import {
  MachineConnectionStatus,
  MachineUpdate,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export enum MachineSubscriptionType {
  MACHINE_UPDATE = 'machineUpdate',
}

export enum SubscriptionMachineEvent {
  TELEMETRY_CHANGED = 'telemetryChanged',
  MACHINE_STATUS_CHANGED = 'machineStatusChanged',
  SITE_CHANGED = 'siteChanged',
  CLEANING_DATA_CHANGED = 'cleaningDataChanged',
  MACHINE_CONNECTION_STATUS_CHANGED = 'connectionStatusChanged',
  MACHINE_ROUTINE_CHANGED = 'routineChanged',
}

export enum MachineSiteChangedEvent {
  MACHINE_ASSIGNED = 'machineAssigned@0.1',
  MACHINE_UNASSIGNED = 'machineUnassigned@0.1',
}

export interface MachineUpdateRobotStatus extends Omit<MachineUpdate, 'data'> {
  data: {
    robotStatus: RobotStatus;
  };
}

export interface MachineUpdateConnectionStatus extends Omit<MachineUpdate, 'data'> {
  data: {
    connectionStatus: MachineConnectionStatus;
  };
}

export interface MachineUpdateTelemetry extends Omit<MachineUpdate, 'data'> {
  data: Record<string, string>;
}

export interface MachineUpdateRoutine {
  routineName: string;
  currentRepeat: number;
  numberOfRepeats: number;
  executionId: string;
}
