import { singleton } from 'tsyringe';
import Observable from 'zen-observable-ts';
import { OpenSearch } from '../../../config/constants';
import { Nullable } from '../../../lib/types/Nullable';
import { Optional } from '../../../lib/types/Optional';
import { initialClassificationAvailabilityState } from '../../cross-cutting-concerns/authentication/constants';
import { BrowserStorage } from '../../cross-cutting-concerns/storage/BrowserStorage';
import { SiteService } from '../site-management/SiteService';
import {
  MachineList,
  MachineDetails,
  MachinesAvailableToBeAssigned,
  MachineListAvailableFilters,
  MachineUpdateResponse,
  MachinesListWithCleaningReport,
  MachineTypesResponse,
  FleetOperatingTimeResponse,
  LegacyMachineStatusItemData,
  ILegacyMachineStatusItemData,
  LegacyMachineStatus,
  MachineListVariantData,
  MachineListTelemetryData,
  MachineListLatestCtrData,
  MachinesAvailableToBeAssignedVariantData,
  MachineDetailsVariantDatum,
  GetMachineOperatingTimeForPeriod,
  MachinesExportRequest,
  MachinesExportGetFile,
  IMachineClassificationAvailabilityConfig,
  MachineRobotCleaningConsumptionSummaryResponse,
  SubscriptionMachineUpdateResult,
  MachineListAsReport,
  MachineDetailsTelemetriesDatum,
  MachineDetailsLatestCtrDatum,
  MachinesReportExportRequest,
  MachineReportSubscriptionsListResponse,
  MachineReportSubscriptionsResetResponse,
  MachineFilterResponse,
  MachineDetailsLatestRoutineDatum,
} from './interfaces/Machine.types';
import {
  MachineClient,
  MachineClientListOptions,
  MachineClientDetailsOptions,
  MachineClientUpdateOptions,
  MachineClientListCoordinatesOptions,
  MachineClientListMachinesWithCleaningReportOptions,
  MachineClientListMachinesWithOperatingTimeOptions,
  MachineClientFleetOperatingTimeOptions,
  MachineClientDetailsPictureOptions,
  MachineClientOperatingTimeOptions,
  MachineClientRequestExportMachinesOptions,
  MachineClientGetExportMachinesFileOptions,
  MachineClientGetConsumptionsSummaryOptions,
  MachineClientAttachmentsListOptions,
  MachineClientListAsReportOptions,
  MachineClientRequestExportMachinesReportOptions,
  MachineClientResetMachineReportSubscriptionsOptions,
  MachineClientMachineFilterOptions,
} from './MachineClient';
import { MachineAttachmentsListResponse } from './interfaces/MachineAttachment.types';
import { SubscriptionOnMachineUpdateArgs } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { GraphQlSubscriptionMessage } from 'app/cross-cutting-concerns/communication/interfaces/graphql.types';

export type MachineServiceDetailsOptions = MachineClientDetailsOptions;

export type MachineServiceOperatingTimeOptions = MachineClientOperatingTimeOptions;

export type MachineServiceDetailsPictureOptions = MachineClientDetailsPictureOptions;

export type MachineServiceListAsReportOptions = MachineClientListAsReportOptions;

export type MachineServiceListOptions = MachineClientListOptions;

export type MachineServiceUpdateOptions = MachineClientUpdateOptions;

export type MachineServiceListCoordinatesOptions = MachineClientListCoordinatesOptions;

export type MachineServiceListMachinesWithCleaningReportOptions = MachineClientListMachinesWithCleaningReportOptions;

export type MachineServiceListMachinesWithOperatingTimeOptions = MachineClientListMachinesWithOperatingTimeOptions;

export type MachineServiceFleetOperatingTimeOptions = MachineClientFleetOperatingTimeOptions;

export type MachineServiceRequestExportMachinesOptions = MachineClientRequestExportMachinesOptions;

export type MachineServiceRequestExportMachinesReportOptions = MachineClientRequestExportMachinesReportOptions;

export type MachineServiceGetExportMachinesFileOptions = MachineClientGetExportMachinesFileOptions;

export type MachineServiceGetMachineRobotConsumptionSummaryOptions = MachineClientGetConsumptionsSummaryOptions;

export type MachineServiceListAttachmentsOptions = MachineClientAttachmentsListOptions;

export type MachineServiceResetMachineReportSubscriptionsOptions = MachineClientResetMachineReportSubscriptionsOptions;

export type MachineServiceMachineFilterOptions = MachineClientMachineFilterOptions;

@singleton()
export class MachineService {
  private static readonly CLASSIFICATION_AVAILABILITY_CONFIG_STORAGE_KEY = 'classification-availability-config';
  private currentClassificationAvailabilityConfig: IMachineClassificationAvailabilityConfig =
    initialClassificationAvailabilityState;

  constructor(
    private readonly browserStorage: BrowserStorage,
    private readonly machineClient: MachineClient,
    private readonly siteService: SiteService
  ) {}

  public listAsReport = async ({
    period,
    search,
    filter,
    sortOptions,
    paginationOptions,
    timezone,
  }: MachineServiceListAsReportOptions): Promise<MachineListAsReport> => {
    const { data } = await this.machineClient.listAsReport({
      period,
      search,
      filter,
      sortOptions,
      paginationOptions,
      timezone,
    });

    return data;
  };

  public list = async ({ paginationOptions, sortOptions, filter }: MachineServiceListOptions): Promise<MachineList> => {
    const { data } = await this.machineClient.list({ paginationOptions, sortOptions, filter });

    return data;
  };

  public listPictures = async ({
    paginationOptions,
    sortOptions,
    filter,
  }: MachineServiceListOptions): Promise<MachineListVariantData> => {
    const { data } = await this.machineClient.listPictures({ paginationOptions, sortOptions, filter });

    return data;
  };

  public listAvailableToBeAssigned = async (): Promise<MachinesAvailableToBeAssigned> => {
    const { data } = await this.machineClient.listAvailableToBeAssigned();

    return data;
  };

  public listAvailableToBeAssignedPictures = async (): Promise<MachinesAvailableToBeAssignedVariantData> => {
    const { data } = await this.machineClient.listAvailableToBeAssignedPictures();

    return data;
  };

  public get = async ({ id }: MachineServiceDetailsOptions): Promise<MachineDetails> => {
    const { data } = await this.machineClient.get({ id });

    return data;
  };

  public getMetadata = async ({ id }: MachineServiceDetailsOptions): Promise<MachineDetails> => {
    const { data } = await this.machineClient.getMetadata({ id });

    return data;
  };

  public getPicture = async ({ id }: MachineServiceDetailsPictureOptions): Promise<MachineDetailsVariantDatum> => {
    const { data } = await this.machineClient.getPicture({ id });

    return data;
  };

  public getTelemetries = async ({
    id,
  }: MachineServiceDetailsPictureOptions): Promise<MachineDetailsTelemetriesDatum> => {
    const { data } = await this.machineClient.getTelemetries({ id });

    return data;
  };

  public getLatestRoutine = async ({
    id,
    latestRoutineFilter,
  }: MachineServiceDetailsOptions): Promise<MachineDetailsLatestRoutineDatum> => {
    const { data } = await this.machineClient.getLastestRoutine({ latestRoutineFilter, id });

    return data;
  };

  public getLatestCtr = async ({ id }: MachineServiceDetailsPictureOptions): Promise<MachineDetailsLatestCtrDatum> => {
    const { data } = await this.machineClient.getLatestCtr({ id });

    return data;
  };

  public getMachineOperatingTime = async ({
    id,
    timezone,
    period,
  }: MachineServiceOperatingTimeOptions): Promise<GetMachineOperatingTimeForPeriod> => {
    const { data } = await this.machineClient.getMachineOperatingTime({ id, timezone, period });

    return data;
  };

  public update = async (input: MachineServiceUpdateOptions): Promise<Optional<MachineUpdateResponse>> => {
    const { data } = await this.machineClient.update(input);

    return data;
  };

  public availableFilters = async (): Promise<MachineListAvailableFilters> => {
    const machineTypesPromise = this.machineClient.listMachineTypes();

    const sitesPromise = this.siteService.listForSelect({
      paginationOptions: {
        limit: OpenSearch.MAX_RESULT_WINDOW,
        paginationToken: '',
      },
    });

    return Promise.all([machineTypesPromise, sitesPromise])
      .then(([machineTypesResponse, sitesResponse]) => {
        const {
          data: {
            machineTypes: { data: machineTypes },
          },
        } = machineTypesResponse;

        const {
          sites: { data: sites },
        } = sitesResponse;

        const machineStatuses: ILegacyMachineStatusItemData[] = Object.values(LegacyMachineStatusItemData).filter(
          // Filter out "Deactivated" machine status until machine deactivation is implemented
          machineStatus =>
            machineStatus.status !== LegacyMachineStatus.DEACTIVATED &&
            machineStatus.status !== LegacyMachineStatus.NON_IOT
        );

        return {
          machineTypes,
          sites,
          machineStatus: machineStatuses,
        };
      })
      .catch(error => {
        throw error;
      });
  };

  public listCoordinates = async ({ filter }: MachineServiceListCoordinatesOptions): Promise<MachineList> => {
    const { data } = await this.machineClient.listCoordinates({ filter });

    return data;
  };

  public listCoordinatesPictures = async ({
    filter,
  }: MachineServiceListCoordinatesOptions): Promise<MachineListVariantData> => {
    const { data } = await this.machineClient.listCoordinatesPictures({ filter });

    return data;
  };

  public listMachinesWithCleaningReport = async ({
    filter,
    sortOptions,
    paginationOptions,
    period,
  }: MachineServiceListMachinesWithCleaningReportOptions): Promise<MachinesListWithCleaningReport> => {
    const { data } = await this.machineClient.listMachinesWithCleaningReport({
      filter,
      sortOptions,
      paginationOptions,
      period,
    });

    return data;
  };

  public listMachineTypes = async (): Promise<MachineTypesResponse> => {
    const { data } = await this.machineClient.listMachineTypes();

    return data;
  };

  public listMachinesWithOperatingTime = async ({
    filter,
    sortOptions,
    paginationOptions,
    period,
  }: MachineServiceListMachinesWithOperatingTimeOptions): Promise<MachinesListWithCleaningReport> => {
    const { data } = await this.machineClient.listMachinesWithOperatingTime({
      filter,
      sortOptions,
      paginationOptions,
      period,
    });

    return data;
  };

  public getFleetOperatingTime = async ({
    filter,
  }: MachineServiceFleetOperatingTimeOptions): Promise<FleetOperatingTimeResponse> => {
    const { data } = await this.machineClient.getFleetOperatingTime({ filter });

    return data;
  };

  /**
   * @deprecated This method is deprecated and will be removed in a future version.
   * Please use the `requestExportMachinesReport` method instead.
   */
  public requestExportMachines = async ({
    filter,
    sortOptions,
    timezone,
    lang,
  }: MachineServiceRequestExportMachinesOptions): Promise<Optional<MachinesExportRequest>> => {
    const { data, errors } = await this.machineClient.requestExportMachines({ filter, sortOptions, timezone, lang });

    if (errors?.length) throw errors;

    return data;
  };

  public requestExportMachinesReport = async ({
    period,
    search,
    filter,
    sortOptions,
    timezone,
    lang,
    columns,
  }: MachineServiceRequestExportMachinesReportOptions): Promise<Optional<MachinesReportExportRequest>> => {
    const { data, errors } = await this.machineClient.requestExportMachinesReport({
      period,
      search,
      filter,
      sortOptions,
      timezone,
      lang,
      columns,
    });

    if (errors?.length) throw errors;

    return data;
  };

  /**
   * @deprecated This method is deprecated and will be removed in a future version.
   * Along with `requestExportMachines` func.
   */
  public getExportMachinesFile = async ({
    requestId,
  }: MachineServiceGetExportMachinesFileOptions): Promise<MachinesExportGetFile> => {
    const { data, errors } = await this.machineClient.getExportMachinesFile({ requestId });

    if (errors?.length) throw errors;

    return data;
  };

  public getCurrentClassificationAvailabilityConfig(): IMachineClassificationAvailabilityConfig {
    return this.currentClassificationAvailabilityConfig;
  }

  public persistClassificationAvailabilityConfig(config: IMachineClassificationAvailabilityConfig): void {
    try {
      this.currentClassificationAvailabilityConfig = config;
      const serializedConfig = JSON.stringify(config);

      this.browserStorage.set(MachineService.CLASSIFICATION_AVAILABILITY_CONFIG_STORAGE_KEY, serializedConfig, {
        obfuscate: true,
      });
    } catch (error) {
      console.error(error);
    }
  }

  public restoreClassificationAvailabilityConfig(): Nullable<IMachineClassificationAvailabilityConfig> {
    try {
      const serializedConfig = this.browserStorage.get(MachineService.CLASSIFICATION_AVAILABILITY_CONFIG_STORAGE_KEY, {
        deobfuscate: true,
      });

      if (!serializedConfig) return null;

      const config = JSON.parse(serializedConfig);

      this.currentClassificationAvailabilityConfig = config;

      return config;
    } catch (error) {
      console.error(error);
    }

    return null;
  }

  public getClassificationAvailabilityConfig = async (): Promise<IMachineClassificationAvailabilityConfig> => {
    const {
      data: { usermachinesClassificationsCheck: classificationAvailabilityConfig },
    } = await this.machineClient.getClassificationAvailabilityConfig();

    const config = {
      hasAccessToGCD: classificationAvailabilityConfig.hasAccessToGCD ?? false,
      hasAccessToRobots: classificationAvailabilityConfig.hasAccessToRobots ?? false,
    };

    this.currentClassificationAvailabilityConfig = config;

    return config;
  };

  public listAttachments = async ({
    machineId,
    paginationOptions,
    sortOptions,
  }: MachineServiceListAttachmentsOptions): Promise<MachineAttachmentsListResponse> => {
    const { data } = await this.machineClient.listAttachments({
      machineId,
      paginationOptions,
      sortOptions,
    });

    return data;
  };

  public getMachineRobotConsumptionSummary = async ({
    filter,
  }: // eslint-disable-next-line max-len
  MachineServiceGetMachineRobotConsumptionSummaryOptions): Promise<MachineRobotCleaningConsumptionSummaryResponse> => {
    const { data } = await this.machineClient.getMachineRobotConsumptionSummary({
      filter,
    });

    return data;
  };

  public subscribeToMachineUpdate = ({
    customerId,
    machineId,
  }: SubscriptionOnMachineUpdateArgs): {
    observable: Observable<GraphQlSubscriptionMessage<SubscriptionMachineUpdateResult>>;
    throttleDuration: number;
  } => this.machineClient.subscribeToMachineUpdate({ customerId, machineId });

  public listRobots = async ({
    paginationOptions,
    sortOptions,
    filter,
  }: MachineServiceListOptions): Promise<MachineList> => {
    const { data } = await this.machineClient.listRobots({
      paginationOptions,
      sortOptions,
      filter,
    });

    return data;
  };

  public robotsWithTelemetries = async ({
    paginationOptions,
    sortOptions,
    filter,
  }: MachineServiceListOptions): Promise<MachineListTelemetryData> => {
    const { data } = await this.machineClient.robotsWithTelemetries({
      paginationOptions,
      sortOptions,
      filter,
    });

    return data;
  };

  public listWithLatestCtr = async ({
    paginationOptions,
    sortOptions,
    filter,
  }: MachineServiceListOptions): Promise<MachineListLatestCtrData> => {
    const { data } = await this.machineClient.listWithLatestCtr({
      paginationOptions,
      sortOptions,
      filter,
    });

    return data;
  };

  public listWithLatestRoutine = async ({
    paginationOptions,
    sortOptions,
    filter,
    filterLatestRoutine,
  }: MachineServiceListOptions): Promise<MachineListLatestCtrData> => {
    const { data } = await this.machineClient.listWithLatestRoutine({
      paginationOptions,
      sortOptions,
      filter,
      filterLatestRoutine,
    });

    return data;
  };

  public listMachineReportSubscriptions = async (): Promise<MachineReportSubscriptionsListResponse> => {
    const { data } = await this.machineClient.listMachineReportSubscriptions();

    return data;
  };

  public resetMachineReportSubscriptions = async ({
    input,
  }: MachineServiceResetMachineReportSubscriptionsOptions): Promise<
    Optional<MachineReportSubscriptionsResetResponse>
  > => {
    const { data } = await this.machineClient.resetMachineReportSubscriptions({
      input,
    });

    return data;
  };

  public getMachineFilter = async ({
    filter,
    timezone,
  }: MachineServiceMachineFilterOptions): Promise<Optional<MachineFilterResponse>> => {
    const { data } = await this.machineClient.getMachineFilter({ filter, timezone });

    return data;
  };
}
