import { useTranslation } from 'react-i18next';
import { noop } from 'lodash-es';
import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { SentInvitationsBlank } from '../SentInvitationsBlank/SentInvitationsBlank';
import { UserListSelectors } from '../../state/UserListSelectors';
import { UserListActions } from '../../state/userListActions';
import { StyledSentInvitations } from './SentInvitations.styles';
import { getSentInvitationListColumns } from './columns/SentInvitations.columns';
import { SortOrders, Status, User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Table } from 'lib/components/Table/Table';
import { InfiniteScrollConstants } from 'config/constants';

export const SentInvitations = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector(UserListSelectors.selectInvitedUserData) || [];
  const isLoading = !!useSelector(UserListSelectors.selectInvitedUserIsLoading);
  const isLoadingMoreData = useSelector(UserListSelectors.selectInvitedUserIsLoadingMoreData);
  const nextPaginationToken = useSelector(UserListSelectors.selectInvitedUserNextPaginationToken);

  const tableColumns = getSentInvitationListColumns({
    t,
    i18n,
  });

  const handleChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;

    noop(sorter, sorterOrder);
  };

  useEffect(() => {
    dispatch(
      UserListActions.getInvitedUserListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        filter: {
          status: Status.Invited,
        },
      })
    );
  }, [dispatch]);

  const loadMore = (): void => {
    dispatch(
      UserListActions.getInvitedUserListMoreDataRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: nextPaginationToken,
        },
        filter: {
          status: Status.Invited,
        },
      })
    );
  };

  return (
    <StyledSentInvitations className="sent-invitations">
      <h1 className="sent-invitations__title">{t('userList.sentInvitations.title')}</h1>

      <div className="sent-invitations__content">
        {!data.length && !isLoading ? (
          <SentInvitationsBlank />
        ) : (
          <Table
            dataSource={data}
            loading={isLoading}
            className="sent-invitations__table"
            columns={tableColumns}
            rowKey="email"
            onChange={handleChange}
            sortDirections={['ascend', 'descend', 'ascend']}
            infiniteScroll={{
              id: 'sent-invitations-infinite-scroll',
              next: loadMore,
              nextPaginationToken,
              isLoadingMoreData,
            }}
            showScrollButtons={true}
          />
        )}
      </div>
    </StyledSentInvitations>
  );
};
