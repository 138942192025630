import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Spin } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import LineBarChart from '../ MachineDetailsRobotsLineBarChart';
import * as machineDetailsPanelRobotSelectors from '../../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineDetailsRobotConsumablesChart } from './MachineDetailsRobotConsumablesChart.styles';
import { MachineDetailsRobotConsumablesChartHeader } from './MachineDetailsRobotConsumablesChartHeader/MachineDetailsRobotConsumablesChartHeader';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { getDatesByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';
import { CleaningTaskConsumablesByDay } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateFormats } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

const DEFAULT_VALUE = {
  finishDates: '2024-01-01 00:00:00.000000000',
  consumptions: {
    battery: 0,
    detergents: 0,
    water: 0,
  },
};

interface MachineDetailsRobotConsumablesChartProps {
  robot: Machine;
}

export const MachineDetailsRobotConsumablesChart = ({
  robot,
}: MachineDetailsRobotConsumablesChartProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const startAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);
  const routeName = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);

  const cleaningDataConsumablesByDay = useSelector(
    machineDetailsPanelRobotSelectors.selectCleaningDataConsumablesByDay
  );
  const cleaningDataConsumablesIsLoading = useSelector(
    machineDetailsPanelRobotSelectors.selectCleaningDataConsumablesIsLoading
  );

  const startTime = startAt ? dayjs(startAt) : null;
  const endTime = endAt ? dayjs(endAt) : null;
  const dates = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_DAY_MONTH));

  useEffect(() => {
    if (!startAt || !endAt) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataConsumablesRequest({
        filter: {
          machineId: robot.id || '',
          period: {
            endAt,
            startAt,
          },
          routeName,
        },
      })
    );
  }, [dispatch, endAt, robot.id, startAt, routeName]);

  const periodData = useMemo(() => {
    const period = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_YEAR_MONTH_DAY));
    const result: CleaningTaskConsumablesByDay[] = [];
    period.forEach(date => {
      const data = cleaningDataConsumablesByDay.find(
        item => dayjs(item.finishedAt).format(DateFormats.DATE_IN_YEAR_MONTH_DAY) === date
      );
      if (!data) {
        result.push({ ...DEFAULT_VALUE, finishedAt: date });
      } else {
        result.push(data);
      }
    });
    return result;
  }, [cleaningDataConsumablesByDay, endTime, startTime]);

  const consumables = periodData.map(item => item.consumptions);
  const finishDates = periodData.map(item => item.finishedAt);

  return (
    <StyledMachineDetailsRobotConsumablesChart>
      <Spin size="large" className="robot-consumables-chart__spin" spinning={cleaningDataConsumablesIsLoading}>
        <Col span={24} className="robot-consumables-chart__bar">
          <MachineDetailsRobotConsumablesChartHeader />
        </Col>
        <Col span={24}>
          <div className="robot-consumables-chart">
            <div className="robot-consumables-chart__unit">
              <p>
                {t('machineDetails.robotCleaningData.Consumables.litre')}&nbsp;{`(${t('common.litreUnit')})`}
              </p>
              <p>
                {t('machineDetails.robotCleaningData.Consumables.energy')}&nbsp;{`(${t('common.kWhUnit')})`}
              </p>
            </div>
            <LineBarChart period={dates} cleaningValues={consumables} finishDates={finishDates} />
          </div>
        </Col>
      </Spin>
    </StyledMachineDetailsRobotConsumablesChart>
  );
};
