import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RobotSummaryRow } from '../../../RobotSummaryRow/RobotSummaryRow';
import { RobotSummaryRowCV50 } from '../../../RobotCV50/RobotRowCV50/RobotSummaryRowCV50';
import { RobotCleaningStatus } from '../../../RobotCleaningStatus/RobotCleaningStatus';
import * as robotDashboardSelectors from '../../../../../state/RobotDashboardSelectors';
import { StyledRobotRowList } from './RobotRowList.styles';
import { InfoTooltip } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import {
  MachineConnectionStatus,
  RobotStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { RobotStatusDisplayName } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { RobotUtils } from 'app/utils/robot/RobotUtils';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { NoStyleButton } from 'lib/components/Button/NoStyleButton/NoStyleButton';
import { KIRA_CV50, KIRA_CV_50 } from 'app/modules/cleaning/widgets/robot-dashboard/utils/constants';

export const RobotRowGroup = ({
  robots,
  robotStatus,
  connectionStatus,
  isShowStatus,
}: {
  robots: Machine[];
  robotStatus: Optional<RobotStatus>;
  connectionStatus: MachineConnectionStatus;
  isShowStatus: boolean;
}): JSX.Element => {
  const dispatch = useDispatch();

  const robotStatusDisplayName = RobotUtils.getRobotStatusDisplayName(robotStatus, connectionStatus);

  const areRobotListPicturesLoading = !!useSelector(robotDashboardSelectors.selectAreRobotListPicturesLoading);
  const areRobotListTelemetriesLoading = !!useSelector(robotDashboardSelectors.selectAreRobotListTelemetriesLoading);
  const areRobotListLatestCtrLoading = !!useSelector(robotDashboardSelectors.selectAreRobotListLatestCtrLoading);

  const handleOpenRobotDetailsPanel = (robotId: string): void => {
    dispatch(DrawersActions.showMachineDetailsDrawer({ machineId: robotId }));
  };

  useEffect(
    () => () => {
      dispatch(DrawersActions.hideMachineDetailsDrawer());
    },
    [dispatch]
  );

  return (
    <div className="robot-row-group__wrapper">
      <InfoTooltipGlobalStyles />
      <div className="robot-row-group__wrapper-header">
        <RobotCleaningStatus robotStatus={robotStatus} connectionStatus={connectionStatus} />
        <Tooltip
          title={<InfoTooltip content={`robotDashboard.tooltip.${robotStatusDisplayName}`} />}
          overlayClassName="tooltip-overlay"
          placement="bottomRight"
        >
          <div>
            <SvgIcon name="info" className="robot-dashboard__info-icon" />
          </div>
        </Tooltip>
      </div>
      {robots.map(robot => (
        <NoStyleButton
          className="robot-row-group__details-wrapper"
          key={robot.id}
          onClick={(): void => handleOpenRobotDetailsPanel(robot.id)}
        >
          {!(
            robot.type?.name?.toLocaleUpperCase().includes(KIRA_CV50) ||
            robot.type?.name?.toLocaleUpperCase().includes(KIRA_CV_50)
          ) ? (
            <RobotSummaryRow
              robot={robot}
              isShowStatus={isShowStatus}
              areRobotListPicturesLoading={areRobotListPicturesLoading}
              areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
              areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
            />
          ) : (
            <RobotSummaryRowCV50
              robot={robot}
              isShowStatus={isShowStatus}
              areRobotListPicturesLoading={areRobotListPicturesLoading}
              areRobotListTelemetriesLoading={areRobotListTelemetriesLoading}
              areRobotListLatestCtrLoading={areRobotListLatestCtrLoading}
            />
          )}
        </NoStyleButton>
      ))}
    </div>
  );
};

export const RobotRowList = (): JSX.Element => {
  const robots = useSelector(robotDashboardSelectors.selectRobotListData) || [];
  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData) || [];
  const robotsGroupedByStatus = RobotUtils.getRobotsGroupedByStatus([...robots, ...unassignedRobots]);
  const isHidingOfflineRobots = useSelector(robotDashboardSelectors.selectIsHidingOfflineRobots);

  return (
    <StyledRobotRowList>
      {robotsGroupedByStatus[RobotStatusDisplayName.Cleaning].length > 0 && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Cleaning]}
          robotStatus={RobotStatus.Autonomous}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Standby].length > 0 && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Standby]}
          robotStatus={RobotStatus.Idle}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Docking].length > 0 && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Docking]}
          robotStatus={RobotStatus.Docking}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.ManualCharging].length > 0 && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.ManualCharging]}
          robotStatus={RobotStatus.Charging}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Exploration].length > 0 && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Exploration]}
          robotStatus={RobotStatus.Exploration}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.Offline].length > 0 && !isHidingOfflineRobots && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.Offline]}
          robotStatus={RobotStatus.Autonomous}
          connectionStatus={MachineConnectionStatus.Offline}
          isShowStatus={false}
        />
      )}
      {robotsGroupedByStatus[RobotStatusDisplayName.NA].length > 0 && (
        <RobotRowGroup
          robots={robotsGroupedByStatus[RobotStatusDisplayName.NA]}
          robotStatus={null}
          connectionStatus={MachineConnectionStatus.Online}
          isShowStatus={false}
        />
      )}
    </StyledRobotRowList>
  );
};
