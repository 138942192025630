import styled from 'styled-components';

export const StyledMachineDetailsPanelStatus = styled.div`
  display: flex;
  padding: ${(props): string => props.theme.spacing.lg};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: ${(props): string => props.theme.spacing.xs};
  align-self: stretch;

  border: 1px solid ${(props): string => props.theme.colors.brightGrey};

  .machine-details-panel-status__heading {
    display: flex;
    align-items: center;
    gap: ${(props): string => props.theme.spacing.md};
    align-self: stretch;
  }

  .machine-details-panel-status__title {
    flex: 1 0 0;
    color: ${(props): string => props.theme.colors.black};
    ${(props): string => props.theme.fontStyles.redesign.xl.strong};
  }

  .machine-details-panel-status__info-icon {
    display: none;
    width: 24px;
    height: 24px;
  }

  .machine-details-panel-status__details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props): string => props.theme.spacing.md};
    align-self: stretch;
  }

  .machine-details-panel-status__status-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;
  }

  .machine-details-panel-status__status-item-label {
    ${(props): string => props.theme.fontStyles.redesign.sm.delete};
  }

  .machine-details-panel-status__status-item-value {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal};
  }

  .machine-details-panel-status__accuracy {
    ${(props): string => props.theme.fontStyles.redesign.sm.normal}; /* 166.667% */
    color: ${(props): string => props.theme.colors.black};
    opacity: 0.25;
  }
`;
