import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { selectData } from '../../state/machineDetailsPanelSelectors';
import { MachineDetailsRobotCleaningData } from '../MachineDetailsRobotPanel/MachineDetailsRobotCleaningData/MachineDetailsRobotCleaningData';
import { MachineDetailsRobotOverview } from '../MachineDetailsRobotPanel/MachineDetailsRobotOverview/MachineDetailsRobotOverview';
import { StyledMachineDetailsPanelRobot } from './MachineDetailsPanelRobot.styles';
import { useAnalyticsSetProductInfo } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetProductInfo';
import { useAnalyticsSetPageInfo } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { PageNamePrefix } from 'config/pages';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { MachineAttachmentList } from 'app/modules/machine-inventory/machine-details/components/MachineAttachmentList/MachineAttachmentList';

enum MachineDetailsRobotTabs {
  OVERVIEW = 'OVERVIEW',
  CLEANING_DATA = 'CLEANING_DATA',
  DOCUMENTS = 'DOCUMENTS',
}
export const MachineDetailsPanelRobot = (): React.JSX.Element => {
  const { t } = useTranslation();
  const robot = useSelector(selectData);

  useAnalyticsSetProductInfo({
    productName: robot?.name,
    productMaterialNo: robot?.materialNumber,
    productSerialNo: robot?.serialNumber,
  });
  useAnalyticsSetPageInfo({
    // eslint-disable-next-line max-len
    pageName: `${PageNamePrefix.MACHINE_DETAILS}${robot?.name} ${robot?.classification} (${robot?.materialNumber} / ${robot?.serialNumber})`,
  });

  const tabs = [
    {
      label: t('machineDetailsPanel.tabs.cleaningData'),
      key: MachineDetailsRobotTabs.CLEANING_DATA,
      children: <MachineDetailsRobotCleaningData robot={robot as Machine} />,
    },
    {
      label: t('machineDetailsPanel.tabs.overview'),
      key: MachineDetailsRobotTabs.OVERVIEW,
      children: <MachineDetailsRobotOverview robot={robot as Machine} />,
    },
    {
      label: t('machineDetailsPanel.tabs.documents'),
      key: MachineDetailsRobotTabs.DOCUMENTS,
      children: <MachineAttachmentList machine={robot as Machine} size="full-width" />,
    },
  ];

  return (
    <StyledMachineDetailsPanelRobot>
      {robot ? (
        <Tabs destroyInactiveTabPane={true} items={tabs} className="machine-panel-robot__tabs" />
      ) : (
        t('machineDetails.error.machineNotFound')
      )}
    </StyledMachineDetailsPanelRobot>
  );
};
