/* eslint-disable max-len */
import { SelectValue } from 'antd/lib/select';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import isNil from 'lodash-es/isNil';
import dayjs, { Dayjs } from 'dayjs';
import { isArray } from 'lodash-es';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { RangePicker } from '../../../../../../lib/components/RangePicker/RangePicker';
import { useAnalyticsSetFilter } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetFilter';
import { useAnalyticsSetPageInfo } from '../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import {
  AnalyticsCleaningReportListFilter,
  AnalyticsLink,
  IAnalyticsFilter,
} from '../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { CleaningReportRequestDateRangeRestrictor } from '../../../CleaningReportRequestDateRangeRestrictor';
import { END_DATE, OperatingHoursChartUtils, START_DATE } from '../../../utils/OperatingHoursChartUtils';
import { CleaningReportListActions } from '../../state/cleaningReportListActions';
import * as cleaningReportListSelectors from '../../state/cleaningReportListSelectors';
import * as authenticationSelectors from '../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import * as robotDashboardSelectors from '../../../widgets/robot-dashboard/state/RobotDashboardSelectors';
import { CleaningReportListExact } from '../CleaningReportListExact/CleaningReportListExact';
import { CleaningReportListRobot } from '../CleaningReportListRobot/components/CleaningReportListRobot';
import { ReportSubscriptionDrawer } from '../ReportSubscriptionDrawer/ReportSubscriptionDrawer';
import { StyledCleaningReportList } from './CleaningReportList.styles';
import { InfoTooltipGlobalStyles } from 'lib/components/Tooltip/InfoTooltip/InfoTooltip.global.styles';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import {
  SortOrders,
  ReportType,
  MachineClassification,
  TaskCompletion,
  InputFilterMachinesList,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { useGetMachineIdsFilter } from 'app/modules/cleaning/hooks/useGetMachineIdsFilter';
import { ALL_VALUE_SELECT_OPTION, UNASSIGNED } from 'config/constants';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { MachineAvailableToFilter } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { RobotDashboardActions } from 'app/modules/cleaning/widgets/robot-dashboard/state/RobotDashboardSlice';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Tabs } from 'lib/components/Tabs/Tabs';
import { SelectUtils } from 'lib/components/Select/SelectUtils';
import { Select } from 'lib/components/Select/Select';
import {
  selectIsRedirectFromRobotDetailsPanel,
  selectRobotIdRedirected,
} from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';

interface ICleaningReportListFilterForm {
  dateRangeLocal: [Dayjs, Dayjs];
}

export enum CleaningReportListTab {
  TOTALS = 'cleaning-report-list-totals',
  EXACT = 'cleaning-report-list-exact',
  ROBOT = 'cleaning-report-list-robot',
}

export const CleaningReportList = (): JSX.Element => {
  const [isMachinesSelectOpen, setIsMachinesSelectOpen] = useState(false);
  const [isSitesSelectOpen, setIsSitesSelectOpen] = useState(false);
  const [isTasksCompletionsSelectOpen, setIsTaskCompletionsSelectOpen] = useState(false);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const showRobotTab = useSelector(authenticationSelectors.selectHasAccessToRobots);
  const showGcdTab = useSelector(authenticationSelectors.selectHasAccessToGCD);

  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;

  const [isShowCleaningReportListTab, setIsShowCleaningReportListTab] = useState(true);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);

  const tabs = [
    // {
    //   label: t('cleaningReportList.tabs.totals'),
    //   key: CleaningReportListTab.TOTALS,
    // },
    {
      label: t('cleaningReportList.tabs.exact'),
      key: CleaningReportListTab.EXACT,
    },
  ];

  const classificationTypes = useMemo(() => {
    const tab = [
      {
        label: t('cleaningReportList.classifications.robot'),
        key: MachineClassification.Robot,
      },
      {
        label: t('cleaningReportList.classifications.gcd'),
        key: MachineClassification.Gcd,
      },
    ];
    if (showGcdTab && showRobotTab) {
      dispatch(CleaningReportListActions.setSelectDeviceType(MachineClassification.Robot));
      return tab;
    }
    return [];
  }, [showRobotTab, showGcdTab, t, dispatch]);

  const [activeReportTypeTab, setActiveReportTypeTab] = useState<CleaningReportListTab>(tabs[0].key);

  useEffect(() => {
    if (!showRobotTab) {
      setActiveReportTypeTab(CleaningReportListTab.EXACT);
      setIsShowCleaningReportListTab(true);
      dispatch(CleaningReportListActions.setSelectDeviceType(MachineClassification.Gcd));
    }
    if (!showGcdTab && showRobotTab) {
      dispatch(CleaningReportListActions.resetActiveMachineStatusFilter());
      setIsShowCleaningReportListTab(false);
      dispatch(CleaningReportListActions.setSelectDeviceType(MachineClassification.Robot));
    }
  }, [dispatch, showGcdTab, showRobotTab]);

  const handleClassificationTabChange = (key: string): void => {
    if (key === MachineClassification.Gcd) {
      setIsShowCleaningReportListTab(true);
    } else {
      dispatch(CleaningReportListActions.resetActiveMachineStatusFilter());
      setIsShowCleaningReportListTab(false);
      analyticsLinkActivated({
        linkName: AnalyticsLink.CLEANING_REPORTS_ROBOT,
      });
    }

    dispatch(CleaningReportListActions.setSelectDeviceType(key as MachineClassification));
    dispatch(CleaningReportListActions.resetFilterSelected());
    dispatch(CleaningReportListActions.getCleaningReportListFiltersRequest([key as MachineClassification]));
  };

  // const handleReportTypeTabChange = (key: string): void => {
  //   if (key === CleaningReportListTab.TOTALS) {
  //     analyticsLinkActivated({
  //       linkName: AnalyticsLink.CLEANING_REPORTS_TOTAL,
  //     });
  //   } else {
  //     dispatch(CleaningReportListActions.resetActiveMachineStatusFilter());
  //     analyticsLinkActivated({
  //       linkName: AnalyticsLink.CLEANING_REPORTS_EXACT,
  //     });
  //   }
  //
  //   setActiveReportTypeTab(key as CleaningReportListTab);
  // };

  const availableFilters = useSelector(cleaningReportListSelectors.selectFilters);
  const areFiltersLoading = useSelector(cleaningReportListSelectors.selectAreFiltersLoading);
  const startDate = useSelector(cleaningReportListSelectors.selectPeriodStartDate);
  const endDate = useSelector(cleaningReportListSelectors.selectPeriodEndDate);
  const activeSitesFilter = useSelector(cleaningReportListSelectors.selectActiveSiteFilter);
  const activeMachinesFilter = useSelector(cleaningReportListSelectors.selectActiveMachinesFilter);
  const activeLastActivityAtFilter = useSelector(cleaningReportListSelectors.selectActiveLastActivityAtFilter);
  const totalSortField = useSelector(cleaningReportListSelectors.selectTotalsSortField) || 'serialNumber';
  const totalSortOrder = useSelector(cleaningReportListSelectors.selectTotalsSortOrder) || SortOrders.Asc;
  const exactSortField = useSelector(cleaningReportListSelectors.selectExactSortField) || 'startedAt';
  const exactSortOrder = useSelector(cleaningReportListSelectors.selectExactSortOrder) || SortOrders.Desc;
  const robotSortField = useSelector(cleaningReportListSelectors.selectRobotSortField) || 'serialNumber';
  const robotSortOrder = useSelector(cleaningReportListSelectors.selectRobotSortOrder) || SortOrders.Desc;
  const exportIsLoading = useSelector(cleaningReportListSelectors.selectExportIsLoading) || false;
  const taskCompletionFilter = useSelector(cleaningReportListSelectors.selectActiveTaskCompletionFilter);
  const activeClassificationTypeTab = useSelector(cleaningReportListSelectors.selectActiveDeviceTab);
  const isRedirectedFromRobotDashboard = useSelector(robotDashboardSelectors.selectRobotDashboardIsRedirected);
  const isRedirectedFromRobotDetailsPanel = useSelector(selectIsRedirectFromRobotDetailsPanel);
  const redirectedRobotIdFromRobotDetailsPanel = useSelector(selectRobotIdRedirected);

  const getTotalFilterCount = (): number => {
    const sanitizedActiveMachineTypeFilter = activeMachinesFilter ?? [];
    const sanitizedActiveSiteFilter = activeSitesFilter ?? [];
    const sanitizedActiveLastActivityAtFilter =
      ((activeLastActivityAtFilter !== undefined ? [activeLastActivityAtFilter] : []) as unknown as string[]) ?? [];
    const sanitizedActiveTaskCompletionFilter = taskCompletionFilter ?? [];

    const count = [
      sanitizedActiveMachineTypeFilter,
      sanitizedActiveSiteFilter,
      sanitizedActiveLastActivityAtFilter,
      sanitizedActiveTaskCompletionFilter,
    ]
      .map(value => {
        if (!isArray(value)) {
          return [value];
        }

        return value;
      })
      .reduce((acc, filterValues) => acc + (filterValues ?? []).length, 0);

    return count;
  };

  const availableMachines = (): MachineAvailableToFilter[] => {
    const machines = availableFilters?.machines || [];
    const standAloneMachines = availableFilters?.standAloneMachines || [];

    return Array.from(new Set([...machines, ...standAloneMachines]));
  };

  const getActiveFiltersCallback = useCallback((): IAnalyticsFilter[] => {
    const activeFilters: IAnalyticsFilter[] = [];

    if (!isNil(startDate) && !isNil(endDate)) {
      activeFilters.push(AnalyticsCleaningReportListFilter.TIME_RANGE);
    }

    if (!isNil(activeSitesFilter) && activeSitesFilter?.length > 0) {
      activeFilters.push(AnalyticsCleaningReportListFilter.ASSIGNED_SITES);
    }

    if (!isNil(activeMachinesFilter) && activeMachinesFilter?.length > 0) {
      activeFilters.push(AnalyticsCleaningReportListFilter.MACHINES);
    }

    if (!isNil(activeLastActivityAtFilter)) {
      activeFilters.push(AnalyticsCleaningReportListFilter.STATUS);
    }

    if (activeReportTypeTab === CleaningReportListTab.TOTALS) {
      activeFilters.push(AnalyticsCleaningReportListFilter.TOTAL_REPORT);
    }

    if (activeReportTypeTab === CleaningReportListTab.EXACT) {
      activeFilters.push(AnalyticsCleaningReportListFilter.EVENT_REPORT);
    }

    if (activeClassificationTypeTab === MachineClassification.Gcd) {
      activeFilters.push(AnalyticsCleaningReportListFilter.GCD);
    }

    if (activeClassificationTypeTab === MachineClassification.Robot) {
      activeFilters.push(AnalyticsCleaningReportListFilter.ROBOT);
    }

    return activeFilters;
  }, [
    activeMachinesFilter,
    activeSitesFilter,
    activeLastActivityAtFilter,
    activeReportTypeTab,
    activeClassificationTypeTab,
    endDate,
    startDate,
  ]);

  useAnalyticsSetFilter({
    getActiveFiltersCallback,
  });
  useAnalyticsSetPageInfo({});

  useEffect(() => {
    form.setFieldsValue({
      dateRangeLocal: [dayjs(startDate), dayjs(endDate)],
    });
  }, [endDate, form, startDate]);

  // Reset form fields when component is unmounted
  useEffect(
    () => (): void => {
      form.resetFields();
      dispatch(RobotDashboardActions.robotDashboardIsNotRedirectedActive());
      dispatch(MachineDetailsPanelRobotActions.machineDetailsRobotIsNotRedirectedActive());
      dispatch(CleaningReportListActions.resetCleaningReportListActiveFiltersState());
    },
    [form, dispatch]
  );

  useEffect(() => {
    if (!(isRedirectedFromRobotDashboard || isRedirectedFromRobotDetailsPanel)) {
      dispatch(
        CleaningReportListActions.setActivePeriodFilter({
          startDate: OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
          endDate: OperatingHoursChartUtils.prepareEndDate(END_DATE).toISOString(),
        })
      );
    }
  }, [dispatch, isRedirectedFromRobotDashboard, isRedirectedFromRobotDetailsPanel]);

  useEffect(() => {
    dispatch(CleaningReportListActions.getCleaningReportListFiltersRequest([activeClassificationTypeTab]));
  }, [activeClassificationTypeTab, dispatch]);

  const handleMachineListFilterRequest = useCallback(
    (filter: InputFilterMachinesList) => {
      dispatch(
        CleaningReportListActions.getMachineListFilterRequest({
          filter: {
            ...filter,
            classifications: [activeClassificationTypeTab],
          },
        })
      );
    },
    [dispatch, activeClassificationTypeTab]
  );

  const handleStandAloneMachineListFilterRequest = useCallback(
    (filter: InputFilterMachinesList) => {
      dispatch(
        CleaningReportListActions.getStandAloneMachineListFilterRequest({
          filter: {
            ...filter,
            classifications: [activeClassificationTypeTab],
          },
        })
      );
    },
    [dispatch, activeClassificationTypeTab]
  );

  useEffect(() => {
    if (isRedirectedFromRobotDashboard || isRedirectedFromRobotDetailsPanel) {
      handleStandAloneMachineListFilterRequest({
        siteIds: activeSitesFilter,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSitesFilter, dispatch, isRedirectedFromRobotDashboard, isRedirectedFromRobotDetailsPanel]);

  useEffect(() => {
    if (isRedirectedFromRobotDetailsPanel && !areFiltersLoading && redirectedRobotIdFromRobotDetailsPanel) {
      dispatch(CleaningReportListActions.setActiveMachinesFilter([redirectedRobotIdFromRobotDetailsPanel]));
    }
  }, [dispatch, isRedirectedFromRobotDetailsPanel, areFiltersLoading, redirectedRobotIdFromRobotDetailsPanel]);

  useEffect(
    () => (): void => {
      dispatch(CleaningReportListActions.totalsResetState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (activeClassificationTypeTab === MachineClassification.Robot) {
      setIsShowCleaningReportListTab(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSiteFilterChange = (values: SelectValue): void => {
    const siteValues = values as string[];

    dispatch(CleaningReportListActions.setActiveSiteFilter(siteValues));
  };

  useEffect(() => {
    if (activeSitesFilter) {
      if (activeSitesFilter[0] === UNASSIGNED && activeSitesFilter.length === 1) {
        handleStandAloneMachineListFilterRequest({
          isStandalone: true,
        });
        dispatch(CleaningReportListActions.resetMachineListFilter());
      }

      if (activeSitesFilter.length > 1 && activeSitesFilter.includes(UNASSIGNED)) {
        handleMachineListFilterRequest({
          siteIds: activeSitesFilter.filter(siteId => siteId !== UNASSIGNED),
        });

        handleStandAloneMachineListFilterRequest({
          isStandalone: true,
        });
      }

      if (!activeSitesFilter.includes(UNASSIGNED) && activeSitesFilter[0] !== ALL_VALUE_SELECT_OPTION) {
        handleMachineListFilterRequest({
          siteIds: activeSitesFilter,
        });

        dispatch(CleaningReportListActions.resetStandAloneMachineListFilter());
      }

      if (activeSitesFilter[0] === ALL_VALUE_SELECT_OPTION && activeSitesFilter.length === 1) {
        handleMachineListFilterRequest({});
        dispatch(CleaningReportListActions.resetStandAloneMachineListFilter());
      }
    }
  }, [dispatch, activeSitesFilter, handleMachineListFilterRequest, handleStandAloneMachineListFilterRequest]);

  const handleMachinesFilterChange = (values: SelectValue): void => {
    const machineValues = values as string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_MACHINES_FILTER,
    });

    dispatch(CleaningReportListActions.setActiveMachinesFilter(machineValues));
  };

  const handleTaskCompletionFilterChange = (values: SelectValue): void => {
    const taskCompleteValues = values as TaskCompletion[] | string[];

    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_TASK_COMPLETION_FILTER,
    });

    dispatch(
      CleaningReportListActions.setActiveTaskCompletionFilter(
        taskCompleteValues.length === 0 ? undefined : taskCompleteValues
      )
    );
  };

  const onFinish = (values: ICleaningReportListFilterForm): void => {
    const { dateRangeLocal: dates } = values;

    if (dates && dates[0] && dates[1]) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.CLEANING_REPORTS_PERIOD_FILTER,
      });

      dispatch(
        CleaningReportListActions.setActivePeriodFilter({
          startDate: OperatingHoursChartUtils.prepareStartDate(dates[0].toDate()).toISOString(),
          endDate: OperatingHoursChartUtils.prepareEndDate(dates[1].toDate()).toISOString(),
        })
      );
    }
  };

  const showReportSubscriptionDrawer = (): void => {
    dispatch(CleaningReportListActions.showReportSubscriptionDrawer());
  };

  const machineIdsFilter = useGetMachineIdsFilter(
    activeMachinesFilter,
    activeSitesFilter,
    availableFilters,
    activeClassificationTypeTab
  );

  const handleExcelExport = (): void => {
    analyticsLinkActivated({
      linkName: AnalyticsLink.CLEANING_REPORTS_EXPORT,
    });

    let reportType = ReportType.TotalReport;
    const sortOptions = {
      field: totalSortField,
      order: totalSortOrder,
    };

    if (activeClassificationTypeTab === MachineClassification.Gcd) {
      if (activeReportTypeTab === CleaningReportListTab.TOTALS) {
        reportType = ReportType.TotalReport;
      } else if (activeReportTypeTab === CleaningReportListTab.EXACT) {
        reportType = ReportType.EventReport;
        sortOptions.field = exactSortField;
        sortOptions.order = exactSortOrder;
      }
    } else if (activeClassificationTypeTab === MachineClassification.Robot) {
      sortOptions.field = robotSortField;
      sortOptions.order = robotSortOrder;
      reportType = ReportType.RobotReport;
    }

    dispatch(
      CleaningReportListActions.requestExportCleaningReportsRequest({
        filter: {
          machineIds: machineIdsFilter,
          period: {
            startAt: startDate || OperatingHoursChartUtils.prepareStartDate(START_DATE).toISOString(),
            endAt: endDate || OperatingHoursChartUtils.prepareStartDate(END_DATE).toISOString(),
          },
          reportType,
        },
        timezone: DateTime.getBrowserTimeZone(),
        lang: Translations.getSupportedLanguageCode(i18n.language),
        sortOptions,
      })
    );
  };

  const resetFilterSelected = (): void => {
    dispatch(CleaningReportListActions.resetFilterSelected());
    // TODO: improvement for late
    // reset filter => reset activeSitesFilter but activeSitesFilter not handle reset machines
    handleMachineListFilterRequest({});
  };
  // TODO: Open when BE support
  // const handleMachineStatusFilterChange = (value: SelectValue): void => {
  //   analyticsLinkActivated({
  //     linkName: AnalyticsLink.CLEANING_REPORTS_MACHINE_STATUS_FILTER,
  //   });

  //   const rangeMap = {
  //     [MachineStatus.DATA_AGE_OVER_THREE_DAYS]: {
  //       from: DateTime.beginningOfEpochTime().toISOString(),
  //       to: DateTime.threeDaysAgo().toISOString(),
  //     },
  //     [MachineStatus.DATA_AGE_ONE_TO_THREE_DAYS]: {
  //       from: DateTime.threeDaysAgo().toISOString(),
  //       to: DateTime.oneDayAgo().toISOString(),
  //     },
  //     [MachineStatus.DATA_UP_TO_DATE]: {
  //       from: DateTime.oneDayAgo().toISOString(),
  //       to: DateTime.today().toISOString(),
  //     },
  //     [MachineStatus.NO_DATA]: null,
  //   };

  //   const lastActivityAt = rangeMap[value as keyof typeof rangeMap] as FilterLastActivityAt;

  //   dispatch(CleaningReportListActions.setActiveMachineStatusFilter(lastActivityAt));
  // };

  // TODO: Remove comment when search integrate search with backend
  // const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
  //   const value = e.target.value.trim() || undefined;

  //   analyticsLinkActivated({
  //     linkName: AnalyticsLink.CLEANING_REPORTS_TASK_SEARCH_TERM,
  //   });

  //   dispatch(CleaningReportListActions.setSearchText(value as Optional<string>));
  // }, 500);

  return (
    <>
      <InfoTooltipGlobalStyles />
      <StyledCleaningReportList className="cleaning-report-list">
        <div className="cleaning-report-list__header">
          <div className="cleaning-report-list__header-content">
            <div className="cleaning-report-list__container--fluid">
              <div className="cleaning-report-list__page-info">
                <div>
                  <h1 className="cleaning-report-list__title">{t('common.events')}</h1>
                </div>
                <div className="cleaning-report-list__report-sub">
                  <SecondaryButton
                    size="s"
                    className="cleaning-report-list__report-subscription-button"
                    onClick={showReportSubscriptionDrawer}
                  >
                    {t('cleaningReportList.reportSubscription.reportSubscription')}
                  </SecondaryButton>
                  <PrimaryButton
                    className="cleaning-report-list__export-button"
                    size="m"
                    onClick={handleExcelExport}
                    icon={<SvgIcon name="exportReport" className="cleaning-report-list__export-icon" />}
                    loading={exportIsLoading}
                  >
                    {t('common.exportReport')}
                  </PrimaryButton>
                </div>
              </div>

              <div className="cleaning-report-list__submenu">
                {isRobotIntegrationEnabled && showRobotTab && (
                  <div className="cleaning-report-list__tabs">
                    <Tabs
                      className="cleaning-report-list__device-type"
                      destroyInactiveTabPane={true}
                      items={classificationTypes}
                      onChange={handleClassificationTabChange}
                      activeKey={activeClassificationTypeTab}
                    />
                  </div>
                )}
              </div>

              <div className="cleaning-report-list__filter">
                <div className="cleaning-report-list__filter-button-wrapper-container">
                  <Form
                    form={form}
                    className="cleaning-report-list__filter-form"
                    name="cleaning-report-list__filter-form"
                    layout="horizontal"
                    onFinish={onFinish}
                    onValuesChange={(): void => {
                      form.submit();
                    }}
                    autoComplete="off"
                    validateTrigger="onSubmit"
                    initialValues={{
                      dateRangeLocal: [dayjs(startDate), dayjs(endDate)],
                    }}
                  >
                    <div className="cleaning-report-list__select-wrapper">
                      <Form.Item
                        name="dateRangeLocal"
                        required
                        rules={[
                          {
                            required: true,
                            message: t('cleaningReportRequestDateRangeRestrictor.errors.dateRangeRequired'),
                          },
                          {
                            validator: CleaningReportRequestDateRangeRestrictor.validate,
                          },
                        ]}
                      >
                        <RangePicker
                          className="cleaning-report-list__date-range-picker"
                          disabledDate={CleaningReportRequestDateRangeRestrictor.disabledDatePredicate}
                        />
                      </Form.Item>
                    </div>
                  </Form>

                  <SecondaryButton
                    size="s"
                    onClick={(): void => setAreFiltersVisible(prevState => !prevState)}
                    className={classnames('cleaning-report-list__filter-button', {
                      'button-active': areFiltersVisible,
                    })}
                    key="cleaning-report-list__filter-button"
                    loading={areFiltersLoading}
                  >
                    <span>{t('common.filter')}</span>
                    {getTotalFilterCount() > 0 && (
                      <span className="cleaning-report-list__filter-button-counter">{getTotalFilterCount() || ''}</span>
                    )}
                    <SvgIcon name="filter" className="cleaning-report-list__filter-button-icon" />
                  </SecondaryButton>

                  {/* // TODO: Remove comment when search integrate search with backend */}
                  {/* <Input
                    className={classnames('cleaning-report-list__search-bar', 'cleaning-report-list__input')}
                    placeholder={t('machineList.filter.searchForMachine')}
                    prefix={
                      <span className="cleaning-report-list__search-bar-icon">
                        <SearchOutlined />
                      </span>
                    }
                    onChange={handleSearchChange}
                  /> */}
                </div>

                <div
                  className={classnames('cleaning-report-list__filter-wrapper-container', {
                    'cleaning-report-list__filter-wrapper-container--hidden': !areFiltersVisible,
                  })}
                >
                  <div className="cleaning-report-list__select-wrapper cleaning-report-list__select-wrapper-sites">
                    <Select
                      defaultValue={ALL_VALUE_SELECT_OPTION}
                      loading={areFiltersLoading}
                      className="cleaning-report-list__select "
                      onChange={handleSiteFilterChange}
                      showSearch={true}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: [
                          { label: t('robotDashboard.unassigned'), value: UNASSIGNED },
                          ...(availableFilters?.sites?.map(site => ({
                            label: site.name,
                            value: site.id,
                          })) || []),
                        ],
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsSitesSelectOpen(isOpen),
                        dropdownVisibleState: isSitesSelectOpen,
                        valueArray: activeSitesFilter || [],
                        dropdownLabel: t('cleaningReportList.filter.assignedSites'),
                      })}
                    />
                  </div>

                  <div
                    className="cleaning-report-list__select-wrapper cleaning-report-list__select-wrapper-machines"
                    id="cleaning-report-list-machines-filter-id"
                  >
                    <Select
                      className="cleaning-report-list__select"
                      loading={areFiltersLoading}
                      onChange={handleMachinesFilterChange}
                      {...SelectUtils.getMultiSelectionProps({
                        mode: 'multiple',
                        options: availableMachines().map(machine => ({
                          label: machine.name,
                          value: machine.id,
                        })),
                        onDropdownVisibleChange: (isOpen: boolean): void => setIsMachinesSelectOpen(isOpen),
                        dropdownVisibleState: isMachinesSelectOpen,
                        valueArray: activeMachinesFilter || [],
                        dropdownLabel: t('cleaningReportList.filter.machinesName'),
                      })}
                    />
                  </div>
                  {/* TODO: Open when BE support */}
                  {/* {activeReportTypeTab === CleaningReportListTab.EXACT && isShowCleaningReportListTab && (
                    <div className="cleaning-report-list__select-wrapper select-wrapper-connection-status">
                      <Select
                        defaultValue=""
                        className={classnames(
                          'cleaning-report-list__machines-filter',
                          'cleaning-report-list__select--connection-status'
                        )}
                        loading={areFiltersLoading}
                        onChange={handleMachineStatusFilterChange}
                        options={[
                          {
                            label: t(MachineStatusItemData[MachineStatus.DATA_UP_TO_DATE].title),
                            value: MachineStatus.DATA_UP_TO_DATE,
                          },
                          {
                            label: t(MachineStatusItemData[MachineStatus.DATA_AGE_ONE_TO_THREE_DAYS].title),
                            value: MachineStatus.DATA_AGE_ONE_TO_THREE_DAYS,
                          },
                          {
                            label: t(MachineStatusItemData[MachineStatus.DATA_AGE_OVER_THREE_DAYS].title),
                            value: MachineStatus.DATA_AGE_OVER_THREE_DAYS,
                          },
                          {
                            label: t(MachineStatusItemData[MachineStatus.NO_DATA].title),
                            value: MachineStatus.NO_DATA,
                          },
                        ]}
                        {...SelectUtils.getSingleSelectionProps({
                          dropdownLabel: t('cleaningReportList.filter.machineStatus'),
                          dropdownVisibleState: isStatusSelectOpen,
                          value: activeLastActivityAtFilter !== undefined ? activeLastActivityAtFilter : '',
                          onDropdownVisibleChange: (isOpen: boolean): void => setIsStatusSelectOpen(isOpen),
                          showSearch: false,
                        })}
                      />
                    </div>
                  )} */}
                  {activeClassificationTypeTab === MachineClassification.Robot && isRobotIntegrationEnabled && (
                    <div
                      className="cleaning-report-list__select-wrapper
                    cleaning-report-list__select-wrapper-task-completion"
                    >
                      <Select
                        className={classnames(
                          'cleaning-report-list__select',
                          'cleaning-report-list__select--task-completion'
                        )}
                        loading={areFiltersLoading}
                        onChange={handleTaskCompletionFilterChange}
                        {...SelectUtils.getMultiSelectionProps({
                          mode: 'multiple',
                          options: [
                            ...((availableFilters?.taskCompletion || []).map(taskCompletion => ({
                              label: t(`robotDashboard.taskCompletion.${taskCompletion.label}`),
                              value: taskCompletion.value,
                            })) || []),
                          ],
                          onDropdownVisibleChange: (isOpen: boolean): void => setIsTaskCompletionsSelectOpen(isOpen),
                          dropdownVisibleState: isTasksCompletionsSelectOpen,
                          valueArray: taskCompletionFilter || [],
                          dropdownLabel: t('cleaningReportList.filter.taskCompletion.title'),
                        })}
                        showSearch={false}
                      />
                    </div>
                  )}

                  <div className="cleaning-report-list__select-wrapper">
                    <SecondaryButton
                      size="s"
                      onClick={resetFilterSelected}
                      className="cleaning-report-list__filter-button"
                      key="cleaning-report-list__filter-reset-button"
                    >
                      <SvgIcon name="arrowSkip" className="cleaning-report-list__filter-reset-button-icon" />
                      <span>{t('common.resetAll')}</span>
                    </SecondaryButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cleaning-report-list__body">
          <div className="cleaning-report-list__body-content">
            {isShowCleaningReportListTab && activeClassificationTypeTab !== MachineClassification.Robot ? (
              <>
                {/* TODO: Commenting out totals tab for Interclean, might be able to fully removed later */}
                {/* <div className="cleaning-report-list__container--fluid"> */}
                {/*  <Tabs */}
                {/*    className="cleaning-report-list__report-type-tabs" */}
                {/*    destroyInactiveTabPane={true} */}
                {/*    items={tabs} */}
                {/*    onChange={handleReportTypeTabChange} */}
                {/*  /> */}
                {/* </div> */}
                {/* {activeReportTypeTab === CleaningReportListTab.TOTALS && ( */}
                {/*  <CleaningReportListTotals activeTabKey={MachineClassification.Gcd} /> */}
                {/* )} */}
                {activeReportTypeTab === CleaningReportListTab.EXACT && (
                  <CleaningReportListExact activeTabKey={MachineClassification.Gcd} />
                )}
              </>
            ) : (
              <>
                {showRobotTab && activeClassificationTypeTab === MachineClassification.Robot && (
                  <div className="cleaning-report-list__container--fluid">
                    <CleaningReportListRobot activeTabKey={activeClassificationTypeTab} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <ReportSubscriptionDrawer />
      </StyledCleaningReportList>
    </>
  );
};
