import { singleton } from 'tsyringe';
import {
  SiteAssignMachineResponse,
  SiteDelete,
  SiteDetails,
  SiteList,
  SiteUnassignMachineResponse,
  SiteUpdateResponse,
  SiteCreate,
  SiteAddressesSuggestionResponse,
  SiteAddressCoordinateResponse,
  SiteGeofenceSetupResponse,
  SiteMachinesAvailable,
  SiteAssignManagersResponse,
  SiteUnassignManagerResponse,
  SiteListWithOperatingTime,
  SiteMachineVariantData,
  SiteTagsList,
  SiteTagsUpdateResponse,
  SiteMachinesList,
  SiteMachinesListWithVariantData,
} from './interfaces/Site.types';
import {
  SiteClient,
  SiteClientAssignMachineOptions,
  SiteClientDeleteOptions,
  SiteClientDetailsOptions,
  SiteClientListOptions,
  SiteClientUnassignMachineOptions,
  SiteClientUpdateOptions,
  SiteClientCreateOptions,
  SiteClientGetAddressesSuggestionOptions,
  SiteClientGetAddressCoordinateOptions,
  SiteClientSetGeofenceOptions,
  SiteClientListAvailableSiteMachinesOptions,
  SiteClientManagerListOptions,
  SiteClientAssignManagersOptions,
  SiteClientUnassignManagerOptions,
  SiteClientListSitesWithOperatingTimeOptions,
  SiteClientListTagsOptions,
  SiteClientUpdateSiteTagsOptions,
  SiteClientListWithRobotDataOptions,
} from './SiteClient';
import { Optional } from 'lib/types/Optional';

export type SiteServiceListOptions = SiteClientListOptions;
export type SiteServiceDetailsOptions = SiteClientDetailsOptions;
export type SiteServiceUpdateOptions = SiteClientUpdateOptions;
export type SiteServiceUpdateSiteTagsOptions = SiteClientUpdateSiteTagsOptions;

export type SiteServiceAssignMachineOptions = SiteClientAssignMachineOptions;
export type SiteServiceUnassignMachineOptions = SiteClientUnassignMachineOptions;
export type SiteServiceGetAddressesSuggestionOptions = SiteClientGetAddressesSuggestionOptions;
export type SiteServiceGetAddressCoordinateOptions = SiteClientGetAddressCoordinateOptions;
export type SiteServiceSetGeofenceOptions = SiteClientSetGeofenceOptions;
export type SiteServiceAvailableSiteMachinesOptions = SiteClientListAvailableSiteMachinesOptions;
export type SiteServiceAssignManagersOptions = SiteClientAssignManagersOptions;
export type SiteServiceUnassignManagerOptions = SiteClientUnassignManagerOptions;

export type SiteServiceManagerListOptions = SiteClientManagerListOptions;
export type SiteServiceListSitesWithOperatingTimeOptions = SiteClientListSitesWithOperatingTimeOptions;
export type SiteServiceListTagsOptions = SiteClientListTagsOptions;

export type SiteServiceListWithRobotDataOptions = SiteClientListWithRobotDataOptions;

@singleton()
export class SiteService {
  constructor(private siteClient: SiteClient) {}

  public list = async ({ paginationOptions, filter, sortOptions }: SiteServiceListOptions): Promise<SiteList> => {
    const { data } = await this.siteClient.list({ paginationOptions, filter, sortOptions });
    return data;
  };

  public listForSelect = async ({ paginationOptions, filter }: SiteServiceListOptions): Promise<SiteList> => {
    const { data } = await this.siteClient.listForSelect({ paginationOptions, filter });
    return data;
  };

  public get = async ({ id, machinePaginationOptions }: SiteServiceDetailsOptions): Promise<SiteDetails> => {
    const { data } = await this.siteClient.get({ id, machinePaginationOptions });
    return data;
  };

  public getMachinePictures = async ({
    id,
    machinePaginationOptions,
  }: SiteServiceDetailsOptions): Promise<SiteMachineVariantData> => {
    const { data } = await this.siteClient.getMachinePictures({ id, machinePaginationOptions });
    return data;
  };

  public create = async (input: SiteClientCreateOptions): Promise<Optional<SiteCreate>> => {
    const { data } = await this.siteClient.create(input);
    return data;
  };

  public update = async (input: SiteServiceUpdateOptions): Promise<Optional<SiteUpdateResponse>> => {
    const { data } = await this.siteClient.update(input);

    return data;
  };

  public updateSiteTags = async ({
    id,
    input,
  }: SiteServiceUpdateSiteTagsOptions): Promise<Optional<SiteTagsUpdateResponse>> => {
    const { data } = await this.siteClient.updateSiteTags({ id, input });
    return data;
  };

  public delete = async ({ id }: SiteClientDeleteOptions): Promise<Optional<SiteDelete>> => {
    const res = await this.siteClient.delete({ id });
    const { data } = res;
    return data;
  };

  public assignMachine = async ({
    siteId,
    machineIds,
  }: SiteServiceAssignMachineOptions): Promise<Optional<SiteAssignMachineResponse>> => {
    const { data } = await this.siteClient.assignMachine({ siteId, machineIds });

    return data;
  };

  public unassignMachine = async ({
    siteId,
    machineId,
  }: SiteServiceUnassignMachineOptions): Promise<Optional<SiteUnassignMachineResponse>> => {
    const { data } = await this.siteClient.unassignMachine({ siteId, machineId });

    return data;
  };

  public getAddressesSuggestion = async ({
    filter,
    paginationOptions,
  }: SiteServiceGetAddressesSuggestionOptions): Promise<SiteAddressesSuggestionResponse> => {
    const { data } = await this.siteClient.getAddressesSuggestion({ filter, paginationOptions });
    return data;
  };

  public getAddressCoordinate = async ({
    filter,
  }: SiteServiceGetAddressCoordinateOptions): Promise<SiteAddressCoordinateResponse> => {
    const { data } = await this.siteClient.getAddressCoordinate({ filter });
    return data;
  };

  public setGeofence = async ({
    siteId,
    geofence,
  }: SiteServiceSetGeofenceOptions): Promise<Optional<SiteGeofenceSetupResponse>> => {
    const { data } = await this.siteClient.setGeofence({ siteId, geofence });
    return data;
  };

  public listAvailableSiteMachines = async ({
    id,
    filter,
    machinePaginationOptions,
  }: SiteServiceAvailableSiteMachinesOptions): Promise<SiteMachinesAvailable> => {
    const { data } = await this.siteClient.listAvailableSiteMachines({ id, filter, machinePaginationOptions });
    return data;
  };

  public assignManagers = async ({
    siteId,
    userIds,
  }: SiteServiceAssignManagersOptions): Promise<Optional<SiteAssignManagersResponse>> => {
    const { data } = await this.siteClient.assignManagers({ siteId, userIds });

    return data;
  };

  public unassignManager = async ({
    siteId,
    userId,
  }: SiteServiceUnassignManagerOptions): Promise<Optional<SiteUnassignManagerResponse>> => {
    const { data } = await this.siteClient.unassignManager({ siteId, userId });

    return data;
  };

  public listSitesWithOperatingTime = async ({
    paginationOptions,
    period,
  }: SiteServiceListSitesWithOperatingTimeOptions): Promise<SiteListWithOperatingTime> => {
    const { data } = await this.siteClient.listSitesWithOperatingTime({ paginationOptions, period });

    return data;
  };

  public listTags = async ({ filter, paginationOptions }: SiteServiceListTagsOptions): Promise<SiteTagsList> => {
    const { data } = await this.siteClient.listTags({ filter, paginationOptions });
    return data;
  };

  public listForRobotDashboard = async ({
    paginationOptions,
    filter,
    sortOptions,
  }: SiteClientListOptions): Promise<SiteList> => {
    const { data } = await this.siteClient.listForRobotDashboard({ paginationOptions, filter, sortOptions });

    return data;
  };

  public listWithCleaningStatistic = async ({
    paginationOptions,
    filter,
    cleaningStatisticFilter,
  }: SiteServiceListWithRobotDataOptions): Promise<SiteList> => {
    const { data } = await this.siteClient.listWithCleaningStatistic({
      paginationOptions,
      filter,
      cleaningStatisticFilter,
    });

    return data;
  };

  public listWithMachines = async ({
    paginationOptions,
    filter,
    machinesOptions,
    cleaningStatisticFilter,
  }: SiteServiceListWithRobotDataOptions): Promise<SiteMachinesList> => {
    const { data } = await this.siteClient.listWithMachines({
      paginationOptions,
      filter,
      machinesOptions,
      cleaningStatisticFilter,
    });

    return data;
  };

  public listWithMachinesTelemetries = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteServiceListWithRobotDataOptions): Promise<SiteMachinesList> => {
    const { data } = await this.siteClient.listWithMachinesTelemetries({ paginationOptions, filter, machinesOptions });

    return data;
  };

  public listWithMachinesLatestCtr = async ({
    paginationOptions,
    filter,
    machinesOptions,
  }: SiteServiceListWithRobotDataOptions): Promise<SiteMachinesList> => {
    const { data } = await this.siteClient.listWithMachinesLatestCtr({ paginationOptions, filter, machinesOptions });

    return data;
  };

  public listWithMachinesLatestRoutine = async ({
    paginationOptions,
    filter,
    machinesOptions,
    filterLatestRoutine,
  }: SiteServiceListWithRobotDataOptions): Promise<SiteMachinesList> => {
    const { data } = await this.siteClient.listWithMachinesLatestRoutine({
      paginationOptions,
      filter,
      machinesOptions,
      filterLatestRoutine,
    });

    return data;
  };

  public listWithMachinesPictures = async ({
    paginationOptions,
    filter,
    machinesOptions,
    cleaningStatisticFilter,
  }: SiteServiceListWithRobotDataOptions): Promise<SiteMachinesListWithVariantData> => {
    const { data } = await this.siteClient.listWithMachinesPictures({
      paginationOptions,
      filter,
      machinesOptions,
      cleaningStatisticFilter,
    });

    return data;
  };
}
