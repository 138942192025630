import { Form, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as cleaningReportListSelectors from '../../state/cleaningReportListSelectors';
import { CleaningReportListActions } from '../../state/cleaningReportListActions';
import { StyledReportSubscriptionDrawer } from './ReportSubscriptionDrawer.styles';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { selectHasAccessToRobots } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { Switch } from 'lib/components/Switch/Switch';
import { Radio } from 'lib/components/Radio/Radio';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { CleaningReportSubscriptionInterval } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import {
  CleaningReportSubscriptionForm,
  convertDataToSave,
  convertResData,
} from 'app/modules/cleaning/utils/ConvertReportSubscriptionUtils';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';

export const ReportSubscriptionDrawer = (): JSX.Element => {
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const [formInstance] = Form.useForm();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector(cleaningReportListSelectors.selectIsReportSubscriptionModalVisible);
  const isGetSubscriptionLoading = useSelector(cleaningReportListSelectors.selectIsGetSubscriptionLoading);
  const isSaveSubscriptionLoading = useSelector(cleaningReportListSelectors.selectIsSaveSubscriptionLoading);
  const data = useSelector(cleaningReportListSelectors.selectIsGetSubscriptionData);
  const [isEventReport, setIsEventReport] = useState(false);
  const [isRobotReport, setIsRobotReport] = useState(false);
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);
  const [fields, setFields] = useState<Optional<CleaningReportSubscriptionForm>>(undefined);

  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const haveRobots = useSelector(selectHasAccessToRobots);

  const hideReportSubscriptionDrawer = useCallback(() => {
    dispatch(CleaningReportListActions.hideReportSubscriptionDrawer());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    setFields(formInstance.getFieldsValue());
    formInstance.submit();
  }, [formInstance]);

  const handleValuesChange = (): void => {
    setFields(formInstance.getFieldsValue());
  };

  const onFinish = useCallback(
    (values: CleaningReportSubscriptionForm) => {
      const params = convertDataToSave(values);

      analyticsLinkActivated({
        linkName: AnalyticsLink.REPORT_SUBSCRIPTION_CHANGED,
      });

      dispatch(
        CleaningReportListActions.saveReportSubscriptionRequest({
          lang: Translations.getSupportedLanguageCode(i18n.language),
          cleaningReportSubscriptions: params,
        })
      );
    },
    [analyticsLinkActivated, dispatch, i18n.language]
  );

  const handleDisableSaveBtn = useCallback((): void => {
    const isEventValidFile = formInstance.getFieldValue('eventPDF') || formInstance.getFieldValue('eventXLS');
    const isEventReportTrue = formInstance.getFieldValue('eventSwitchReport');
    const eventInterval = formInstance.getFieldValue('eventInterval');
    const isEventReportValid = isEventReportTrue ? isEventValidFile && eventInterval : true;

    const isRobotValidFile = formInstance.getFieldValue('robotPDF') || formInstance.getFieldValue('robotXLS');
    const isRobotReportTrue = formInstance.getFieldValue('robotSwitchReport');
    const robotInterval = formInstance.getFieldValue('robotInterval');
    const isRobotReportValid = isRobotReportTrue ? isRobotValidFile && robotInterval : true;

    setIsSaveBtnDisable(!(isEventReportValid && isRobotReportValid));
  }, [formInstance]);

  const onValuesChange = useCallback((): void => {
    setIsEventReport(formInstance.getFieldValue('eventSwitchReport'));
    setIsRobotReport(formInstance.getFieldValue('robotSwitchReport'));
    handleDisableSaveBtn();

    setFields(formInstance.getFieldsValue());
  }, [formInstance, handleDisableSaveBtn]);

  useEffect(() => {
    const converted = convertResData(data);
    formInstance.setFieldsValue(converted);
    onValuesChange();
  }, [formInstance, data, onValuesChange]);

  useEffect(() => {
    if (isVisible) {
      dispatch(CleaningReportListActions.getReportSubscriptionRequest());
    }
  }, [dispatch, isVisible]);

  useAnalyticsSetForm({
    name: AnalyticsForm.CHANGE_REPORT_SUBSCRIPTION,
    fields: fields as Record<string, any>,
    isVisible,
  });

  return (
    <StyledReportSubscriptionDrawer
      className="subscription-drawer"
      size="small"
      open={isVisible}
      onClose={hideReportSubscriptionDrawer}
    >
      <div className="report-subscription-drawer__header">
        <h1 className="report-subscription-drawer__title">
          {t('cleaningReportList.reportSubscription.reportSubscription')}
        </h1>
      </div>
      <div className="report-subscription-drawer__body">
        <p className="report-subscription-drawer__description">
          {t('cleaningReportList.reportSubscription.modalBody')}
        </p>
        <p className="report-subscription-drawer__description">
          {t('cleaningReportList.reportSubscription.unsubscribeHint')}
        </p>
        <Form
          form={formInstance}
          name="report-subscription-drawer"
          layout="vertical"
          onVolumeChange={handleValuesChange}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={onValuesChange}
        >
          <Spin size="large" spinning={isGetSubscriptionLoading}>
            <div className="report-subscription-drawer__event-report">
              <Form.Item
                name="eventSwitchReport"
                className="report-subscription-drawer__input-group"
                valuePropName="checked"
              >
                <Switch className="report-subscription-drawer__switch" />
              </Form.Item>
              <div style={{ opacity: isEventReport ? 1 : 0.5 }}>
                <p className="report-subscription-drawer__switch-title">
                  {t('cleaningReportList.reportSubscription.eventReport')}
                </p>
                <p className="report-subscription-drawer__switch-description">
                  {t('cleaningReportList.reportSubscription.eventReportDes')}
                </p>

                <Form.Item name="eventInterval" className="report-subscription-drawer__input-group">
                  <Radio.Group disabled={!isEventReport}>
                    <div className="report-subscription-drawer__option">
                      <Radio
                        value={CleaningReportSubscriptionInterval.Monthly}
                        name="eventMonthly"
                        disabled={!isEventReport}
                      />
                      <p>{t('cleaningReportList.reportSubscription.reportMonthly')}</p>
                    </div>
                    <div className="report-subscription-drawer__option">
                      <Radio
                        value={CleaningReportSubscriptionInterval.Weekly}
                        name="eventWeekly"
                        disabled={!isEventReport}
                      />
                      <p>{t('cleaningReportList.reportSubscription.reportWeekly')}</p>
                    </div>
                  </Radio.Group>
                </Form.Item>

                <div className="report-subscription-drawer__option">
                  <Form.Item
                    name="eventPDF"
                    className="report-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={!isEventReport} className="report-subscription-drawer__checkbox" />
                  </Form.Item>
                  <p>{t('cleaningReportList.reportSubscription.PDF')}</p>
                </div>
                <div className="report-subscription-drawer__option">
                  <Form.Item
                    name="eventXLS"
                    className="report-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={!isEventReport} className="report-subscription-drawer__checkbox" />
                  </Form.Item>
                  <p>{t('cleaningReportList.reportSubscription.XLS')}</p>
                </div>
              </div>
            </div>
            {isRobotIntegrationEnabled && haveRobots && (
              <div className="report-subscription-drawer__robot-report">
                <Form.Item
                  name="robotSwitchReport"
                  className="report-subscription-drawer__input-group"
                  valuePropName="checked"
                >
                  <Switch className="report-subscription-drawer__switch" />
                </Form.Item>
                <div style={{ opacity: isRobotReport ? 1 : 0.5 }}>
                  <p className="report-subscription-drawer__switch-title">
                    {t('cleaningReportList.reportSubscription.robotReport')}
                  </p>
                  <p className="report-subscription-drawer__switch-description">
                    {t('cleaningReportList.reportSubscription.robotReportDes')}
                  </p>

                  <Form.Item name="robotInterval" className="report-subscription-drawer__input-group">
                    <Radio.Group disabled={!isRobotReport}>
                      <div className="report-subscription-drawer__option">
                        <Radio
                          value={CleaningReportSubscriptionInterval.Monthly}
                          name="robotMonthly"
                          disabled={!isRobotReport}
                        />
                        <p>{t('cleaningReportList.reportSubscription.reportMonthly')}</p>
                      </div>
                      <div className="report-subscription-drawer__option">
                        <Radio
                          value={CleaningReportSubscriptionInterval.Weekly}
                          name="robotWeekly"
                          disabled={!isRobotReport}
                        />
                        <p>{t('cleaningReportList.reportSubscription.reportWeekly')}</p>
                      </div>
                    </Radio.Group>
                  </Form.Item>

                  <div className="report-subscription-drawer__option">
                    <Form.Item
                      name="robotPDF"
                      className="report-subscription-drawer__input-group"
                      valuePropName="checked"
                    >
                      <Checkbox disabled={!isRobotReport} className="report-subscription-drawer__checkbox" />
                    </Form.Item>
                    <p>{t('cleaningReportList.reportSubscription.PDF')}</p>
                  </div>
                  <div className="report-subscription-drawer__option">
                    <Form.Item
                      name="robotXLS"
                      className="report-subscription-drawer__input-group"
                      valuePropName="checked"
                    >
                      <Checkbox disabled={!isRobotReport} className="report-subscription-drawer__checkbox" />
                    </Form.Item>
                    <p>{t('cleaningReportList.reportSubscription.XLS')}</p>
                  </div>
                </div>
              </div>
            )}
          </Spin>
        </Form>
      </div>

      <div className="report-subscription-drawer__footer">
        <SecondaryButton size="s" onClick={hideReportSubscriptionDrawer} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </SecondaryButton>
        <PrimaryButton
          disabled={isSaveBtnDisable}
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleSave}
          loading={isSaveSubscriptionLoading}
        >
          {t('common.save')}
        </PrimaryButton>
      </div>
    </StyledReportSubscriptionDrawer>
  );
};
