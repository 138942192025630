import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotViewModel } from '../../../RobotViewModel';
import { RobotBatteryPercentIcon } from '../RobotBatteryIcon/RobotBatteryIcon';
import { KIRA_CV50, KIRA_CV_50 } from '../../../utils/constants';
import { StyledRobotBattery } from './RobotBattery.styles';
import { Odometer } from 'lib/components/Odometer/Odometer';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export const RobotBattery = ({
  robot,
  batteryPackNumber,
}: {
  robot: Machine;
  batteryPackNumber?: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const robotModelView = new RobotViewModel(robot);
  const isCharging = useMemo(() => robotModelView.getCharging, [robotModelView.getCharging]);

  const getBatteryOfRelevantRobotType = useCallback(() => {
    if (
      robot.type?.name?.toLocaleUpperCase().includes(KIRA_CV50) ||
      robot.type?.name?.toLocaleUpperCase().includes(KIRA_CV_50)
    ) {
      if (batteryPackNumber === 1) {
        return robotModelView.getBatteryDataCV50.batteryPack1.stateOfCharge.toString();
      }

      return robotModelView.getBatteryDataCV50.batteryPack2.stateOfCharge.toString();
    }

    return robotModelView.getBatteryChargeLevel.toString();
  }, [
    robot.type?.name,
    batteryPackNumber,
    robotModelView.getBatteryChargeLevel,
    robotModelView.getBatteryDataCV50.batteryPack1.stateOfCharge,
    robotModelView.getBatteryDataCV50.batteryPack2.stateOfCharge,
  ]);

  return (
    <StyledRobotBattery className="robot-battery">
      <div className="robot-battery__wrapper">
        <div className="robot-battery__icon">
          {isCharging ? (
            <SvgIcon name="batteryCharging" />
          ) : (
            <RobotBatteryPercentIcon batteryPercentageNumber={Number(getBatteryOfRelevantRobotType())} />
          )}
        </div>
        <div className="robot-battery__data">
          <Odometer value={getBatteryOfRelevantRobotType()} />
          <span className="robot-battery__unit">&#37;</span>
        </div>
      </div>
      <span className="robot-battery__label">{t('robotDashboard.battery')}</span>
    </StyledRobotBattery>
  );
};
