import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { noop } from 'lodash-es';
import React, { useEffect, useMemo } from 'react';
import { Permission } from '../../../../../../../config/permissions';
import { useAnalyticsSetPageInfo } from '../../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsSetPageInfo';
import { useAnalyticsAddUserAttributes } from '../../../../../../cross-cutting-concerns/analytics/hooks/useAnalyticsAddUserAttributes';
import { AnalyticsLink } from '../../../../../../cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { selectPermissions } from '../../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { isUserPermitted } from '../../../../../../cross-cutting-concerns/authentication/utils/isUserPermitted';
import {
  Maybe,
  SortOrders,
  Role,
  User,
  Status,
} from '../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { FeatureFlagSelectors } from '../../../../../../cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { MachineListActions } from '../../../../../machine-inventory/machine-list/state/machineListActions';
import { UserListActions } from '../../../state/userListActions';
import { UserListSelectors } from '../../../state/UserListSelectors';
import { SentInvitations } from '../../SentInvitations/SentInvitations';
import { getUserListSystemColumns } from './columns/UserListSystem.columns';
import { StyledUserListSystem } from './UserListSystem.styles';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { Table } from 'lib/components/Table/Table';
import { InfiniteScrollConstants } from 'config/constants';

export const UserListSystem = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const data = useSelector(UserListSelectors.selectActiveUserData) || [];
  const isLoading = !!useSelector(UserListSelectors.selectActiveUserIsLoading);
  const totalCount = useSelector(UserListSelectors.selectActiveUserTotalCount) || 0;
  const nextPaginationToken = useSelector(UserListSelectors.selectActiveUserNextPaginationToken);
  const isLoadingMoreData = useSelector(UserListSelectors.selectActiveUserIsLoadingMoreData);
  const userPermissions = useSelector(selectPermissions);

  const isSortingAndFilteringEnabled = features.USER_LIST_FILTERS_AND_SORTING;
  const isCustomerUnauthorizeEnabled = features.CUSTOMER_UNAUTHORIZE;

  useAnalyticsAddUserAttributes({
    countAuthorized: totalCount,
  });
  useAnalyticsSetPageInfo({});

  const hasPermissionCustomerUserUnauthorize = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Customer.User.UNAUTHORIZE]),
    [userPermissions]
  );

  useEffect(() => {
    dispatch(
      UserListActions.getUserListRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: '',
        },
        filter: {
          status: Status.Active,
        },
      })
    );
  }, [dispatch]);

  const loadMore = (): void => {
    dispatch(
      UserListActions.getUserListMoreDataRequest({
        paginationOptions: {
          limit: InfiniteScrollConstants.MAX_ITEMS,
          paginationToken: nextPaginationToken,
        },
        filter: {
          status: Status.Active,
        },
      })
    );
  };

  useEffect(
    () => (): void => {
      dispatch(UserListActions.resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isSortingAndFilteringEnabled) return;

    dispatch(UserListActions.getUserListFiltersRequest());
  }, [dispatch, isSortingAndFilteringEnabled]);

  const handleUpdate = ({ id }: User, role: Role): void => {
    const params = {
      id,
      input: {
        role,
      },
    };

    if (role === Role.CustomerAdmin) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.ASSIGN_ADMINISTRATOR_ROLE,
      });
    } else if (role === Role.CustomerStrategicManager) {
      analyticsLinkActivated({
        linkName: AnalyticsLink.ASSIGN_STRATEGIC_MANAGER_ROLE,
      });
    }

    dispatch(UserListActions.setUserRoleRequest(params));
  };

  const handleDelete = (user: Optional<User>): void => {
    noop(user);
  };

  const handleChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(MachineListActions.setActiveSortField(sorter.field as Maybe<string>));
    dispatch(MachineListActions.setActiveSortOrder(sorterOrder as Maybe<SortOrders>));
  };

  const tableColumns = getUserListSystemColumns({
    t,
    i18n,
    handleUpdate,
    handleDelete,
    isCustomerUnauthorizeEnabled,
    hasPermissionCustomerUserUnauthorize,
  });

  return (
    <StyledUserListSystem className="user-list-system">
      <Table
        dataSource={data}
        loading={isLoading}
        className="user-list-system__table"
        columns={tableColumns}
        rowKey="email"
        onChange={handleChange}
        sortDirections={['ascend', 'descend', 'ascend']}
        infiniteScroll={{
          id: 'user-list-infinite-scroll',
          next: loadMore,
          nextPaginationToken,
          isLoadingMoreData,
        }}
        showScrollButtons={true}
      />
      <br />
      <SentInvitations />
    </StyledUserListSystem>
  );
};
