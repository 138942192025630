import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spin, Empty } from 'antd';
import * as robotDashboardSelectors from '../../../../state/RobotDashboardSelectors';
import { RobotDashboardActions } from '../../../../state/RobotDashboardSlice';
import { RobotAssignSiteModal } from '../../../../modals/components/RobotAssignSiteModal';
import { RobotUtils } from '../../../../../../../../utils/robot/RobotUtils';
import { selectRobotAssignSiteModalVisible } from '../../../../modals/state/RobotModalsSelectors';
import { StyledRobotListGroupedByStatus } from './RobotListGroupedByStatus.styles';
import { RobotBoxList } from './RobotBoxList/RobotBoxList';
import { RobotRowList } from './RobotRowList/RobotRowList';
import {
  MachineClassification,
  InputFilterMachinesList,
  RoutineStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { ALL_VALUE_SELECT_OPTION, OpenSearch, UNASSIGNED } from 'config/constants';
import { RobotStatusDisplayName } from 'app/modules/machine-inventory/interfaces/Robot.types';

interface IRobotListGroupedByStatusProps {
  isListLayout: boolean;
}

export const RobotListGroupedByStatus = ({ isListLayout }: IRobotListGroupedByStatusProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const robots = useSelector(robotDashboardSelectors.selectRobotListData);
  const isRobotsLoading = useSelector(robotDashboardSelectors.selectIsRobotListLoading);

  const unassignedRobots = useSelector(robotDashboardSelectors.selectRobotUnassignedListData);
  const isUnassignedRobotsLoading = useSelector(robotDashboardSelectors.selectIsRobotUnassignedListLoading);

  const robotStatusesFilter = useSelector(robotDashboardSelectors.selectStatus);
  const activeSitesFilter = useSelector(robotDashboardSelectors.selectActiveSites);

  const isRobotAssignModalVisible = useSelector(selectRobotAssignSiteModalVisible);

  const handleDispatchRobotListRequest = useCallback(
    (filter: InputFilterMachinesList) => {
      dispatch(
        RobotDashboardActions.robotListRequest({
          filter: {
            ...filter,
            siteIds:
              activeSitesFilter[0] !== ALL_VALUE_SELECT_OPTION
                ? activeSitesFilter.filter(value => value !== UNASSIGNED)
                : undefined,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
          filterLatestRoutine: {
            status: RoutineStatus.InProgress,
          },
        })
      );
    },
    [dispatch, activeSitesFilter]
  );

  const handleDispatchUnassignedRobotListRequest = useCallback(
    (filter: InputFilterMachinesList) => {
      dispatch(
        RobotDashboardActions.robotUnassignedListRequest({
          filter: {
            ...filter,
            isStandalone: true,
          },
          paginationOptions: {
            limit: OpenSearch.MAX_RESULT_WINDOW,
          },
          filterLatestRoutine: {
            status: RoutineStatus.InProgress,
          },
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    const { connectionStatuses, robotStatuses } = RobotUtils.getRobotStatusesFilter(robotStatusesFilter);

    const filterMachinesList: InputFilterMachinesList = {
      classifications: [MachineClassification.Robot],
      connectionStatuses: connectionStatuses.length > 0 ? connectionStatuses : undefined,
      robotStatuses: robotStatuses.length > 0 ? robotStatuses : undefined,
      isRobotStatusEmpty: robotStatusesFilter.includes(RobotStatusDisplayName.NA) || undefined,
    };

    const isOnlyUnassignedFilter = activeSitesFilter[0] === UNASSIGNED && activeSitesFilter.length === 1;
    const isWithUnassignedFilter = activeSitesFilter.includes(UNASSIGNED) && activeSitesFilter.length > 1;
    const isNotIncludedUnassignedFilterOrAll =
      activeSitesFilter[0] === ALL_VALUE_SELECT_OPTION || !activeSitesFilter.includes(UNASSIGNED);

    if (isOnlyUnassignedFilter) {
      handleDispatchUnassignedRobotListRequest(filterMachinesList);
      dispatch(RobotDashboardActions.robotListResetState());
    }

    if (isNotIncludedUnassignedFilterOrAll) {
      handleDispatchRobotListRequest(filterMachinesList);
      dispatch(RobotDashboardActions.robotUnassignedListResetState());
    }

    if (isWithUnassignedFilter) {
      handleDispatchRobotListRequest(filterMachinesList);
      handleDispatchUnassignedRobotListRequest(filterMachinesList);
    }
  }, [
    dispatch,
    activeSitesFilter,
    robotStatusesFilter,
    handleDispatchUnassignedRobotListRequest,
    handleDispatchRobotListRequest,
  ]);

  useEffect(
    () => (): void => {
      dispatch(RobotDashboardActions.robotListResetState());
      dispatch(RobotDashboardActions.robotUnassignedListResetState());
    },
    [dispatch]
  );

  if (isRobotsLoading || isUnassignedRobotsLoading) {
    return (
      <StyledRobotListGroupedByStatus>
        <div className="robot-list-grouped-by-status__load-indicator">
          <Spin size="default" />
        </div>
      </StyledRobotListGroupedByStatus>
    );
  }

  return (
    <StyledRobotListGroupedByStatus>
      {(robots && robots.length > 0) || (unassignedRobots && unassignedRobots.length > 0) ? (
        <>{isListLayout ? <RobotBoxList /> : <RobotRowList />}</>
      ) : (
        <Empty
          className="robot-list-grouped-by-status__empty-container"
          description={t('robotDashboard.noRobotsFoundMessage')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
      {isRobotAssignModalVisible && <RobotAssignSiteModal />}
    </StyledRobotListGroupedByStatus>
  );
};
