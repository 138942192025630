import { inject, injectable } from 'tsyringe';
import { gql, ApolloQueryResult, FetchResult } from '@apollo/client';
import {
  GetOperatorResponse,
  NotificationDeleteOperatorResponse,
  NotificationOperatorsResponse,
  OperatorResponse,
} from './interfaces/Operator.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  MutationNotificationUpsertOperatorArgs,
  QueryNotificationListOperatorArgs,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export type OperatorClientListOptions = QueryNotificationListOperatorArgs;
export type OperatorModalClientOptions = MutationNotificationUpsertOperatorArgs;
export interface OperatorClientDeleteOptions {
  id: string;
}

@injectable()
export class OperatorClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({
    paginationOptions,
  }: OperatorClientListOptions): Promise<ApolloQueryResult<NotificationOperatorsResponse>> =>
    this.client.query({
      query: gql`
        query NotificationListOperator($paginationOptions: InputPagingOptions!) {
          notificationListOperator(paginationOptions: $paginationOptions) {
            data {
              email
              id
              language
              name
              notifyAutonomousTaskStopped
              notifyCleaningTaskFinished
              notifyCleaningTaskInterrupted
              notifyEmergencyStop
              notifyErrorOccurred
              notifyInformation
              notifyLocationStatus
              notifyNoWorkStart
              notifyPCM
              notifyOther
              notifyReminder
              notifyResources
              notifyServiceNeeded
              phoneNumber
              numberOfAssignedMachines
            }
            metadata {
              paginationToken
              totalCount
            }
          }
        }
      `,
      variables: {
        paginationOptions,
      },
    });

  public upsert = async ({ input }: MutationNotificationUpsertOperatorArgs): Promise<FetchResult<OperatorResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation NotificationUpsertOperator($input: InputNotificationUpsertOperator!) {
          notificationUpsertOperator(input: $input) {
            data {
              id
              email
              name
              phoneNumber
              notifyAutonomousTaskStopped
              notifyCleaningTaskFinished
              notifyCleaningTaskInterrupted
              notifyEmergencyStop
              notifyErrorOccurred
              notifyInformation
              notifyLocationStatus
              notifyNoWorkStart
              notifyOther
              notifyPCM
              notifyReminder
              notifyResources
              notifyServiceNeeded
              assignedMachines {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public get = async (id: string): Promise<FetchResult<GetOperatorResponse>> =>
    this.client.query({
      query: gql`
        query NotificationGetOperator($id: String!) {
          notificationGetOperator(id: $id) {
            data {
              id
              name
              email
              phoneNumber
              assignedMachines {
                id
                name
              }
              language
              notifyAutonomousTaskStopped
              notifyCleaningTaskFinished
              notifyCleaningTaskInterrupted
              notifyEmergencyStop
              notifyErrorOccurred
              notifyInformation
              notifyLocationStatus
              notifyNoWorkStart
              notifyOther
              notifyPCM
              notifyReminder
              notifyResources
              notifyServiceNeeded
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public delete = async ({
    id,
  }: OperatorClientDeleteOptions): Promise<FetchResult<NotificationDeleteOperatorResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation NotificationDeleteOperator($id: String!) {
          notificationDeleteOperator(id: $id) {
            data
          }
        }
      `,
      variables: {
        id,
      },
    });
}
