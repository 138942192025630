import { useTranslation } from 'react-i18next';
import { Form, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import * as authenticationSelectors from '../../../../../../cross-cutting-concerns/authentication/state/authenticationSelectors';
import { StyledNotificationListSubscriptionDrawer } from './NotificationListSubscriptionDrawer.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import {
  selectGetNotificationSubscriptionData,
  selectIsGetNotificationSubscriptionModalLoading,
  selectIsNotificationSubscriptionModalVisible,
  selectIsSaveNotificationSubscriptionModalSaving,
} from 'app/modules/notification/modals/state/notificationModalsSelectors';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { useAnalyticsLinkActivated } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsLinkActivated';
import { NotificationModalActions } from 'app/modules/notification/modals/state/notificationModalsActions';
import { AnalyticsForm, AnalyticsLink } from 'app/cross-cutting-concerns/analytics/interfaces/Analytics.types';
import { Optional } from 'lib/types/Optional';
import { useAnalyticsSetForm } from 'app/cross-cutting-concerns/analytics/hooks/useAnalyticsSetForm';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { DateTime } from 'lib/utils/date-handling/DateTime';

interface NotificationSubscriptionForm {
  locationStatus: boolean;
  reminder: boolean;
  emergencyStop: boolean;
  resources: boolean;
  errorOccurred: boolean;
  autonomousTaskStopped: boolean;
  cleaningTaskInterrupted: boolean;
  cleaningTaskFinished: boolean;
  noWorkStart: boolean;
  pcm: boolean;
  other: boolean;
  information: boolean;
  serviceNeeded: boolean;
}

export const NotificationListSubscriptionDrawer = (): JSX.Element => {
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const analyticsLinkActivated = useAnalyticsLinkActivated();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [subscriptionForm] = Form.useForm<NotificationSubscriptionForm>();
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [fields, setFields] = useState<Optional<NotificationSubscriptionForm>>({
    locationStatus: false,
    reminder: false,
    emergencyStop: false,
    resources: false,
    errorOccurred: false,
    autonomousTaskStopped: false,
    cleaningTaskInterrupted: false,
    cleaningTaskFinished: false,
    noWorkStart: false,
    pcm: false,
    other: false,
    information: false,
    serviceNeeded: false,
  });

  const isVisible = useSelector(selectIsNotificationSubscriptionModalVisible);
  const isLoading = useSelector(selectIsGetNotificationSubscriptionModalLoading);
  const isSaving = useSelector(selectIsSaveNotificationSubscriptionModalSaving);
  const isMachineReminderListEnabled = features.MACHINE_REMINDER_LIST;
  const getNotificationUserData = useSelector(selectGetNotificationSubscriptionData);

  const isRobotIntegrationEnabled = features.ROBOT_INTEGRATION;
  const hasAccessToRobots = useSelector(authenticationSelectors.selectHasAccessToRobots);
  const showRobotCategories = isRobotIntegrationEnabled && hasAccessToRobots;

  const handleValuesChange = (): void => {
    setFields(subscriptionForm.getFieldsValue());
    setIsSelectAll(Object.values(subscriptionForm.getFieldsValue()).every(Boolean));
  };

  const handleSelectAll = useCallback(
    (e: CheckboxChangeEvent) => {
      Object.keys(fields || {}).forEach(key => {
        subscriptionForm.setFieldsValue({ [key]: e.target.checked });
      });
      setIsSelectAll(e.target.checked);
    },
    [fields, subscriptionForm]
  );

  const handleSave = useCallback(() => {
    setFields(subscriptionForm.getFieldsValue());
    subscriptionForm.submit();
  }, [subscriptionForm]);

  const handleClose = useCallback(() => {
    dispatch(NotificationModalActions.hideNotificationSubscriptionModal());
    dispatch(NotificationModalActions.getNotificationSubscriptionResetState());
    setIsSelectAll(false);
    subscriptionForm.resetFields();
  }, [dispatch, subscriptionForm]);

  const onFinish = useCallback(
    (values: NotificationSubscriptionForm) => {
      let notifyReminderSetting = getNotificationUserData?.notifyReminder ?? false;
      let notifyEmergencyStopSetting = getNotificationUserData?.notifyEmergencyStop ?? false;
      let notifyResourcesSetting = getNotificationUserData?.notifyResources ?? false;
      let notifyErrorOccurredSetting = getNotificationUserData?.notifyErrorOccurred ?? false;
      let notifyAutonomousTaskStoppedSetting = getNotificationUserData?.notifyAutonomousTaskStopped ?? false;
      let notifyCleaningTaskInterruptedSetting = getNotificationUserData?.notifyCleaningTaskInterrupted ?? false;
      let notifyCleaningTaskFinishedSetting = getNotificationUserData?.notifyCleaningTaskFinished ?? false;
      let notifyNoWorkStartSetting = getNotificationUserData?.notifyNoWorkStart ?? false;
      let notifyPCMSetting = getNotificationUserData?.notifyPCM ?? false;
      let notifyOtherSetting = getNotificationUserData?.notifyOther ?? false;
      let notifyInformationSetting = getNotificationUserData?.notifyInformation ?? false;
      let notifyServiceNeededSetting = getNotificationUserData?.notifyServiceNeeded ?? false;
      let notifyLocationStatusSetting = getNotificationUserData?.notifyLocationStatus ?? false;

      if (isMachineReminderListEnabled) {
        notifyReminderSetting = values.reminder || false;
      }

      if (showRobotCategories) {
        notifyEmergencyStopSetting = values.emergencyStop || false;
        notifyResourcesSetting = values.resources || false;
        notifyErrorOccurredSetting = values.errorOccurred || false;
        notifyAutonomousTaskStoppedSetting = values.autonomousTaskStopped || false;
        notifyCleaningTaskInterruptedSetting = values.cleaningTaskInterrupted || false;
        notifyCleaningTaskFinishedSetting = values.cleaningTaskFinished || false;
        notifyInformationSetting = values.information || false;
        notifyServiceNeededSetting = values.serviceNeeded || false;
      }
      notifyNoWorkStartSetting = values.noWorkStart || false;
      notifyPCMSetting = values.pcm || false;
      notifyOtherSetting = values.other || false;
      notifyLocationStatusSetting = values.locationStatus || false;

      dispatch(
        NotificationModalActions.saveNotificationSubscriptionRequest({
          lang: Translations.getSupportedLanguageCode(i18n.language).toLowerCase(),
          notifyLocationStatus: notifyLocationStatusSetting,
          notifyReminder: notifyReminderSetting,
          notifyEmergencyStop: notifyEmergencyStopSetting,
          notifyResources: notifyResourcesSetting,
          notifyErrorOccurred: notifyErrorOccurredSetting,
          notifyAutonomousTaskStopped: notifyAutonomousTaskStoppedSetting,
          notifyCleaningTaskInterrupted: notifyCleaningTaskInterruptedSetting,
          notifyCleaningTaskFinished: notifyCleaningTaskFinishedSetting,
          notifyNoWorkStart: notifyNoWorkStartSetting,
          notifyPCM: notifyPCMSetting,
          notifyOther: notifyOtherSetting,
          notifyInformation: notifyInformationSetting,
          notifyServiceNeeded: notifyServiceNeededSetting,
          timezone: DateTime.getBrowserTimeZone(),
        })
      );

      analyticsLinkActivated({
        linkName: AnalyticsLink.NOTIFICATION_SUBSCRIPTION_CHANGED,
      });
    },
    [
      getNotificationUserData?.notifyReminder,
      getNotificationUserData?.notifyEmergencyStop,
      getNotificationUserData?.notifyResources,
      getNotificationUserData?.notifyErrorOccurred,
      getNotificationUserData?.notifyAutonomousTaskStopped,
      getNotificationUserData?.notifyCleaningTaskInterrupted,
      getNotificationUserData?.notifyCleaningTaskFinished,
      getNotificationUserData?.notifyNoWorkStart,
      getNotificationUserData?.notifyPCM,
      getNotificationUserData?.notifyOther,
      getNotificationUserData?.notifyInformation,
      getNotificationUserData?.notifyServiceNeeded,
      getNotificationUserData?.notifyLocationStatus,
      isMachineReminderListEnabled,
      showRobotCategories,
      dispatch,
      i18n.language,
      analyticsLinkActivated,
    ]
  );

  useEffect(() => {
    if (isVisible) {
      dispatch(NotificationModalActions.getNotificationSubscriptionRequest());
    }
  }, [dispatch, isVisible]);

  useEffect(() => {
    if (getNotificationUserData && isVisible) {
      if (Object.values(getNotificationUserData).every(Boolean)) {
        setIsSelectAll(true);
      }

      subscriptionForm.setFieldsValue({
        locationStatus: getNotificationUserData.notifyLocationStatus || false,
        reminder: getNotificationUserData.notifyReminder || false,
        emergencyStop: getNotificationUserData.notifyEmergencyStop || false,
        resources: getNotificationUserData.notifyResources || false,
        errorOccurred: getNotificationUserData.notifyErrorOccurred || false,
        autonomousTaskStopped: getNotificationUserData.notifyAutonomousTaskStopped || false,
        cleaningTaskInterrupted: getNotificationUserData.notifyCleaningTaskInterrupted || false,
        cleaningTaskFinished: getNotificationUserData.notifyCleaningTaskFinished || false,
        noWorkStart: getNotificationUserData.notifyNoWorkStart || false,
        pcm: getNotificationUserData.notifyPCM || false,
        other: getNotificationUserData.notifyOther || false,
        information: getNotificationUserData.notifyInformation || false,
        serviceNeeded: getNotificationUserData.notifyServiceNeeded || false,
      });
    }
  }, [subscriptionForm, getNotificationUserData, isVisible]);

  useAnalyticsSetForm({
    name: AnalyticsForm.CHANGE_NOTIFICATION_SUBSCRIPTION,
    fields: fields as Record<string, any>,
    isVisible,
  });

  useEffect(() => () => handleClose(), [handleClose]);

  return (
    <StyledNotificationListSubscriptionDrawer
      className="notification-list__notificaiton-subscription-panel-drawer"
      size="extra-small"
      open={isVisible}
      onClose={handleClose}
      loading={isLoading}
      destroyOnClose
    >
      <div className="notification-subscription-drawer__header">
        <h1 className="notification-subscription-drawer__title">
          {t('notificationList.notificationSubscription.title')}
        </h1>
      </div>
      <div className="notification-subscription-drawer__body">
        <p className="notification-subscription-drawer__description">
          {t('notificationList.notificationSubscription.description')}
        </p>

        <Form
          form={subscriptionForm}
          name="notification-subscription"
          layout="vertical"
          autoComplete="off"
          onValuesChange={handleValuesChange}
          onFinish={onFinish}
        >
          <div className="notification-subscription-drawer__body">
            <Spin size="large" spinning={isLoading}>
              <Form.Item
                className="
                  notification-subscription-drawer__input-group
                  notification-subscription-drawer__input-group--select-all"
              >
                <Checkbox
                  className="notification-subscription-drawer__checkbox"
                  onChange={handleSelectAll}
                  checked={isSelectAll}
                  value={isSelectAll}
                >
                  {t('common.selectAll')}
                </Checkbox>
              </Form.Item>

              <Form.Item
                name="locationStatus"
                className="notification-subscription-drawer__input-group"
                valuePropName="checked"
              >
                <Checkbox className="notification-subscription-drawer__checkbox">
                  {t('notificationList.notificationSubscription.locationStatus')}
                </Checkbox>
              </Form.Item>

              {isMachineReminderListEnabled && (
                <Form.Item
                  name="reminder"
                  className="notification-subscription-drawer__input-group"
                  valuePropName="checked"
                >
                  <Checkbox className="notification-subscription-drawer__checkbox">
                    {t('notificationList.notificationSubscription.reminder')}
                  </Checkbox>
                </Form.Item>
              )}

              <Form.Item
                name="noWorkStart"
                className="notification-subscription-drawer__input-group"
                valuePropName="checked"
              >
                <Checkbox className="notification-subscription-drawer__checkbox">
                  {t('notificationList.notificationSubscription.noWorkStart')}
                </Checkbox>
              </Form.Item>

              <Form.Item name="pcm" className="notification-subscription-drawer__input-group" valuePropName="checked">
                <Checkbox className="notification-subscription-drawer__checkbox">
                  {t('notificationList.notificationSubscription.pcm')}
                </Checkbox>
              </Form.Item>

              {showRobotCategories && (
                <>
                  <Form.Item
                    name="emergencyStop"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.emergencyStop')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="resources"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.resources')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="errorOccurred"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.errorOccurred')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="cleaningTaskInterrupted"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.cleaningTaskInterrupted')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="cleaningTaskFinished"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.cleaningTaskFinished')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="information"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.information')}
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="serviceNeeded"
                    className="notification-subscription-drawer__input-group"
                    valuePropName="checked"
                  >
                    <Checkbox className="notification-subscription-drawer__checkbox">
                      {t('notificationList.notificationSubscription.serviceNeeded')}
                    </Checkbox>
                  </Form.Item>
                </>
              )}
            </Spin>
          </div>
        </Form>
      </div>

      <div className="notification-subscription-drawer__footer">
        <SecondaryButton onClick={handleClose} size="m" className="notification-subscription-drawer__close-button">
          {t('common.close')}
        </SecondaryButton>
        <PrimaryButton
          onClick={handleSave}
          size="m"
          loading={isSaving}
          className="notification-subscription-drawer__save-button"
        >
          {t('machineList.reportSubscription.saveSubscription')}
        </PrimaryButton>
      </div>
    </StyledNotificationListSubscriptionDrawer>
  );
};
