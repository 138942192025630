import { Button, Menu } from 'antd';
// eslint-disable-next-line import/no-unresolved
import { ItemType } from 'antd/es/menu/interface';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash-es';
import { useTheme } from 'styled-components';
import { SvgIcon } from '../../../../lib/components/SvgIcon/SvgIcon';
import { useDependencies } from '../../../cross-cutting-concerns/dependency-injection/hooks/useDependencies';
import { StyledSideNav } from './SideNav.styles';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import { selectHasAccessToRobots } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { RoutePaths } from 'config/route-paths';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';

const SIDENAV_COLLAPSING_STATE_KEY = 'sidenav-collapsing-state';

export const SideNav = (): JSX.Element => {
  const { t } = useTranslation();
  const routeMatch = useMatch('/:path/*');
  const { menuItemManager } = useDependencies();
  const [menuItems, setMenuItems] = useState<ItemType[]>([]);
  const [footerMenuItems, setFooterMenuItems] = useState<ItemType[]>([]);
  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const haveRobots = useSelector(selectHasAccessToRobots);
  const theme = useTheme();
  const { browserStorage } = useDependencies();

  let initialCollapsingState: boolean;

  try {
    initialCollapsingState = browserStorage.get(SIDENAV_COLLAPSING_STATE_KEY) === 'true';
  } catch (error) {
    initialCollapsingState = true;
  }

  const [isCollapsed, setIsCollapsed] = useState(initialCollapsingState);

  useEffect(() => {
    const getMenuItemsAsync = async (): Promise<void> => {
      const items = await menuItemManager.getMenuItems();
      const preparedItems = items.map(item => ({
        label: (
          <Tooltip title={t(item.translationKey)} placement="right">
            <NavLink to={item.path}>{t(item.translationKey)}</NavLink>
          </Tooltip>
        ),
        key: item.path,
        icon: item.icon,
      }));

      setMenuItems(
        features.ROBOTICS_DASHBOARD_PAGE && haveRobots
          ? preparedItems
          : preparedItems.filter(item => item.key !== RoutePaths.ROBOT_DASHBOARD)
      );
    };
    getMenuItemsAsync();
  }, [menuItemManager, t, features.ROBOTICS_DASHBOARD_PAGE, haveRobots]);

  useEffect(() => {
    const getMenuItemsAsync = async (): Promise<void> => {
      const items = await menuItemManager.getFooterMenuItems();
      const preparedItems = items.map(item => ({
        label: (
          <Tooltip title={t(item.translationKey)} placement="right">
            <NavLink to={item.path}>{t(item.translationKey)}</NavLink>
          </Tooltip>
        ),
        key: item.path,
        icon: item.icon,
      }));

      setFooterMenuItems(preparedItems);
    };
    getMenuItemsAsync();
  }, [menuItemManager, t, features.ROBOTICS_DASHBOARD_PAGE, haveRobots]);

  const firstMenuItem = first(menuItems);

  const handleToggleCollapsing = (): void => {
    const newCollapsingState = !isCollapsed;
    browserStorage.set(SIDENAV_COLLAPSING_STATE_KEY, String(newCollapsingState));
    setIsCollapsed(newCollapsingState);
  };

  return (
    <StyledSideNav
      width={isCollapsed ? theme.constants.sideBar.width.collapsed : theme.constants.sideBar.width.full}
      className={classnames('side-nav', {
        'side-nav--collapsed': isCollapsed,
      })}
    >
      <Menu selectedKeys={[routeMatch?.pathnameBase || (String(firstMenuItem?.key) ?? '')]} items={menuItems} />
      <Menu
        className="side-bar__footer-menu"
        selectedKeys={[routeMatch?.pathnameBase || (String(firstMenuItem?.key) ?? '')]}
        items={footerMenuItems}
      />
      <Button
        className="side-nav__toggle"
        type="text"
        onClick={handleToggleCollapsing}
        icon={<SvgIcon name="doubleChevronRight" />}
      />
    </StyledSideNav>
  );
};
