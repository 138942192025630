/* eslint-disable max-len */
import { Action } from 'redux';
import {
  FilterLastActivityAt,
  MachineClassification,
  ResponseCleaningReportSubscriptions,
  SortOrders,
  TaskCompletion,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { CleaningTaskReportClientListOptions } from '../../CleaningTaskReportClient';
import { CleaningTaskReportServiceRouteImageOptions } from '../../CleaningTaskReportService';
import { CleaningTaskReportListResponse } from '../../interfaces/CleaningTaskReport.types';
import {
  CleaningReportListAvailableFilters,
  CleaningReportsExportRequest,
  CleaningReportsExportGetFile,
  CleaningReportsExportRobotDetailsRequest,
} from '../../interfaces/CleaningReport.types';
import { CleaningReportServiceMachineListFilterOptions } from '../../CleaningReportService';
import {
  CleaningReportClientRequestExportCleaningReportsOptions,
  CleaningReportClientRequestRobotDetailsExportOptions,
} from '../../CleaningReportClient';
import { CleaningClientReportSubscriptionOptions } from '../../CleaningReportSubscriptionClient';
import { Optional } from 'lib/types/Optional';
import { MachineClientListMachinesWithCleaningReportOptions } from 'app/modules/machine-inventory/MachineClient';
import {
  MachinesAvailableToFilter,
  MachinesListWithCleaningReport,
} from 'app/modules/machine-inventory/interfaces/Machine.types';

// CleaningReportListTotals

export type IGetCleaningReportListTotalsRequestOptions = MachineClientListMachinesWithCleaningReportOptions;
export type IGetCleaningReportListTotalsRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_REQUEST
> & {
  payload: MachineClientListMachinesWithCleaningReportOptions;
};

export type IGetCleaningReportListTotalsSuccessOptions = MachinesListWithCleaningReport;
export type IGetCleaningReportListTotalsSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_REQUEST
> & {
  payload: MachinesListWithCleaningReport;
};

export type IGetCleaningReportListTotalsErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_ERROR
>;

export type IChangeCleaningReportListTotalsTablePage = Action<
  typeof CleaningReportListActions.TOTALS_CHANGE_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type IChangeCleaningReportListTotalsTablePageSize = Action<
  typeof CleaningReportListActions.TOTALS_CHANGE_TABLE_PAGE_SIZE
> & {
  payload: {
    pageSize: number;
  };
};

export type ITotalsSetActiveSortField = Action<typeof CleaningReportListActions.TOTALS_SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type ITotalsSetActiveSortOrder = Action<typeof CleaningReportListActions.TOTALS_SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

// CleaningReportListExact
// GET_CLEANING_REPORT_LIST_EXACT
export type IGetCleaningReportListExactRequestOptions = CleaningTaskReportClientListOptions;
export type IGetCleaningReportListExactRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_REQUEST
> & {
  payload: CleaningTaskReportClientListOptions;
};

export type IGetCleaningReportListExactSuccessOptions = CleaningTaskReportListResponse;
export type IGetCleaningReportListExactSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_REQUEST
> & {
  payload: CleaningTaskReportListResponse;
};

export type IGetCleaningReportListExactErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_ERROR
>;

// GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA
export type IGetCleaningReportListExactMoreDataRequestOptions = CleaningTaskReportClientListOptions;
export type IGetCleaningReportListExactMoreDataRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST
> & {
  payload: CleaningTaskReportClientListOptions;
};

export type IGetCleaningReportListExactMoreDataSuccessOptions = CleaningTaskReportListResponse;
export type IGetCleaningReportListExactMoreDataSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST
> & {
  payload: CleaningTaskReportListResponse;
};

export type IGetCleaningReportListExactMoreDataErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_ERROR
>;

export type IChangeCleaningReportListExactTablePage = Action<
  typeof CleaningReportListActions.EXACT_CHANGE_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type IChangeCleaningReportListExactTablePageSize = Action<
  typeof CleaningReportListActions.EXACT_CHANGE_TABLE_PAGE_SIZE
> & {
  payload: {
    pageSize: number;
  };
};

export type IExactSetActiveSortField = Action<typeof CleaningReportListActions.EXACT_SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type IExactSetActiveSortOrder = Action<typeof CleaningReportListActions.EXACT_SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

// CleaningReportListRobot
// GET_CLEANING_REPORT_LIST_ROBOT
export type IGetCleaningReportListRobotRequestOptions = CleaningTaskReportClientListOptions;
export type IGetCleaningReportListRobotRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_REQUEST
> & {
  payload: CleaningTaskReportClientListOptions;
};

export type IGetCleaningReportListRobotSuccessOptions = CleaningTaskReportListResponse;
export type IGetCleaningReportListRobotSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_SUCCESS
> & {
  payload: CleaningTaskReportListResponse;
};

export type IGetCleaningReportListRobotErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_ERROR
>;

// GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA
export type IGetCleaningReportListRobotMoreDataRequestOptions = CleaningTaskReportClientListOptions;
export type IGetCleaningReportListRobotMoreDataRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_REQUEST
> & {
  payload: CleaningTaskReportClientListOptions;
};

export type IGetCleaningReportListRobotMoreDataSuccessOptions = CleaningTaskReportListResponse;
export type IGetCleaningReportListRobotMoreDataSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_SUCCESS
> & {
  payload: CleaningTaskReportListResponse;
};

export type IGetCleaningReportListRobotMoreDataErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_ERROR
>;

export type IChangeCleaningReportListRobotTablePage = Action<
  typeof CleaningReportListActions.ROBOT_CHANGE_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type IChangeCleaningReportListRobotTablePageSize = Action<
  typeof CleaningReportListActions.ROBOT_CHANGE_TABLE_PAGE_SIZE
> & {
  payload: {
    pageSize: number;
  };
};

export type IRobotSetActiveSortField = Action<typeof CleaningReportListActions.ROBOT_SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type IRobotSetActiveSortOrder = Action<typeof CleaningReportListActions.ROBOT_SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IRobotResetState = Action<typeof CleaningReportListActions.ROBOT_RESET_STATE>;

// Cleaning report robot route image
export type IGetCleaningReportRobotRouteImageRequestOptions = CleaningTaskReportServiceRouteImageOptions;
export type IGetCleaningReportRobotRouteImageRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST
> & {
  payload: IGetCleaningReportRobotRouteImageRequestOptions;
};

export interface IGetCleaningReportRobotRouteImageSuccessOptions {
  url: string;
  imageObjectUrl: string;
}
export type IGetCleaningReportRobotRouteImageSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS
> & {
  payload: IGetCleaningReportRobotRouteImageSuccessOptions;
};

export type IGetCleaningReportRobotRouteImageErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR
>;

export type IResetCleaningReportRobotRouteImageAction = Action<
  typeof CleaningReportListActions.RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE
>;

export interface IDownloadCleaningReportRobotRouteImageOptions {
  imageAsDataUrl: string;
  imageTitle: string;
}

export type IDownloadCleaningReportRobotRouteImageAction = Action<
  typeof CleaningReportListActions.RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE
> & {
  payload: IDownloadCleaningReportRobotRouteImageOptions;
};

// Cleaning report list filters
export type ISetActivePeriodFilter = Action<typeof CleaningReportListActions.SET_ACTIVE_PERIOD_FILTER> & {
  payload: {
    period: {
      startDate: string;
      endDate: string;
    };
  };
};

export type ISetActiveMachineStatusFilter = Action<
  typeof CleaningReportListActions.SET_ACTIVE_MACHINE_STATUS_FILTER
> & {
  payload: {
    lastActivityAt: Optional<FilterLastActivityAt>;
  };
};

export type IGetCleaningReportListFiltersRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_REQUEST
> & {
  payload: MachineClassification[];
};

export type IGetCleaningReportListFiltersSuccessOptions = CleaningReportListAvailableFilters;
export type IGetCleaningReportListFiltersSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_REQUEST
> & {
  payload: CleaningReportListAvailableFilters;
};

export type IGetCleaningReportListFiltersErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_ERROR
>;

export type ISetActiveMachinesFilter = Action<typeof CleaningReportListActions.SET_ACTIVE_MACHINES_FILTER> & {
  payload: {
    machines: Optional<string[]>;
  };
};

export type ISetActiveSiteFilter = Action<typeof CleaningReportListActions.SET_ACTIVE_SITE_FILTER> & {
  payload: {
    siteIds: Optional<string[]>;
  };
};

export type ISetActiveTaskCompletionFilter = Action<
  typeof CleaningReportListActions.SET_ACTIVE_TASK_COMPLETION_FILTER
> & {
  payload: {
    taskCompletion: Optional<TaskCompletion[] | string[]>;
  };
};

export type IGetMachineListFilterRequestOptions = CleaningReportServiceMachineListFilterOptions;
export type IGetMachineListFilterRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_REQUEST
> & {
  payload: CleaningReportServiceMachineListFilterOptions;
};

export type IGetMachineListFilterSuccessOptions = MachinesAvailableToFilter;
export type IGetMachineListFilterSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_SUCCESS
> & {
  payload: MachinesAvailableToFilter;
};

export type IGetMachineListFilterErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_ERROR
>;

export type IRequestExportCleaningReportsRequestOptions = CleaningReportClientRequestExportCleaningReportsOptions;
export type IRequestExportCleaningReportsRequestAction = Action<
  typeof CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_REQUEST
> & {
  payload: CleaningReportClientRequestExportCleaningReportsOptions;
};

export type IRequestExportCleaningReportsSuccessOptions = Optional<CleaningReportsExportRequest>;
export type IRequestExportCleaningReportsSuccessAction = Action<
  typeof CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_REQUEST
> & {
  payload: Optional<CleaningReportsExportRequest>;
};

export type IRequestExportCleaningReportsErrorAction = IErrorAction<
  typeof CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_ERROR
>;

export interface IPollGetExportRobotDetailsCleaningReportsFileRequestOptions {
  requestId: string;
}
export type IPollGetExportRobotDetailsCleaningReportsFileRequestAction = Action<
  typeof CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_REQUEST
> & {
  payload: IPollGetExportRobotDetailsCleaningReportsFileRequestOptions;
};

export type IPollGetExportRobotDetailsCleaningReportsFileSuccessOptions = CleaningReportsExportGetFile;
export type IPollGetExportRobotDetailsCleaningReportsFileSuccessAction = Action<
  typeof CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_SUCCESS
> & {
  payload: CleaningReportsExportGetFile;
};

export type IPollGetExportRobotDetailsCleaningReportsFileErrorAction = IErrorAction<
  typeof CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_ERROR
>;

export type IRequestExportRobotDetailsCleaningReportsRequestOptions =
  CleaningReportClientRequestRobotDetailsExportOptions;

export type IRequestExportRobotDetailsCleaningReportsRequestAction = Action<
  typeof CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST
> & {
  payload: CleaningReportClientRequestRobotDetailsExportOptions;
};

export type IRequestExportRobotDetailsCleaningReportsSuccessOptions =
  Optional<CleaningReportsExportRobotDetailsRequest>;
export type IRequestExportRobotDetailsCleaningReportsSuccessAction = Action<
  typeof CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST
> & {
  payload: Optional<CleaningReportsExportRobotDetailsRequest>;
};

export type IRequestExportRobotDetailsCleaningReportsErrorAction = IErrorAction<
  typeof CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_ERROR
>;

export type ISetSelectDeviceTypeOptions = MachineClassification;
export type ISetSelectDeviceTypeAction = Action<typeof CleaningReportListActions.SET_SELECT_DEVICE_TYPE> & {
  payload: ISetSelectDeviceTypeOptions;
};

export type IResetCleaningReportListActiveFilters = Action<
  typeof CleaningReportListActions.RESET_CLEANING_REPORT_LIST_ACTIVE_FILTERS
>;

export type IGetStandAloneMachineListFilterRequestOptions = CleaningReportServiceMachineListFilterOptions;
export type IGetStandAloneMachineListFilterRequestAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_REQUEST
> & {
  payload: CleaningReportServiceMachineListFilterOptions;
};

export type IGetStandAloneMachineListFilterSuccessOptions = MachinesAvailableToFilter;
export type IGetStandAloneMachineListFilterSuccessAction = Action<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_SUCCESS
> & {
  payload: MachinesAvailableToFilter;
};

export type IGetStandAloneMachineListFilterErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_ERROR
>;

export type ISetSearchTextFilter = Action<typeof CleaningReportListActions.SET_SEARCH_TEXT_FILTER> & {
  payload: {
    searchText: Optional<string>;
  };
};

// Cleaning report subscription drawer
export type IShowReportSubscriptionDrawerAction = Action<
  typeof CleaningReportListActions.SHOW_REPORT_SUBSCRIPTION_DRAWER
>;
export type IHideReportSubscriptionDrawerAction = Action<
  typeof CleaningReportListActions.HIDE_REPORT_SUBSCRIPTION_DRAWER
>;
export type IReportSubscriptionDrawerIsLoadingAction = Action<
  typeof CleaningReportListActions.REPORT_SUBSCRIPTION_DRAWER_IS_LOADING
>;
export type IReportSubscriptionDrawerIsNotLoadingAction = Action<
  typeof CleaningReportListActions.REPORT_SUBSCRIPTION_DRAWER_IS_NOT_LOADING
>;

export type IGetReportSubscriptionRequestAction = Action<
  typeof CleaningReportListActions.GET_REPORT_SUBSCRIPTION_REQUEST
>;
export type IGetReportSubscriptionSuccessOptions = ResponseCleaningReportSubscriptions;
export type IGetReportSubscriptionSuccessAction = Action<
  typeof CleaningReportListActions.GET_REPORT_SUBSCRIPTION_REQUEST
> & {
  payload: ResponseCleaningReportSubscriptions;
};
export type IGetReportSubscriptionErrorAction = IErrorAction<
  typeof CleaningReportListActions.GET_REPORT_SUBSCRIPTION_ERROR
>;

export type ISaveReportSubscriptionRequestOptions = CleaningClientReportSubscriptionOptions;
export type ISaveReportSubscriptionRequestAction = Action<
  typeof CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_REQUEST
> & {
  payload: ISaveReportSubscriptionRequestOptions;
};
export type ISaveReportSubscriptionSuccessOptions = Optional<ResponseCleaningReportSubscriptions>;
export type ISaveReportSubscriptionSuccessAction = Action<
  typeof CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_REQUEST
> & {
  payload: Optional<ResponseCleaningReportSubscriptions>;
};
export type ISaveReportSubscriptionErrorAction = IErrorAction<
  typeof CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_ERROR
>;

export class CleaningReportListActions {
  public static GET_CLEANING_REPORT_LIST_TOTALS_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_TOTALS_REQUEST';

  public static GET_CLEANING_REPORT_LIST_TOTALS_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_TOTALS_SUCCESS';

  public static GET_CLEANING_REPORT_LIST_TOTALS_ERROR = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_TOTALS_ERROR';

  public static TOTALS_CHANGE_TABLE_PAGE = 'CLEANING_REPORT_LIST::TOTALS_CHANGE_TABLE_PAGE';
  public static TOTALS_CHANGE_TABLE_PAGE_SIZE = 'CLEANING_REPORT_LIST::TOTALS_CHANGE_TABLE_PAGE_SIZE';
  public static TOTALS_RESET_STATE = 'CLEANING_REPORT_LIST::TOTALS_RESET_STATE';
  public static TOTALS_SET_ACTIVE_SORT_FIELD = 'CLEANING_REPORT_LIST::TOTALS_SET_ACTIVE_SORT_FIELD';
  public static TOTALS_SET_ACTIVE_SORT_ORDER = 'CLEANING_REPORT_LIST::TOTALS_SET_ACTIVE_SORT_ORDER';

  public static GET_CLEANING_REPORT_LIST_EXACT_REQUEST = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_EXACT_REQUEST';
  public static GET_CLEANING_REPORT_LIST_EXACT_SUCCESS = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_EXACT_SUCCESS';
  public static GET_CLEANING_REPORT_LIST_EXACT_ERROR = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_EXACT_ERROR';

  public static GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST';

  public static GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_SUCCESS';

  public static GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_ERROR =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_ERROR';

  public static EXACT_CHANGE_TABLE_PAGE = 'CLEANING_REPORT_LIST::EXACT_CHANGE_TABLE_PAGE';
  public static EXACT_CHANGE_TABLE_PAGE_SIZE = 'CLEANING_REPORT_LIST::EXACT_CHANGE_TABLE_PAGE_SIZE';
  public static EXACT_RESET_STATE = 'CLEANING_REPORT_LIST::EXACT_RESET_STATE';
  public static EXACT_SET_ACTIVE_SORT_FIELD = 'CLEANING_REPORT_LIST::EXACT_SET_ACTIVE_SORT_FIELD';
  public static EXACT_SET_ACTIVE_SORT_ORDER = 'CLEANING_REPORT_LIST::EXACT_SET_ACTIVE_SORT_ORDER';

  public static GET_CLEANING_REPORT_LIST_ROBOT_REQUEST = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_ROBOT_REQUEST';
  public static GET_CLEANING_REPORT_LIST_ROBOT_SUCCESS = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_ROBOT_SUCCESS';
  public static GET_CLEANING_REPORT_LIST_ROBOT_ERROR = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_ROBOT_ERROR';

  public static GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_REQUEST';

  public static GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_SUCCESS';

  public static GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_ERROR =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_ERROR';

  public static ROBOT_CHANGE_TABLE_PAGE = 'CLEANING_REPORT_LIST::ROBOT_CHANGE_TABLE_PAGE';
  public static ROBOT_CHANGE_TABLE_PAGE_SIZE = 'CLEANING_REPORT_LIST::ROBOT_CHANGE_TABLE_PAGE_SIZE';
  public static ROBOT_RESET_STATE = 'CLEANING_REPORT_LIST::ROBOT_RESET_STATE';
  public static ROBOT_SET_ACTIVE_SORT_FIELD = 'CLEANING_REPORT_LIST::ROBOT_SET_ACTIVE_SORT_FIELD';
  public static ROBOT_SET_ACTIVE_SORT_ORDER = 'CLEANING_REPORT_LIST::ROBOT_SET_ACTIVE_SORT_ORDER';

  public static GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST';

  public static GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS';

  public static GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR';

  public static RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE =
    'CLEANING_REPORT_LIST::RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE';

  public static DOWNLOAD_CLEANING_REPORT_ROBOT_ROUTE_IMAGE =
    'CLEANING_REPORT_LIST::DOWNLOAD_CLEANING_REPORT_ROBOT_ROUTE_IMAGE';

  public static GET_CLEANING_REPORT_LIST_FILTERS_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_FILTERS_REQUEST';

  public static GET_CLEANING_REPORT_LIST_FILTERS_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_FILTERS_SUCCESS';

  public static GET_CLEANING_REPORT_LIST_FILTERS_ERROR = 'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_FILTERS_ERROR';

  public static SET_ACTIVE_PERIOD_FILTER = 'CLEANING_REPORT_LIST::SET_ACTIVE_PERIOD_FILTER';
  public static SET_ACTIVE_MACHINES_FILTER = 'CLEANING_REPORT_LIST::SET_ACTIVE_MACHINES_FILTER';
  public static SET_ACTIVE_SITE_FILTER = 'CLEANING_REPORT_LIST::SET_ACTIVE_SITE_FILTER';
  public static SET_ACTIVE_MACHINE_STATUS_FILTER = 'CLEANING_REPORT_LIST::SET_ACTIVE_MACHINE_STATUS_FILTER';
  public static SET_ACTIVE_TASK_COMPLETION_FILTER = 'CLEANING_REPORT_LIST::SET_ACTIVE_TASK_COMPLETION_FILTER';
  public static RESET_ACTIVE_MACHINE_STATUS_FILTER = 'CLEANING_REPORT_LIST::RESET_ACTIVE_MACHINE_STATUS_FILTER';

  public static GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_REQUEST';

  public static GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_SUCCESS';

  public static GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_ERROR =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_ERROR';

  public static REQUEST_EXPORT_CLEANING_REPORTS_REQUEST =
    'CLEANING_REPORT_LIST::REQUEST_EXPORT_CLEANING_REPORTS_REQUEST';

  public static REQUEST_EXPORT_CLEANING_REPORTS_SUCCESS =
    'CLEANING_REPORT_LIST::REQUEST_EXPORT_CLEANING_REPORTS_SUCCESS';

  public static REQUEST_EXPORT_CLEANING_REPORTS_ERROR = 'CLEANING_REPORT_LIST::REQUEST_EXPORT_CLEANING_REPORTS_ERROR';

  public static POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_REQUEST =
    'CLEANING_REPORT_LIST::POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_REQUEST';

  public static POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_SUCCESS =
    'CLEANING_REPORT_LIST::POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_SUCCESS';

  public static POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_ERROR =
    'CLEANING_REPORT_LIST::POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_ERROR';

  public static EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_RESET_STATE =
    'CLEANING_REPORT_LIST::EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_RESET_STATE';

  public static REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST =
    'CLEANING_REPORT_LIST::REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST';

  public static REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_SUCCESS =
    'CLEANING_REPORT_LIST::REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_SUCCESS';

  public static REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_ERROR =
    'CLEANING_REPORT_LIST::REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_ERROR';

  public static SET_SELECT_DEVICE_TYPE = 'CLEANING_REPORT_LIST::SET_SELECT_DEVICE_TYPE';

  public static RESET_CLEANING_REPORT_LIST_ACTIVE_FILTERS =
    'CLEANING_REPORT_LIST::RESET_CLEANING_REPORT_LIST_ACTIVE_FILTERS';

  public static GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_REQUEST =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_REQUEST';

  public static GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_SUCCESS =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_SUCCESS';

  public static GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_ERROR =
    'CLEANING_REPORT_LIST::GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_ERROR';

  public static RESET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER =
    'RESET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER';

  public static RESET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER = 'RESET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER';
  public static SET_SEARCH_TEXT_FILTER = 'CLEANING_REPORT_LIST::SET_SEARCH_TEXT_FILTER';
  public static RESET_CLEANING_REPORT_LIST_FILTER_SELECTED = 'RESET_CLEANING_REPORT_LIST_FILTER_SELECTED';

  public static SHOW_REPORT_SUBSCRIPTION_DRAWER = 'SUBSCRIPTION_DRAWER::SHOW_REPORT_SUBSCRIPTION_DRAWER';
  public static HIDE_REPORT_SUBSCRIPTION_DRAWER = 'SUBSCRIPTION_DRAWER::HIDE_REPORT_SUBSCRIPTION_DRAWER';
  public static REPORT_SUBSCRIPTION_DRAWER_IS_LOADING = 'SUBSCRIPTION_DRAWER::REPORT_SUBSCRIPTION_DRAWER_IS_LOADING';
  public static REPORT_SUBSCRIPTION_DRAWER_IS_NOT_LOADING =
    'SUBSCRIPTION_DRAWER::REPORT_SUBSCRIPTION_DRAWER_IS_NOT_LOADING';

  public static GET_REPORT_SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_DRAWER::GET_REPORT_SUBSCRIPTION_REQUEST';
  public static GET_REPORT_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_DRAWER::GET_REPORT_SUBSCRIPTION_SUCCESS';
  public static GET_REPORT_SUBSCRIPTION_ERROR = 'SUBSCRIPTION_DRAWER::GET_REPORT_SUBSCRIPTION_ERROR';

  public static SAVE_REPORT_SUBSCRIPTION_REQUEST = 'SUBSCRIPTION_DRAWER::SAVE_REPORT_SUBSCRIPTION_REQUEST';
  public static SAVE_REPORT_SUBSCRIPTION_SUCCESS = 'SUBSCRIPTION_DRAWER::SAVE_REPORT_SUBSCRIPTION_SUCCESS';
  public static SAVE_REPORT_SUBSCRIPTION_ERROR = 'SUBSCRIPTION_DRAWER::SAVE_REPORT_SUBSCRIPTION_ERROR';

  public static getCleaningReportListTotalsRequest({
    paginationOptions,
    filter,
    sortOptions,
    period,
  }: IGetCleaningReportListTotalsRequestOptions): IGetCleaningReportListTotalsRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
        period,
      },
    };
  }

  public static getCleaningReportListTotalsSuccess(
    data: IGetCleaningReportListTotalsSuccessOptions
  ): IGetCleaningReportListTotalsSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_SUCCESS,
      payload: data,
    };
  }

  public static getCleaningReportListTotalsError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportListTotalsErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_TOTALS_ERROR,
      error,
    };
  }

  public static totalsChangeTablePage(data: { page: number }): IChangeCleaningReportListTotalsTablePage {
    return {
      type: CleaningReportListActions.TOTALS_CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static totalsChangeTablePageSize(data: { pageSize: number }): IChangeCleaningReportListTotalsTablePageSize {
    return {
      type: CleaningReportListActions.TOTALS_CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static totalsResetState(): Action<typeof CleaningReportListActions.TOTALS_RESET_STATE> {
    return {
      type: CleaningReportListActions.TOTALS_RESET_STATE,
    };
  }

  public static totalsSetActiveSortField(field: Optional<string>): ITotalsSetActiveSortField {
    return {
      type: CleaningReportListActions.TOTALS_SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static totalsSetActiveSortOrder(order: Optional<SortOrders>): ITotalsSetActiveSortOrder {
    return {
      type: CleaningReportListActions.TOTALS_SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getCleaningReportListExactRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetCleaningReportListExactRequestOptions): IGetCleaningReportListExactRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getCleaningReportListExactSuccess(
    data: IGetCleaningReportListExactSuccessOptions
  ): IGetCleaningReportListExactSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_SUCCESS,
      payload: data,
    };
  }

  public static getCleaningReportListExactError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportListExactErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_ERROR,
      error,
    };
  }

  public static getCleaningReportListExactMoreDataRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetCleaningReportListExactMoreDataRequestOptions): IGetCleaningReportListExactMoreDataRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getCleaningReportListExactMoreDataSuccess(
    data: IGetCleaningReportListExactMoreDataSuccessOptions
  ): IGetCleaningReportListExactMoreDataSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getCleaningReportListExactMoreDataError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportListExactMoreDataErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_EXACT_MORE_DATA_ERROR,
      error,
    };
  }

  public static exactChangeTablePage(data: { page: number }): IChangeCleaningReportListExactTablePage {
    return {
      type: CleaningReportListActions.EXACT_CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static exactChangeTablePageSize(data: { pageSize: number }): IChangeCleaningReportListExactTablePageSize {
    return {
      type: CleaningReportListActions.EXACT_CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static exactResetState(): Action<typeof CleaningReportListActions.EXACT_RESET_STATE> {
    return {
      type: CleaningReportListActions.EXACT_RESET_STATE,
    };
  }

  public static exactSetActiveSortField(field: Optional<string>): IExactSetActiveSortField {
    return {
      type: CleaningReportListActions.EXACT_SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static exactSetActiveSortOrder(order: Optional<SortOrders>): IExactSetActiveSortOrder {
    return {
      type: CleaningReportListActions.EXACT_SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getCleaningReportListRobotRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetCleaningReportListRobotRequestOptions): IGetCleaningReportListRobotRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getCleaningReportListRobotSuccess(
    data: IGetCleaningReportListRobotSuccessOptions
  ): IGetCleaningReportListRobotSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_SUCCESS,
      payload: data,
    };
  }

  public static getCleaningReportListRobotError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportListRobotErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_ERROR,
      error,
    };
  }

  public static getCleaningReportListRobotMoreDataRequest({
    paginationOptions,
    filter,
    sortOptions,
  }: IGetCleaningReportListRobotMoreDataRequestOptions): IGetCleaningReportListRobotMoreDataRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_REQUEST,
      payload: {
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getCleaningReportListRobotMoreDataSuccess(
    data: IGetCleaningReportListRobotMoreDataSuccessOptions
  ): IGetCleaningReportListRobotMoreDataSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_SUCCESS,
      payload: data,
    };
  }

  public static getCleaningReportListRobotMoreDataError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportListRobotMoreDataErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_ROBOT_MORE_DATA_ERROR,
      error,
    };
  }

  public static robotChangeTablePage(data: { page: number }): IChangeCleaningReportListRobotTablePage {
    return {
      type: CleaningReportListActions.ROBOT_CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static robotChangeTablePageSize(data: { pageSize: number }): IChangeCleaningReportListRobotTablePageSize {
    return {
      type: CleaningReportListActions.ROBOT_CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static robotSetActiveSortField(field: Optional<string>): IRobotSetActiveSortField {
    return {
      type: CleaningReportListActions.ROBOT_SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static robotSetActiveSortOrder(order: Optional<SortOrders>): IRobotSetActiveSortOrder {
    return {
      type: CleaningReportListActions.ROBOT_SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static robotResetState(): IRobotResetState {
    return {
      type: CleaningReportListActions.ROBOT_RESET_STATE,
    };
  }

  public static getCleaningReportRobotRouteImageRequest({
    machineId,
    finishedAt,
  }: IGetCleaningReportRobotRouteImageRequestOptions): IGetCleaningReportRobotRouteImageRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_REQUEST,
      payload: {
        machineId,
        finishedAt,
      },
    };
  }

  public static getCleaningReportRobotRouteImageSuccess({
    url,
    imageObjectUrl,
  }: IGetCleaningReportRobotRouteImageSuccessOptions): IGetCleaningReportRobotRouteImageSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_SUCCESS,
      payload: {
        url,
        imageObjectUrl,
      },
    };
  }

  public static getCleaningReportRobotRouteImageError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportRobotRouteImageErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE_ERROR,
      error,
    };
  }

  public static resetCleaningReportRobotRouteImage(): IResetCleaningReportRobotRouteImageAction {
    return {
      type: CleaningReportListActions.RESET_CLEANING_REPORT_ROBOT_ROUTE_IMAGE,
    };
  }

  public static downloadCleaningReportRobotRouteImage({
    imageAsDataUrl,
    imageTitle,
  }: IDownloadCleaningReportRobotRouteImageOptions): IDownloadCleaningReportRobotRouteImageAction {
    return {
      type: CleaningReportListActions.DOWNLOAD_CLEANING_REPORT_ROBOT_ROUTE_IMAGE,
      payload: {
        imageAsDataUrl,
        imageTitle,
      },
    };
  }

  public static getCleaningReportListFiltersRequest(
    classifications: MachineClassification[]
  ): IGetCleaningReportListFiltersRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_REQUEST,
      payload: classifications,
    };
  }

  public static getCleaningReportListFiltersSuccess(
    availableFilters: IGetCleaningReportListFiltersSuccessOptions
  ): IGetCleaningReportListFiltersSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_SUCCESS,
      payload: availableFilters,
    };
  }

  public static getCleaningReportListFiltersError({
    error,
  }: IActionCreatorErrorOptions): IGetCleaningReportListFiltersErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_FILTERS_ERROR,
      error,
    };
  }

  public static setActiveMachinesFilter(machines: Optional<string[]>): ISetActiveMachinesFilter {
    return {
      type: CleaningReportListActions.SET_ACTIVE_MACHINES_FILTER,
      payload: {
        machines,
      },
    };
  }

  public static setActiveSiteFilter(siteIds: Optional<string[]>): ISetActiveSiteFilter {
    return {
      type: CleaningReportListActions.SET_ACTIVE_SITE_FILTER,
      payload: {
        siteIds,
      },
    };
  }

  public static setActivePeriodFilter(period: { startDate: string; endDate: string }): ISetActivePeriodFilter {
    return {
      type: CleaningReportListActions.SET_ACTIVE_PERIOD_FILTER,
      payload: {
        period,
      },
    };
  }

  public static setActiveMachineStatusFilter(
    lastActivityAt: Optional<FilterLastActivityAt>
  ): ISetActiveMachineStatusFilter {
    return {
      type: CleaningReportListActions.SET_ACTIVE_MACHINE_STATUS_FILTER,
      payload: {
        lastActivityAt,
      },
    };
  }

  public static setActiveTaskCompletionFilter(
    taskCompletion: Optional<TaskCompletion[] | string[]>
  ): ISetActiveTaskCompletionFilter {
    return {
      type: CleaningReportListActions.SET_ACTIVE_TASK_COMPLETION_FILTER,
      payload: {
        taskCompletion,
      },
    };
  }

  public static resetActiveMachineStatusFilter(): Action<
    typeof CleaningReportListActions.RESET_ACTIVE_MACHINE_STATUS_FILTER
  > {
    return {
      type: CleaningReportListActions.RESET_ACTIVE_MACHINE_STATUS_FILTER,
    };
  }

  public static getMachineListFilterRequest({
    filter,
  }: IGetMachineListFilterRequestOptions): IGetMachineListFilterRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getMachineListFilterSuccess(
    data: IGetMachineListFilterSuccessOptions
  ): IGetMachineListFilterSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListFilterError({ error }: IActionCreatorErrorOptions): IGetMachineListFilterErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER_ERROR,
      error,
    };
  }

  public static requestExportCleaningReportsRequest({
    filter,
    timezone,
    sortOptions,
    lang,
  }: IRequestExportCleaningReportsRequestOptions): IRequestExportCleaningReportsRequestAction {
    return {
      type: CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_REQUEST,
      payload: {
        filter,
        timezone,
        sortOptions,
        lang,
      },
    };
  }

  public static requestExportCleaningReportsSuccess(
    data: IRequestExportCleaningReportsSuccessOptions
  ): IRequestExportCleaningReportsSuccessAction {
    return {
      type: CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_SUCCESS,
      payload: data,
    };
  }

  public static requestExportCleaningReportsError({
    error,
  }: IActionCreatorErrorOptions): IRequestExportCleaningReportsErrorAction {
    return {
      type: CleaningReportListActions.REQUEST_EXPORT_CLEANING_REPORTS_ERROR,
      error,
    };
  }

  public static pollGetExportRobotDetailsCleaningReportsFileRequest({
    requestId,
  }: IPollGetExportRobotDetailsCleaningReportsFileRequestOptions): IPollGetExportRobotDetailsCleaningReportsFileRequestAction {
    return {
      type: CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_REQUEST,
      payload: {
        requestId,
      },
    };
  }

  public static pollGetExportRobotDetailsCleaningReportsFileSuccess(
    data: IPollGetExportRobotDetailsCleaningReportsFileSuccessOptions
  ): IPollGetExportRobotDetailsCleaningReportsFileSuccessAction {
    return {
      type: CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_SUCCESS,
      payload: data,
    };
  }

  public static pollGetExportRobotDetailsCleaningReportsFileError({
    error,
  }: IActionCreatorErrorOptions): IPollGetExportRobotDetailsCleaningReportsFileErrorAction {
    return {
      type: CleaningReportListActions.POLL_GET_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_FILE_ERROR,
      error,
    };
  }

  public static exportRobotDetailsCleaningReportsResetState(): Action<
    typeof CleaningReportListActions.EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_RESET_STATE
  > {
    return {
      type: CleaningReportListActions.EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_RESET_STATE,
    };
  }

  public static requestExportRobotDetailsCleaningReportsRequest({
    id,
    timezone,
    machineId,
    lang,
  }: IRequestExportRobotDetailsCleaningReportsRequestOptions): IRequestExportRobotDetailsCleaningReportsRequestAction {
    return {
      type: CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_REQUEST,
      payload: {
        id,
        timezone,
        machineId,
        lang,
      },
    };
  }

  public static requestExportRobotDetailsCleaningReportsSuccess(
    data: IRequestExportRobotDetailsCleaningReportsSuccessOptions
  ): IRequestExportRobotDetailsCleaningReportsSuccessAction {
    return {
      type: CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_SUCCESS,
      payload: data,
    };
  }

  public static requestExportRobotDetailsCleaningReportsError({
    error,
  }: IActionCreatorErrorOptions): IRequestExportRobotDetailsCleaningReportsErrorAction {
    return {
      type: CleaningReportListActions.REQUEST_EXPORT_ROBOT_DETAILS_CLEANING_REPORTS_ERROR,
      error,
    };
  }

  public static setSelectDeviceType(deviceType: ISetSelectDeviceTypeOptions): ISetSelectDeviceTypeAction {
    return {
      type: CleaningReportListActions.SET_SELECT_DEVICE_TYPE,
      payload: deviceType,
    };
  }

  public static resetCleaningReportListActiveFiltersState(): IResetCleaningReportListActiveFilters {
    return {
      type: CleaningReportListActions.RESET_CLEANING_REPORT_LIST_ACTIVE_FILTERS,
    };
  }

  public static resetStandAloneMachineListFilter(): Action<
    typeof CleaningReportListActions.RESET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER
  > {
    return {
      type: CleaningReportListActions.RESET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER,
    };
  }

  public static resetMachineListFilter(): Action<
    typeof CleaningReportListActions.RESET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER
  > {
    return {
      type: CleaningReportListActions.RESET_CLEANING_REPORT_LIST_MACHINE_LIST_FILTER,
    };
  }

  public static getStandAloneMachineListFilterRequest({
    filter,
  }: IGetStandAloneMachineListFilterRequestOptions): IGetStandAloneMachineListFilterRequestAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_REQUEST,
      payload: {
        filter,
      },
    };
  }

  public static getStandAloneMachineListFilterSuccess(
    data: IGetStandAloneMachineListFilterSuccessOptions
  ): IGetStandAloneMachineListFilterSuccessAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_SUCCESS,
      payload: data,
    };
  }

  public static getStandAloneMachineListFilterError({
    error,
  }: IActionCreatorErrorOptions): IGetStandAloneMachineListFilterErrorAction {
    return {
      type: CleaningReportListActions.GET_CLEANING_REPORT_LIST_STAND_ALONE_MACHINE_LIST_FILTER_ERROR,
      error,
    };
  }

  public static setSearchText(searchText: Optional<string>): ISetSearchTextFilter {
    return {
      type: CleaningReportListActions.SET_SEARCH_TEXT_FILTER,
      payload: {
        searchText,
      },
    };
  }

  public static resetFilterSelected(): Action<
    typeof CleaningReportListActions.RESET_CLEANING_REPORT_LIST_FILTER_SELECTED
  > {
    return {
      type: CleaningReportListActions.RESET_CLEANING_REPORT_LIST_FILTER_SELECTED,
    };
  }

  public static showReportSubscriptionDrawer(): IShowReportSubscriptionDrawerAction {
    return {
      type: CleaningReportListActions.SHOW_REPORT_SUBSCRIPTION_DRAWER,
    };
  }

  public static hideReportSubscriptionDrawer(): IHideReportSubscriptionDrawerAction {
    return {
      type: CleaningReportListActions.HIDE_REPORT_SUBSCRIPTION_DRAWER,
    };
  }

  public static reportSubscriptionDrawerIsLoading(): IReportSubscriptionDrawerIsLoadingAction {
    return {
      type: CleaningReportListActions.REPORT_SUBSCRIPTION_DRAWER_IS_LOADING,
    };
  }

  public static reportSubscriptionDrawerIsNotLoading(): IReportSubscriptionDrawerIsNotLoadingAction {
    return {
      type: CleaningReportListActions.REPORT_SUBSCRIPTION_DRAWER_IS_NOT_LOADING,
    };
  }

  public static getReportSubscriptionRequest(): IGetReportSubscriptionRequestAction {
    return {
      type: CleaningReportListActions.GET_REPORT_SUBSCRIPTION_REQUEST,
    };
  }

  public static getReportSubscriptionSuccess(
    data: IGetReportSubscriptionSuccessOptions
  ): IGetReportSubscriptionSuccessAction {
    return {
      type: CleaningReportListActions.GET_REPORT_SUBSCRIPTION_SUCCESS,
      payload: data,
    };
  }

  public static getReportSubscriptionError({ error }: IActionCreatorErrorOptions): IGetReportSubscriptionErrorAction {
    return {
      type: CleaningReportListActions.GET_REPORT_SUBSCRIPTION_ERROR,
      error,
    };
  }

  public static saveReportSubscriptionRequest(
    data: ISaveReportSubscriptionRequestOptions
  ): ISaveReportSubscriptionRequestAction {
    return {
      type: CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_REQUEST,
      payload: data,
    };
  }

  public static saveReportSubscriptionSuccess(
    data: ISaveReportSubscriptionSuccessOptions
  ): ISaveReportSubscriptionSuccessAction {
    return {
      type: CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_SUCCESS,
      payload: data,
    };
  }

  public static saveReportSubscriptionError({ error }: IActionCreatorErrorOptions): ISaveReportSubscriptionErrorAction {
    return {
      type: CleaningReportListActions.SAVE_REPORT_SUBSCRIPTION_ERROR,
      error,
    };
  }
}
