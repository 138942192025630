import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import { NotificationList } from './interfaces/Notification.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  InputPagingOptions,
  InputSortOptions,
  InputFilterNotificationsList,
  InputFilterMachinesList,
  ResponseNotificationUser,
  ResponseNotificationUpdateNotifyStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface NotificationClientListOptions {
  paginationOptions: InputPagingOptions;
  filter?: InputFilterNotificationsList;
  sortOptions?: InputSortOptions;
  lang?: string;
}

export interface NotificationClientMachineListFilterOptions {
  filter: InputFilterMachinesList;
}

export interface NotificationClientUserLocationStatusOptions {
  lang: string;
  notifyAutonomousTaskStopped: boolean;
  notifyCleaningTaskFinished: boolean;
  notifyCleaningTaskInterrupted: boolean;
  notifyEmergencyStop: boolean;
  notifyErrorOccurred: boolean;
  notifyInformation: boolean;
  notifyLocationStatus: boolean;
  notifyNoWorkStart: boolean;
  notifyOther: boolean;
  notifyPCM: boolean;
  notifyReminder: boolean;
  notifyResources: boolean;
  notifyServiceNeeded: boolean;
  timezone: string;
}

@injectable()
export class NotificationClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({
    paginationOptions,
    filter,
    sortOptions,
    lang,
  }: NotificationClientListOptions): Promise<ApolloQueryResult<NotificationList>> =>
    this.client.query({
      query: gql`
        query Notifications(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterNotificationsList
          $sortOptions: InputSortOptions
          $lang: String
        ) {
          notifications(
            paginationOptions: $paginationOptions
            filter: $filter
            sortOptions: $sortOptions
            lang: $lang
          ) {
            data {
              id
              timestamp
              description
              type
              severity
              data
              machine {
                id
                name
                materialNumber
                serialNumber
                site {
                  id
                  name
                }
                type {
                  name
                }
              }
              message {
                cause
                description
                consequence
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter: {
          ...(filter?.machineIds && { machineIds: filter?.machineIds }),
          ...(filter?.type && { type: filter?.type }),
          ...(filter?.severities && { severities: filter?.severities }),
          ...(filter?.categories && { categories: filter?.categories }),
          ...(filter?.period && { period: filter?.period }),
        },
        sortOptions,
        paginationOptions,
        lang,
      },
    });

  public listShort = async ({
    paginationOptions,
    filter,
    sortOptions,
    lang,
  }: NotificationClientListOptions): Promise<ApolloQueryResult<NotificationList>> =>
    this.client.query({
      query: gql`
        query Notifications(
          $paginationOptions: InputPagingOptions!
          $filter: InputFilterNotificationsList
          $sortOptions: InputSortOptions
          $lang: String
        ) {
          notifications(
            paginationOptions: $paginationOptions
            filter: $filter
            sortOptions: $sortOptions
            lang: $lang
          ) {
            data {
              id
              timestamp
              description
              type
              severity
              data
              machine {
                id
                name
              }
              message {
                cause
                description
                consequence
              }
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        filter: {
          ...(filter?.machineIds && { machineIds: filter?.machineIds }),
          ...(filter?.type && { type: filter?.type }),
          ...(filter?.severities && { severities: filter?.severities }),
        },
        sortOptions,
        paginationOptions,
        lang,
      },
    });

  public getUserNotification = async (): Promise<ApolloQueryResult<ResponseNotificationUser>> =>
    this.client.query({
      query: gql`
        query NotificationGetUser {
          notificationGetUser {
            data {
              notifyAutonomousTaskStopped
              notifyCleaningTaskFinished
              notifyCleaningTaskInterrupted
              notifyEmergencyStop
              notifyErrorOccurred
              notifyInformation
              notifyLocationStatus
              notifyNoWorkStart
              notifyOther
              notifyPCM
              notifyReminder
              notifyResources
              notifyServiceNeeded
              timezone
            }
          }
        }
      `,
    });

  public updateNotifyStatus = async ({
    lang,
    notifyAutonomousTaskStopped,
    notifyCleaningTaskFinished,
    notifyCleaningTaskInterrupted,
    notifyEmergencyStop,
    notifyErrorOccurred,
    notifyInformation,
    notifyLocationStatus,
    notifyNoWorkStart,
    notifyPCM,
    notifyOther,
    notifyReminder,
    notifyResources,
    notifyServiceNeeded,
    timezone,
  }: NotificationClientUserLocationStatusOptions): Promise<FetchResult<ResponseNotificationUpdateNotifyStatus>> =>
    this.client.mutate({
      mutation: gql`
        mutation NotificationUpdateNotifyStatus($input: InputNotificationUpdateNotifyStatus!) {
          notificationUpdateNotifyStatus(input: $input) {
            data {
              notifyAutonomousTaskStopped
              notifyCleaningTaskFinished
              notifyCleaningTaskInterrupted
              notifyEmergencyStop
              notifyErrorOccurred
              notifyInformation
              notifyLocationStatus
              notifyNoWorkStart
              notifyOther
              notifyPCM
              notifyReminder
              notifyResources
              notifyServiceNeeded
              timezone
            }
          }
        }
      `,
      variables: {
        input: {
          lang,
          notifyAutonomousTaskStopped,
          notifyCleaningTaskFinished,
          notifyCleaningTaskInterrupted,
          notifyEmergencyStop,
          notifyErrorOccurred,
          notifyInformation,
          notifyLocationStatus,
          notifyNoWorkStart,
          notifyOther,
          notifyPCM,
          notifyReminder,
          notifyResources,
          notifyServiceNeeded,
          timezone,
        },
      },
    });
}
