import { singleton } from 'tsyringe';
import {
  Role,
  UserInviteResponse,
  UserListAvailableFilters,
  UserListResponse,
  UserUpdateResponse,
} from './interfaces/User.types';
import { UserClient, UserClientInviteOptions, UserClientListOptions, UserClientUpdateOptions } from './UserClient';
import { UNASSIGNABLE_ROLES } from './userConstants';
import { Optional } from 'lib/types/Optional';

export type UserServiceListOptions = UserClientListOptions;
export type UserServiceUpdateOptions = UserClientUpdateOptions;
export type UserServiceInviteOptions = UserClientInviteOptions;

@singleton()
export class UserService {
  constructor(private userClient: UserClient) {}

  public list = async ({ filter, paginationOptions }: UserServiceListOptions): Promise<UserListResponse> => {
    const { data } = await this.userClient.list({ filter, paginationOptions });
    return data;
  };

  public availableFilters = async (): Promise<UserListAvailableFilters> => {
    const allRoles: Role[] = Object.values(Role);
    const assignableRoles: Role[] = allRoles.filter(role => !UNASSIGNABLE_ROLES.includes(role));

    const rolesPromise = Promise.resolve(assignableRoles);

    return Promise.all([rolesPromise])
      .then(([roles]) => ({
        roles,
      }))
      .catch(error => {
        throw error;
      });
  };

  public update = async ({ id, input }: UserServiceUpdateOptions): Promise<Optional<UserUpdateResponse>> => {
    const { data } = await this.userClient.update({ id, input });
    return data;
  };

  public invite = async ({ input }: UserServiceInviteOptions): Promise<Optional<UserInviteResponse>> => {
    const { data } = await this.userClient.invite({ input });
    return data;
  };
}
