import { Auth } from 'aws-amplify';
import { isNil, negate, uniq } from 'lodash-es';
import { inject, singleton } from 'tsyringe';
import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  NotAuthorizedException,
} from '@aws-sdk/client-cognito-identity-provider';
import { RoutePaths } from '../../../config/route-paths';
import { Optional } from '../../../lib/types/Optional';
import { UserPoolUtils } from '../../utils/user-pool/UserPoolUtils';
import { CognitoError } from './errors/CognitoError';
import { IPermission, IUserInfo } from './interfaces/Authentication.types';
import {
  IAccessTokenPayload,
  IAuthenticationTokens,
  IIdTokenPayload,
  OidcTokenType,
} from './interfaces/IAuthenticationTokens';
import { PermissionsByRole } from 'config/permissions';

const CognitoIpClient = new CognitoIdentityProviderClient({ region: process.env.REACT_APP_AWS_REGION });

@singleton()
export class AuthenticationService {
  constructor(
    @inject('UserPoolProviderName') private providerName: string,
    // Typescript is reporting the error "Attempted import error: 'BrowserHistory' is not exported from 'redux'"
    // Set to any type as a workaround
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @inject('History') private history: any,
    private userPoolUtils: UserPoolUtils
  ) {}

  public signIn = async (): Promise<void> => {
    try {
      await Auth.federatedSignIn({ customProvider: this.providerName });
    } catch (error) {
      console.error(error);
    }
  };

  public triggerTokenRefresh = async (): Promise<void> => {
    try {
      await Auth.currentSession();
    } catch (error) {
      if (error === CognitoError.REFRESH_TOKEN_EXPIRED) {
        await this.signOut();
        this.history.push(RoutePaths.LOGIN);
        return;
      }
      // No refresh needed when not logged in
      if (error !== CognitoError.NO_CURRENT_USER) {
        throw error;
      }
    }
  };

  public checkIsAccessTokenRevoked = async (): Promise<void> => {
    try {
      const currentSession = await Auth.currentSession();

      const command = new GetUserCommand({
        AccessToken: currentSession.getAccessToken().getJwtToken(),
      });
      await CognitoIpClient.send(command);
    } catch (error) {
      if (error instanceof NotAuthorizedException && error.message === CognitoError.ACCESS_TOKEN_REVOKED) {
        await this.signOut();
        this.history.push(RoutePaths.LOGIN);
        return;
      }
      throw error;
    }
  };

  public signOut = async (): Promise<any> => {
    await Auth.signOut();
  };

  public refreshSession = async (): Promise<void> => {
    const currentSession = await Auth.currentSession();
    const refreshToken = currentSession.getRefreshToken();
    const cognitoUser = await Auth.currentAuthenticatedUser();

    cognitoUser.refreshSession(refreshToken, (error: any) => {
      if (error) {
        console.error(error);
      }
    });
  };

  public checkAuthentication = async (): Promise<Optional<IAuthenticationTokens>> => {
    const tokens = await this.getTokens();

    return tokens;
  };

  public getTokens = async (): Promise<Optional<IAuthenticationTokens>> => {
    let tokens: Optional<IAuthenticationTokens>;

    try {
      const currentSession = await Auth.currentSession();

      const idToken = currentSession.getIdToken();
      const accessToken = currentSession.getAccessToken();
      const refreshToken = currentSession.getRefreshToken();

      tokens = {
        jwt: {
          idToken: idToken.getJwtToken(),
          accessToken: accessToken.getJwtToken(),
          refreshToken: refreshToken.getToken(),
        },
        decoded: {
          idToken: idToken.decodePayload() as IIdTokenPayload,
          accessToken: accessToken.decodePayload() as IAccessTokenPayload,
        },
      };
    } catch (error) {
      if (error === CognitoError.NO_CURRENT_USER) {
        tokens = null;
      } else {
        console.error(error);
      }
    }

    if (tokens !== null) {
      try {
        this.userPoolUtils.verifyOidcToken(tokens?.jwt.idToken, OidcTokenType.ID);
        this.userPoolUtils.verifyOidcToken(tokens?.jwt.accessToken, OidcTokenType.ACCESS);
      } catch (error) {
        console.error(error);
        await this.signOut();
        this.history.push(RoutePaths.LOGIN);
      }
    }

    return tokens;
  };

  public getPermissions = async (): Promise<IPermission[]> => {
    const tokens = await this.getTokens();
    const roles = tokens?.decoded.accessToken?.['cognito:groups'] ?? [];
    // eslint-disable-next-line prefer-const
    let permissions = roles.flatMap(role => PermissionsByRole[role]).filter(negate(isNil));

    // Comment / uncomment permissions for testing

    // permissions = [
    //   Permission.Customer.Account.UPDATE,
    //   Permission.Customer.Role.UPDATE,
    //   Permission.Customer.User.AUTHORIZE,
    //   Permission.Customer.User.UNAUTHORIZE,
    //   Permission.Customer.User.SEND_INVITATION,
    //   Permission.Customer.User.DELETE,
    //   Permission.Internal.Role.UPDATE,
    //   Permission.Machine.CLAIM,
    //   Permission.Machine.UPDATE,
    //   Permission.Machine.ActivationStatus.UPDATE,
    //   Permission.Machine.Comment.CREATE,
    //   Permission.Site.CREATE,
    //   Permission.Site.READ,
    //   Permission.Site.UPDATE,
    //   Permission.Site.DELETE,
    //   Permission.Site.Machine.UPDATE,
    //   Permission.Site.StrategicManager.UPDATE,
    //   Permission.Site.WorkInterval.UPDATE,
    //   Permission.Permissions.READ,
    // ];

    return uniq(permissions);
  };

  public getUserInfo = async (): Promise<IUserInfo> => {
    try {
      const currentSession = await Auth.currentSession();
      const email = currentSession?.getIdToken()?.decodePayload()?.email;

      return {
        email,
      };
    } catch (error) {
      return {
        email: undefined,
      };
    }
  };

  public getUserGroups = async (): Promise<string[]> => {
    try {
      const currentSession = await Auth.currentSession();
      const groups: string[] = currentSession?.getIdToken()?.decodePayload()?.['cognito:groups'];

      return groups;
    } catch (error) {
      console.error('Error reading user groups');
      return [];
    }
  };
}
