import { ColumnsType } from 'antd/lib/table';
import { i18n as i18next } from 'i18next';
import { TFunction } from 'react-i18next';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { DATE_FORMAT_PATTERN } from 'lib/utils/date-handling/DateTime.types';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

interface GetInvitationListColumnsOptions {
  t: TFunction;
  i18n: i18next;
}

export const getSentInvitationListColumns = ({ t, i18n }: GetInvitationListColumnsOptions): ColumnsType<User> => [
  {
    title: t('userList.sentInvitations.table.name'),
    dataIndex: 'displayName',
    key: 'displayName',
    width: '20%',
  },
  {
    title: t('userList.sentInvitations.table.email'),
    dataIndex: 'email',
    key: 'email',
    width: '25%',
  },
  {
    title: t('userList.sentInvitations.table.sentInvitation'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '25%',
    render: (createdAt): JSX.Element => (
      <>{DateTime.formatDateByLocale(i18n.language, createdAt, DATE_FORMAT_PATTERN.DATE)}</>
    ),
    defaultSortOrder: 'descend',
  },
];
