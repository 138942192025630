import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useEffect } from 'react';
import { Spin } from 'antd';
import { MachineRobotsCleaningDataChartHeader } from '../MachineDetailsRobotsCleaningDataChartHeader/MachineDetailsRobotsCleaningDataChartHeader';
import MachineDetailsRobotsBarChart from '../MachineDetailsRobotsBarChart';
import * as machineDetailsPanelRobotSelectors from '../../../../../state/machineDetailsPanelRobot/machineDetailsPanelRobotSelectors';
import { StyledMachineDetailsRobotCleanedAreaChart } from './MachineDetailsRobotCleanedAreaChart.styles';
import { MachineDetailsPanelRobotActions } from 'app/modules/machine-inventory/machine-details-panel/state/machineDetailsPanelRobot/machineDetailsPanelRobotSlice';
import { CleaningTaskCleanedAreaByDay } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { getDatesByPeriod } from 'lib/utils/date-handling/getTimeByPeriod';
import { Numbers } from 'lib/utils/number-formatting/Numbers';
import { DateFormats } from 'app/modules/machine-inventory/interfaces/Robot.types';
import { Machine } from 'app/modules/machine-inventory/interfaces/Machine.types';

const DEFAULT_VALUE = {
  cleanedArea: 0,
  finishedAt: '',
};

interface MachineDetailsRobotCleanedAreaChartProps {
  robot: Machine;
}

export const MachineDetailsRobotCleanedAreaChart = ({
  robot,
}: MachineDetailsRobotCleanedAreaChartProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const startAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endAt = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);
  const routeName = useSelector(machineDetailsPanelRobotSelectors.selectActiveRoutesName);

  const periodCleanedArea = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataPeriodCleanedArea);
  const totalCleanedArea = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataTotalCleanedArea);
  const cleanedAreaByDay = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataCleanedAreaByDay);
  const cleanedAreaIsLoading = useSelector(machineDetailsPanelRobotSelectors.selectCleaningDataCleanedAreaIsLoading);

  useEffect(() => {
    if (!endAt || !startAt) return;
    dispatch(
      MachineDetailsPanelRobotActions.machineDetailsRobotListCleaningDataCleanedAreaRequest({
        filter: {
          machineId: robot.id || '',
          period: {
            endAt,
            startAt,
          },
          routeName,
        },
      })
    );
  }, [dispatch, endAt, robot.id, startAt, routeName]);

  const startDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodStartDate);
  const endDate = useSelector(machineDetailsPanelRobotSelectors.selectPeriodEndDate);

  const startTime = startAt ? dayjs(startDate) : null;
  const endTime = endAt ? dayjs(endDate) : null;

  const dates = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_DAY_MONTH));

  const periodData = useMemo(() => {
    const period = getDatesByPeriod(startTime, endTime).map(d => d.format(DateFormats.DATE_IN_YEAR_MONTH_DAY));
    const result: CleaningTaskCleanedAreaByDay[] = [];
    period.forEach(date => {
      const data = cleanedAreaByDay.find(
        item => dayjs(item.finishedAt).format(DateFormats.DATE_IN_YEAR_MONTH_DAY) === date
      );
      if (!data) {
        result.push({ ...DEFAULT_VALUE, finishedAt: date });
      } else {
        result.push(data);
      }
    });
    return result;
  }, [cleanedAreaByDay, endTime, startTime]);

  const cleanedArea = periodData.map(item => item.cleanedArea);
  const finishDates = periodData.map(item => item.finishedAt);

  return (
    <StyledMachineDetailsRobotCleanedAreaChart>
      <Spin className="robot-cleaned-area-chart__spin" spinning={cleanedAreaIsLoading} size="large">
        <MachineRobotsCleaningDataChartHeader
          labelNoteSquare={t('machineDetails.robotCleaningData.cleanedArea.note')}
          periodLabel={t('machineDetails.robotCleaningData.cleanedArea.periodCleanedArea')}
          totalLabel={t('machineDetails.robotCleaningData.cleanedArea.totalCleanedArea')}
          periodValue={Numbers.formatNumberDecimalRounded(periodCleanedArea, 1)}
          totalValue={Numbers.formatNumberDecimalRounded(totalCleanedArea, 1)}
          unit={t('common.areaUnit')}
        />
        <div className="robot-cleaned-area-chart__wrapper">
          <div className="robot-cleaned-area-chart__unit">
            <p>
              {t('machineDetails.robotCleaningData.cleanedArea.sqm')} {t('common.areaUnit')}
            </p>
          </div>
          <MachineDetailsRobotsBarChart
            period={dates}
            cleaningValues={cleanedArea}
            unit={t('common.areaUnit')}
            finishDates={finishDates}
          />
        </div>
      </Spin>
    </StyledMachineDetailsRobotCleanedAreaChart>
  );
};
