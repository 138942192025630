import { i18n as i18next } from 'i18next';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { ReactNode } from 'react';
import { Flex } from 'antd';
import { DATE_FORMAT_PATTERN } from '../../../../../../../../lib/utils/date-handling/DateTime.types';
import {
  CleaningTaskReport,
  TaskCompletion,
} from '../../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Optional } from 'lib/types/Optional';
import { BarProgress } from 'lib/components/Progress/BarProgress/BarProgress';
import { StatusPill } from 'lib/components/StatusPill/StatusPill';
import { LoadingIndicator } from 'lib/components/LoadingIndicator/LoadingIndicator';

export const getCleaningReportListRobotColumns = ({
  t,
  i18n,
  handleClickMachineName,
  handleClickSiteName,
  handleClickDownload,
  currentExportingId,
  handleOpenRouteImage,
}: {
  t: TFunction;
  i18n: i18next;
  handleClickMachineName: (ctr: CleaningTaskReport) => void;
  handleClickSiteName: (ctr: CleaningTaskReport) => void;
  handleClickDownload: (ctr: CleaningTaskReport) => void;
  currentExportingId: Optional<string>;
  handleOpenRouteImage: (ctr: CleaningTaskReport) => void;
}): ColumnsType<CleaningTaskReport> => {
  const columns: ColumnsType<CleaningTaskReport> = [
    {
      title: t('cleaningReportList.robot.table.columns.date'),
      dataIndex: 'startedAt',
      width: 185,
      key: 'startedAt',
      render: (startedAt): JSX.Element => (
        <>
          {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.DATE)}
          {', '}
          {DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.WEEKDAY)}
        </>
      ),
      fixed: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.machineName'),
      dataIndex: ['machine', 'name'],
      width: 180,
      key: 'machineName',
      render: (machineName: string, cleaningTaskReport): JSX.Element => (
        <div className="table__link--no-styling" onClick={(): void => handleClickMachineName(cleaningTaskReport)}>
          {machineName}
        </div>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.cleanedSquareMeter'),
      dataIndex: 'cleanedSquareMeter',
      width: 129,
      key: 'cleanedSquareMeter',
      render: (cleanedSquareMeter): string =>
        t('cleaningReportList.robot.table.values.cleanedSquareMeterValue', {
          cleanedSquareMeter: cleanedSquareMeter || 0,
        }),
      align: 'right',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.assignedSite'),
      dataIndex: ['machine', 'site', 'name'],
      width: 180,
      key: 'site',
      render: (_siteName: string, cleaningTaskReport): JSX.Element => {
        if (!cleaningTaskReport.machine?.site?.id) {
          return <>{t('cleaningReportList.unassignedLabel')}</>;
        }

        return (
          <div className="table__link--no-styling" onClick={(): void => handleClickSiteName(cleaningTaskReport)}>
            {cleaningTaskReport.machine.site?.name}
          </div>
        );
      },
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.routeName'),
      dataIndex: 'routeName',
      width: 180,
      key: 'routeName',
      render: routeName => `${routeName || t('common.NA')}`,
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.pathCoverage'),
      dataIndex: 'pathCoverage',
      width: 120,
      key: 'pathCoverage',
      render: (pathCoverage): JSX.Element => (
        <BarProgress
          percent={Math.round((pathCoverage || 0) * 100)}
          format={(percent?: number | undefined): ReactNode => {
            if (percent === 100) return `${percent}%`;
            return `${percent}%`;
          }}
        />
      ),
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.taskCompletion'),
      className: 'cleaning-report-list-robot__task-column',
      dataIndex: 'taskCompletion',
      width: 122,
      key: 'taskCompletion',
      render: (taskCompletion): JSX.Element => {
        let color = 'gray';
        switch (taskCompletion) {
          case TaskCompletion.Completed:
            color = 'green';
            break;
          case TaskCompletion.Interrupted:
            color = 'yellow';
            break;
          case TaskCompletion.Stopped:
            color = 'red';
            break;
          default:
            color = 'gray';
            break;
        }
        return (
          <StatusPill className="cleaning-report-list__status-pill" color={color}>
            {`${
              taskCompletion
                ? t(`machineDetails.taskCompletionHistory.labels.${taskCompletion.toLowerCase()}`)
                : t('common.NA')
            }`}
          </StatusPill>
        );
      },
      sorter: true,
    },
    {
      title: t('cleaningReportList.robot.table.columns.materialNo'),
      dataIndex: 'materialNumber',
      width: 123,
      key: 'materialNumber',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: t('cleaningReportList.robot.table.columns.serialNo'),
      dataIndex: 'serialNumber',
      width: 105,
      key: 'serialNumber',
      sorter: true,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.startTime')}</Flex>,
      dataIndex: 'startedAt',
      width: 101,
      key: 'startedAt',
      render: (startedAt): JSX.Element => (
        <>{DateTime.formatDateByLocale(i18n.language, startedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND)}</>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.stopTime')}</Flex>,
      dataIndex: 'finishedAt',
      width: 101,
      key: 'finishedAt',
      render: (finishedAt): JSX.Element => (
        <>{DateTime.formatDateByLocale(i18n.language, finishedAt, DATE_FORMAT_PATTERN.TIME_24_HOUR_WITH_SECOND)}</>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: (): ReactNode => <Flex>{t('cleaningReportList.robot.table.columns.duration')}</Flex>,
      dataIndex: 'actualOperatingTimeInSeconds',
      width: 101,
      key: 'actualOperatingTimeInSeconds',
      render: actualOperatingTimeInSeconds =>
        DateTime.formatDurationByMilliseconds({
          ms: actualOperatingTimeInSeconds * 1000,
        }),
      ellipsis: {
        showTitle: true,
      },
      align: 'right',
    },
    {
      title: t('cleaningReportList.robot.table.columns.imageRoute'),
      dataIndex: 'imageRoute',
      width: 120,
      key: 'imageRoute',
      className: 'cleaning-task-report-robot__image-icon-column',
      render: (_, cleaningTaskReport): JSX.Element => (
        <div
          className="cleaning-task-report-robot__image-route-icon"
          onClick={(): void => handleOpenRouteImage(cleaningTaskReport)}
        >
          <SvgIcon name="image" className="image-icon" />
        </div>
      ),
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
    {
      title: t('common.export'),
      width: 87,
      key: 'export',
      className: 'cleaning-report-list__export-icon-column',
      render: (_id: string, cleaningTaskReport): JSX.Element => {
        if (!cleaningTaskReport.id) {
          return <></>;
        }
        return (
          <div className="table__link--no-styling" onClick={(): void => handleClickDownload(cleaningTaskReport)}>
            {currentExportingId === cleaningTaskReport.id ? (
              <LoadingIndicator size="small" />
            ) : (
              <SvgIcon name="exportReport" className="cleaning-report-list__table_export-icon" />
            )}
          </div>
        );
      },
      ellipsis: {
        showTitle: true,
      },
      align: 'center',
    },
  ];

  return columns;
};
