import styled from 'styled-components';

export const StyledMachineDetailsRobotCtrList = styled.div`
  .message-list {
    .ant-card-body {
      padding: 0;
    }

    .message-list__header {
      margin-bottom: 0;
    }

    .message-list__body {
      height: 420px;
    }
  }

  .robot-details-ctr-list__empty-icon {
    svg {
      width: 40px;
      height: 40px;
    }
  }

  .robot-details-ctr-list__empty-text {
    ${(props): string => props.theme.fontStyles.caption.medium};
    color: ${(props): string => props.theme.colors.black};
  }

  &.robot-details-ctr-list {
    .message-list__message {
      padding: 16px;
      cursor: pointer;
    }

    .message__description {
      .robot-ctr-list__routine-row {
        margin-bottom: 8px;

        .robot-ctr-list__routine-data {
          ${(props): string => props.theme.fontStyles.redesign.sm.normal};
          margin: 0;
        }
      }

      .robot-ctr-list__title-row {
        margin-bottom: 8px;
      }

      .robot-ctr-list__data {
        margin-bottom: 0px;

        &.ctr-path-coverage {
          float: right;
        }

        &.ctr-task-completion {
          text-transform: capitalize;
        }

        &.ctr-path-coverage,
        &.ctr-task-completion {
          ${(props): string => props.theme.fontStyles.redesign.sm.normal};
          color: ${(props): string => props.theme.colors.semanticSuccess};
        }

        &.ctr-cleaning-route,
        &.ctr-cleaned-area {
          ${(props): string => props.theme.fontStyles.redesign.base.strong};
          color: ${(props): string => props.theme.colors.black};
        }

        &.ctr-date,
        &.ctr-time {
          ${(props): string => props.theme.fontStyles.p1.news};
          color: ${(props): string => props.theme.colors.black};
        }
      }

      .robot-ctr-list__description-row {
        .robot-ctr-list__export-btn,
        .robot-ctr-list__redirect-event-page-btn {
          top: 5px;

          :hover {
            background: none;
          }

          .download-icon,
          .event-report-icon {
            svg {
              margin: auto;
              width: 24px;
              height: 24px;

              path {
                fill: ${(props): string => props.theme.colors.black};
              }
            }
          }
        }
      }
    }
  }
`;
