import styled from 'styled-components';

export const StyledUserListSystem = styled.div`
  .user-list-system__table {
    .ant-table-row {
      .user-list-system__delete-icon {
        display: none;
      }
    }

    .ant-table-cell-row-hover {
      .user-list-system__delete-icon {
        display: flex;
      }
    }

    .ant-table-cell {
      padding: 7px 12px !important;
    }
  }

  .user-list-system__role-column {
    display: flex;
  }

  .user-list-system__role-dropdown {
    width: 100%;
  }

  .user-list-system__delete-button-column {
    min-width: 28px;
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-list-system__delete-icon {
    font-size: 20px;
  }

  .table-head--with-icon {
    ${(props): string => props.theme.fontStyles.redesign.base.strong};
  }

  .user-list-system__display-name,
  .user-list-system__email {
    ${(props): string => props.theme.fontStyles.redesign.base.normal};
  }

  .user-list-system__role-dropdown {
    height: unset;
    .ant-select-selector {
      height: 24px !important;
      padding: 0 8px !important;
    }
    .ant-select-arrow {
      margin-top: -5px !important;
    }
  }
`;
