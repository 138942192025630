import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';
import { IRoute } from '../../interfaces/Routing.types';
import { selectHasAccessToRobots } from '../../../authentication/state/authenticationSelectors';

export interface RoutingGuardProps {
  route: IRoute;
  children?: React.ReactNode;
}

export const RoutingGuard: React.FC<RoutingGuardProps> = ({ route, children }) => {
  const hasAccessToRobots = useSelector(selectHasAccessToRobots);
  const isRobotDashboardRoute = route.path === RoutePaths.ROBOT_DASHBOARD;

  if (isRobotDashboardRoute && !hasAccessToRobots) {
    return <Navigate to={RoutePaths.ROOT} />;
  }

  return <>{children}</>;
};
