import { SelectValue } from 'antd/es/select';
import { ColumnsType } from 'antd/lib/table';
import { i18n as i18next } from 'i18next';
import { TFunction } from 'react-i18next';
import { Icon } from '../../../../../../../../lib/components/Icon/Icon';
import { Role, User } from '../../../../../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { getRoleTranslationString } from '../../../../../utils/getRoleTranslationString';
import { Permission, Roles } from 'config/permissions';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { UNASSIGNABLE_ROLES } from 'app/modules/user-management/userConstants';
import { Select } from 'lib/components/Select/Select';

interface GetUserListSystemColumnsOptions {
  t: TFunction;
  i18n: i18next;
  handleUpdate: (user: User, role: Role) => void;
  handleDelete: (user: User) => void;
  isCustomerUnauthorizeEnabled: boolean;
  hasPermissionCustomerUserUnauthorize: boolean;
}

export const getUserListSystemColumns = ({
  t,
  i18n: _i18n,
  handleUpdate,
  handleDelete,
  isCustomerUnauthorizeEnabled,
  hasPermissionCustomerUserUnauthorize,
}: GetUserListSystemColumnsOptions): ColumnsType<User> => [
  {
    title: t('userList.table.name'),
    dataIndex: 'displayName',
    key: 'displayName',
    width: '20%',
    defaultSortOrder: 'ascend',
    render: (displayName): JSX.Element => <div className="user-list-system__display-name">{displayName}</div>,
  },
  {
    title: t('userList.table.email'),
    dataIndex: 'email',
    key: 'email',
    width: '25%',
    render: (email): JSX.Element => <div className="user-list-system__email">{email}</div>,
  },
  {
    title: t('userList.table.role'),
    dataIndex: 'role',
    key: 'role',
    width: '25%',
    render: (role, user: User): JSX.Element => (
      <div className="user-list-system__role-column">
        <PermissionGuard
          requiredPermissions={[Permission.Customer.Role.UPDATE]}
          fallback={t(getRoleTranslationString(role))}
        >
          <Select
            className="user-list-system__role-dropdown"
            maxTagCount="responsive"
            // TODO: set loading state
            loading={false}
            dropdownVisibleState={false}
            selectedOptionsOnTop
            value={role}
            onChange={(value: SelectValue): void => handleUpdate(user, value as Role)}
            options={Roles.filter(r => !UNASSIGNABLE_ROLES.includes(r)).map(availableRole => ({
              label: t(getRoleTranslationString(availableRole)),
              value: availableRole,
            }))}
          />
        </PermissionGuard>
      </div>
    ),
  },
  ...(isCustomerUnauthorizeEnabled && hasPermissionCustomerUserUnauthorize
    ? [
        {
          key: 'id',
          width: '4%',
          render: (_id: string, user: User): JSX.Element => (
            <div className="user-list-system__delete-button-column">
              <div
                className="user-list-system__delete-icon"
                onClick={(e): void => {
                  e.stopPropagation();
                  handleDelete(user);
                }}
              >
                <Icon name="icon-a_050_delete_line" />
              </div>
            </div>
          ),
        },
      ]
    : []),
];
