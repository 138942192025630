import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { IGetUserListOperatorRequestAction, UserListActions } from '../../user-list/state/userListActions';
import { NotificationDeleteOperatorResponse } from '../../interfaces/Operator.types';
import {
  UserModalsActions,
  IDeleteOperatorErrorAction,
  IDeleteOperatorModalIsLoadingAction,
  IDeleteOperatorModalIsNotLoadingAction,
  IDeleteOperatorRequestAction,
  IDeleteOperatorSuccessAction,
  IHideDeleteOperatorModalAction,
} from './userModalsActions';
import { Optional } from 'lib/types/Optional';
import { DrawersActions } from 'app/cross-cutting-concerns/drawers/state/drawersSlice';
import { InfiniteScrollConstants } from 'config/constants';

export function* deleteOperatorSaga(
  action: IDeleteOperatorRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<NotificationDeleteOperatorResponse> | void>
  | PutEffect<IDeleteOperatorModalIsLoadingAction>
  | PutEffect<IGetUserListOperatorRequestAction>
  | PutEffect<IDeleteOperatorSuccessAction>
  | PutEffect<IDeleteOperatorErrorAction>
  | PutEffect<IDeleteOperatorModalIsNotLoadingAction>
  | PutEffect<IHideDeleteOperatorModalAction>,
  void,
  IDependencies
> {
  const { operatorService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  yield* put(UserModalsActions.deleteOperatorModalIsLoading());

  try {
    const response = yield* call(operatorService.delete, action.payload);

    if (response?.notificationDeleteOperator?.data) {
      yield* call(toastService.success, {
        message: t('userList.operator.deleteModal.toasts.success.message'),
        description: t('userList.operator.deleteModal.toasts.success.description'),
      });

      yield* put(
        UserListActions.getUserListOperatorRequest({
          paginationOptions: {
            limit: InfiniteScrollConstants.MAX_ITEMS,
            paginationToken: '',
          },
        })
      );
    }

    yield* put(UserModalsActions.deleteOperatorSuccess(response));
    yield* put(DrawersActions.hideOperatorDetailsDrawer());
  } catch (error) {
    console.error(error);
    yield* call(toastService.error, {
      message: t('userList.operator.deleteModal.toasts.error.message'),
      description: (error as Error).message,
    });

    yield* put(
      UserModalsActions.deleteOperatorError({
        error,
      })
    );
  } finally {
    yield* put(UserModalsActions.hideDeleteOperatorModal());
    yield* put(UserModalsActions.deleteOperatorModalIsNotLoading());
  }
}

export function* userModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserModalsActions.DELETE_OPERATOR_REQUEST, deleteOperatorSaga);
}
