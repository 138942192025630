import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export const selectDeleteOperatorModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modals.operator.operatorDelete.visible;

export const selectDeleteOperatorModalIsLoading = (state: IState): boolean =>
  state.modules['user-management'].modals.operator.operatorDelete.isLoading;

export const selectDeleteOperatorId = (state: IState): string =>
  state.modules['user-management'].modals.operator.operatorDelete.operatorId;
