import {
  CleaningReportSubscription,
  CleaningReportSubscriptionReportFormat,
  CleaningReportSubscriptionType,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface CleaningReportSubscriptionForm {
  totalSwitchReport: boolean;
  totalInterval: string;
  totalPDF: boolean;
  totalXLS: boolean;
  eventSwitchReport: boolean;
  eventInterval: string;
  eventPDF: boolean;
  eventXLS: boolean;
  robotSwitchReport: boolean;
  robotInterval: string;
  robotPDF: boolean;
  robotXLS: boolean;
}

/**
 * This function converts the data returned from the API to the format required by the form.
 *
 * @param data - data returned from the API
 * @returns converted data for the form
 */
export const convertResData = (data: CleaningReportSubscription[]): CleaningReportSubscriptionForm => {
  const converted: CleaningReportSubscriptionForm = {
    totalSwitchReport: false,
    totalInterval: '',
    totalPDF: false,
    totalXLS: false,
    eventSwitchReport: false,
    eventInterval: '',
    eventPDF: false,
    eventXLS: false,
    robotSwitchReport: false,
    robotInterval: '',
    robotPDF: false,
    robotXLS: false,
  };

  data.forEach(({ interval, type, formats }) => {
    const isTotal = type === CleaningReportSubscriptionType.Total;
    const isEvent = type === CleaningReportSubscriptionType.Events;
    const isRobot = type === CleaningReportSubscriptionType.Robot;
    const isPDF = !!formats.find((format: string) => format === CleaningReportSubscriptionReportFormat.Pdf);
    const isXLS = !!formats.find((format: string) => format === CleaningReportSubscriptionReportFormat.Excel);
    if (isTotal) {
      converted.totalSwitchReport = isTotal;
      converted.totalInterval = interval;
      converted.totalPDF = isPDF;
      converted.totalXLS = isXLS;
    } else if (isEvent) {
      converted.eventSwitchReport = isEvent;
      converted.eventInterval = interval;
      converted.eventPDF = isPDF;
      converted.eventXLS = isXLS;
    } else if (isRobot) {
      converted.robotSwitchReport = isRobot;
      converted.robotInterval = interval;
      converted.robotPDF = isPDF;
      converted.robotXLS = isXLS;
    }
  });
  return converted;
};

// This function converts data from the form to a format that can be sent to the backend
// to save the user's subscription. The function takes the data from the form (input)
// and returns an array of CleaningReportSubscription objects.
// The input object represents the data from the form.
// The CleaningReportSubscription object represents the data that needs to be sent
// to the backend to save the user's subscription.

export const convertDataToSave = (input: CleaningReportSubscriptionForm): CleaningReportSubscription[] => {
  const allSubscriptions = [];

  if (input.totalSwitchReport) {
    allSubscriptions.push({
      interval: input.totalInterval,
      type: CleaningReportSubscriptionType.Total,
      formats: [
        input.totalPDF && CleaningReportSubscriptionReportFormat.Pdf,
        input.totalXLS && CleaningReportSubscriptionReportFormat.Excel,
      ].filter(Boolean),
    });
  }

  if (input.eventSwitchReport) {
    allSubscriptions.push({
      interval: input.eventInterval,
      type: CleaningReportSubscriptionType.Events,
      formats: [
        input.eventPDF && CleaningReportSubscriptionReportFormat.Pdf,
        input.eventXLS && CleaningReportSubscriptionReportFormat.Excel,
      ].filter(Boolean),
    });
  }

  if (input.robotSwitchReport) {
    allSubscriptions.push({
      interval: input.robotInterval,
      type: CleaningReportSubscriptionType.Robot,
      formats: [
        input.robotPDF && CleaningReportSubscriptionReportFormat.Pdf,
        input.robotXLS && CleaningReportSubscriptionReportFormat.Excel,
      ].filter(Boolean),
    });
  }

  return allSubscriptions as CleaningReportSubscription[];
};
