import { Action } from 'redux';
import { UploadFile } from 'antd';
import { Optional } from '../../../../../lib/types/Optional';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { RemindersListResponse } from '../../../reminder/interfaces/Reminder.types';
import { ReminderServiceListOptions } from '../../../reminder/ReminderService';
import {
  GetMachineOperatingTimeForPeriod,
  MachineDetails,
  MachineDetailsLatestCtrDatum,
  MachineDetailsLatestRoutineDatum,
  MachineDetailsTelemetriesDatum,
  MachineDetailsVariantDatum,
  MachineUpdateResponse,
} from '../../interfaces/Machine.types';
import {
  MachineClientDetailsOptions,
  MachineClientOperatingTimeOptions,
  MachineClientAttachmentsListOptions,
  MachineClientUpdateOptions,
} from '../../MachineClient';
import { MachineAttachmentClientDeleteOptions } from '../../MachineAttachmentClient';
import {
  MachineAttachmentDeleteResponse,
  MachineAttachmentDownloadResponse,
  MachineAttachmentsListResponse,
} from '../../interfaces/MachineAttachment.types';
import {
  MachineUpdateConnectionStatus,
  MachineUpdateRobotStatus,
  MachineUpdateTelemetry,
} from '../../interfaces/MachineSubscription.types';
import { MachineUpdate, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { NotificationList } from 'app/modules/notification/interfaces/Notification.types';
import { NotificationClientListOptions } from 'app/modules/notification/NotificationClient';
import { NotesListResponse } from 'app/modules/note/interfaces/Note.types';
import { NoteServiceListOptions } from 'app/modules/note/NoteService';

export type IGetMachineDetailsRequestOptions = MachineClientDetailsOptions;
export type IGetMachineDetailsRequestAction = Action<typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_REQUEST> & {
  payload: MachineClientDetailsOptions;
};

export type IGetMachineDetailsSuccessOptions = MachineDetails;
export type IGetMachineDetailsSuccessAction = Action<typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_SUCCESS> & {
  payload: MachineDetails;
};

export type IGetMachineDetailsPictureSuccessOptions = MachineDetailsVariantDatum;
export type IGetMachineDetailsPictureSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_PICTURE_SUCCESS
> & {
  payload: MachineDetailsVariantDatum;
};

export type IGetMachineDetailsTelemetriesSuccessOptions = MachineDetailsTelemetriesDatum;
export type IGetMachineDetailsTelemetriesSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS
> & {
  payload: MachineDetailsTelemetriesDatum;
};

export type IGetMachineDetailsLatestRoutineSuccessOptions = MachineDetailsLatestRoutineDatum;
export type IGetMachineDetailsLatestRoutineSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_LATEST_ROUTINE_SUCCESS
> & {
  payload: MachineDetailsLatestRoutineDatum;
};

export type IGetMachineDetailsLatestCtrSuccessOptions = MachineDetailsLatestCtrDatum;
export type IGetMachineDetailsLatestCtrSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS
> & {
  payload: MachineDetailsLatestCtrDatum;
};

export type IGetMachineDetailsErrorAction = IErrorAction<typeof MachineDetailsPanelActions.GET_MACHINE_DETAILS_ERROR>;

export type IGetMachineOperatingTimeRequestOptions = MachineClientOperatingTimeOptions;
export type IGetMachineOperatingTimeRequestAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_OPERATING_TIME_REQUEST
> & {
  payload: MachineClientOperatingTimeOptions;
};

export type IGetMachineOperatingTimeSuccessOptions = GetMachineOperatingTimeForPeriod;
export type IGetMachineOperatingTimeSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_OPERATING_TIME_SUCCESS
> & {
  payload: GetMachineOperatingTimeForPeriod;
};

export type IGetMachineOperatingTimeErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.GET_MACHINE_OPERATING_TIME_ERROR
>;

export interface IGetMachineRemindersRequestOptions extends ReminderServiceListOptions {
  append: boolean;
}
export type IGetMachineRemindersRequestAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_REMINDERS_REQUEST
> & {
  payload: IGetMachineRemindersRequestOptions;
};

export interface IGetMachineRemindersSuccessOptions extends RemindersListResponse {
  append: boolean;
}
export type IGetMachineRemindersSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_REMINDERS_SUCCESS
> & {
  payload: IGetMachineRemindersSuccessOptions;
};

export type IGetMachineRemindersErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.GET_MACHINE_REMINDERS_ERROR
>;

export interface IGetMachineNotesRequestOptions extends NoteServiceListOptions {
  append: boolean;
}
export type IGetMachineNotesRequestAction = Action<typeof MachineDetailsPanelActions.GET_MACHINE_NOTES_REQUEST> & {
  payload: IGetMachineNotesRequestOptions;
};

export interface IGetMachineNotesSuccessOptions extends NotesListResponse {
  append: boolean;
}
export type IGetMachineNotesSuccessAction = Action<typeof MachineDetailsPanelActions.GET_MACHINE_NOTES_SUCCESS> & {
  payload: IGetMachineNotesSuccessOptions;
};

export type IGetMachineNotesErrorAction = IErrorAction<typeof MachineDetailsPanelActions.GET_MACHINE_NOTES_ERROR>;

export type IUpdateMachineRequestOptions = MachineClientUpdateOptions;
export type IUpdateMachineRequestAction = Action<typeof MachineDetailsPanelActions.UPDATE_MACHINE_REQUEST> & {
  payload: MachineClientUpdateOptions;
};

export type IUpdateMachineSuccessOptions = Optional<MachineUpdateResponse>;
export type IUpdateMachineSuccessAction = Action<typeof MachineDetailsPanelActions.UPDATE_MACHINE_SUCCESS> & {
  payload: Optional<MachineUpdateResponse>;
};

export type IUpdateMachineErrorAction = IErrorAction<typeof MachineDetailsPanelActions.UPDATE_MACHINE_ERROR>;

export type IUpdateMachineInfoRequestOptions = MachineClientUpdateOptions;
export type IUpdateMachineInfoRequestAction = Action<typeof MachineDetailsPanelActions.UPDATE_MACHINE_INFO_REQUEST> & {
  payload: MachineClientUpdateOptions;
};
export type IUpdateMachineInfoSuccessOptions = Optional<MachineUpdateResponse>;
export type IUpdateMachineInfoSuccessAction = Action<typeof MachineDetailsPanelActions.UPDATE_MACHINE_INFO_SUCCESS> & {
  payload: Optional<MachineUpdateResponse>;
};

export type IUpdateMachineInfoErrorAction = IErrorAction<typeof MachineDetailsPanelActions.UPDATE_MACHINE_INFO_ERROR>;

export type IGetMachineNotificationListRequestOptions = NotificationClientListOptions;

export type IGetMachineNotificationListRequestAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_NOTIFICATION_LIST_REQUEST
> & {
  payload: NotificationClientListOptions;
};

export type IGetMachineNotificationListSuccessOptions = NotificationList;
export type IGetMachineNotificationListSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_NOTIFICATION_LIST_SUCCESS
> & {
  payload: NotificationList;
};

export type IGetMachineNotificationListErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.GET_MACHINE_NOTIFICATION_LIST_ERROR
>;

export type IChangeMachineNotificationListTablePage = Action<
  typeof MachineDetailsPanelActions.CHANGE_NOTIFICATIONS_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type ISetActiveNotificationsSortField = Action<
  typeof MachineDetailsPanelActions.SET_ACTIVE_NOTIFICATIONS_SORT_FIELD
> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveNotificationsSortOrder = Action<
  typeof MachineDetailsPanelActions.SET_ACTIVE_NOTIFICATIONS_SORT_ORDER
> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IGetMachineAttachmentsRequestOptions = MachineClientAttachmentsListOptions;
export type IGetMachineAttachmentsRequestAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_ATTACHMENTS_LIST_REQUEST
> & {
  payload: MachineClientAttachmentsListOptions;
};

export type IGetMachineAttachmentsSuccessOptions = MachineAttachmentsListResponse;
export type IGetMachineAttachmentsSuccessAction = Action<
  typeof MachineDetailsPanelActions.GET_MACHINE_ATTACHMENTS_LIST_SUCCESS
> & {
  payload: MachineAttachmentsListResponse;
};
export type IGetMachineAttachmentsErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.GET_MACHINE_ATTACHMENTS_LIST_ERROR
>;

export type IMachineAttachmentsIsLoadingAction = Action<
  typeof MachineDetailsPanelActions.MACHINE_ATTACHMENTS_IS_LOADING
>;

export type IChangeMachineAttachmentsListTablePage = Action<
  typeof MachineDetailsPanelActions.CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE
> & {
  payload: {
    page: number;
  };
};

export type ISetMachineAttachmentsActiveSortOrder = Action<
  typeof MachineDetailsPanelActions.SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER
> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type IDeleteMachineAttachmentRequestOptions = MachineAttachmentClientDeleteOptions;
export type IDeleteMachineAttachmentRequestAction = Action<
  typeof MachineDetailsPanelActions.DELETE_MACHINE_ATTACHMENT_REQUEST
> & {
  payload: MachineAttachmentClientDeleteOptions;
};

export type IDeleteMachineAttachmentSuccessOptions = Optional<MachineAttachmentDeleteResponse>;
export type IDeleteMachineAttachmentSuccessAction = Action<
  typeof MachineDetailsPanelActions.DELETE_MACHINE_ATTACHMENT_SUCCESS
> & {
  payload: Optional<MachineAttachmentDeleteResponse>;
};

export type IDeleteMachineAttachmentErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.DELETE_MACHINE_ATTACHMENT_ERROR
>;

export interface IAddMachineAttachmentRequestOptions {
  machineId: string;
  file: UploadFile;
}
export type IAddMachineAttachmentRequestAction = Action<
  typeof MachineDetailsPanelActions.ADD_MACHINE_ATTACHMENT_REQUEST
> & {
  payload: IAddMachineAttachmentRequestOptions;
};

export type IAddMachineAttachmentSuccessOptions = Optional<void>;
export type IAddMachineAttachmentSuccessAction = Action<
  typeof MachineDetailsPanelActions.ADD_MACHINE_ATTACHMENT_SUCCESS
> & {
  payload: Optional<void>;
};

export type IAddMachineAttachmentErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.ADD_MACHINE_ATTACHMENT_ERROR
>;

export interface IDownloadMachineAttachmentRequestOptions {
  machineId: string;
  attachmentId: string;
}
export type IDownloadMachineAttachmentRequestAction = Action<
  typeof MachineDetailsPanelActions.DOWNLOAD_MACHINE_ATTACHMENT_REQUEST
> & {
  payload: IDownloadMachineAttachmentRequestOptions;
};

export type IDownloadMachineAttachmentSuccessOptions = Optional<MachineAttachmentDownloadResponse>;
export type IDownloadMachineAttachmentSuccessAction = Action<
  typeof MachineDetailsPanelActions.DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS
> & {
  payload: Optional<MachineAttachmentDownloadResponse>;
};

export type IDownloadMachineAttachmentErrorAction = IErrorAction<
  typeof MachineDetailsPanelActions.DOWNLOAD_MACHINE_ATTACHMENT_ERROR
>;

export enum IPollMachineAttachmentsOperation {
  ADD = 'add',
  DELETE = 'delete',
}
export interface IPollMachineAttachmentsRequestOptions {
  machineId: string;
  operation: IPollMachineAttachmentsOperation;
  attachment?: {
    name: string;
    mimeType: string;
    size: number;
    time: number;
  };
}

export type IPollMachineAttachmentsRequestAction = Action<
  typeof MachineDetailsPanelActions.POLL_MACHINE_ATTACHMENTS_REQUEST
> & {
  payload: IPollMachineAttachmentsRequestOptions;
};

export interface MachineDetailsRobotRealTimePropertyChangedOptions {
  updatedData: MachineUpdateConnectionStatus | MachineUpdateRobotStatus | MachineUpdateTelemetry;
}
export type MachineDetailsRobotRealTimePropertyChangedAction = Action<
  typeof MachineDetailsPanelActions.MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED
> & {
  payload: {
    updatedData: MachineUpdateConnectionStatus | MachineUpdateRobotStatus | MachineUpdateTelemetry;
  };
};

export interface MachineDetailsRobotRealTimeRoutineChangedOptions {
  updatedData: MachineUpdate;
}
export type MachineDetailsRobotRealTimeRoutineChangedAction = Action<
  typeof MachineDetailsPanelActions.MACHINE_DETAILS_ROBOT_REAL_TIME_ROUTINE_CHANGED
> & {
  payload: {
    updatedData: MachineUpdate;
  };
};

export class MachineDetailsPanelActions {
  public static GET_MACHINE_DETAILS_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_REQUEST';
  public static GET_MACHINE_DETAILS_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_SUCCESS';
  public static GET_MACHINE_DETAILS_PICTURE_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_PICTURE_SUCCESS';
  public static GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS';
  public static GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS = 'MACHINE_DETAILS::GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS';
  public static GET_MACHINE_DETAILS_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_ERROR';

  public static GET_MACHINE_DETAILS_LATEST_ROUTINE_SUCCESS =
    'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_LATEST_ROUTINE_SUCCESS';

  public static GET_MACHINE_OPERATING_TIME_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_OPERATING_TIME_REQUEST';
  public static GET_MACHINE_OPERATING_TIME_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_OPERATING_TIME_SUCCESS';
  public static GET_MACHINE_OPERATING_TIME_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_OPERATING_TIME_ERROR';

  public static GET_MACHINE_REMINDERS_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_REMINDERS_REQUEST';
  public static GET_MACHINE_REMINDERS_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_REMINDERS_SUCCESS';
  public static GET_MACHINE_REMINDERS_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_REMINDERS_ERROR';

  public static GET_MACHINE_NOTES_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_NOTES_REQUEST';
  public static GET_MACHINE_NOTES_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_NOTES_SUCCESS';
  public static GET_MACHINE_NOTES_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_NOTES_ERROR';

  public static UPDATE_MACHINE_REQUEST = 'MACHINE_DETAILS_PANEL::UPDATE_MACHINE_REQUEST';
  public static UPDATE_MACHINE_SUCCESS = 'MACHINE_DETAILS_PANEL::UPDATE_MACHINE_SUCCESS';
  public static UPDATE_MACHINE_ERROR = 'MACHINE_DETAILS_PANEL::UPDATE_MACHINE_ERROR';

  public static UPDATE_MACHINE_INFO_REQUEST = 'MACHINE_DETAILS_PANEL::UPDATE_MACHINE_INFO_REQUEST';
  public static UPDATE_MACHINE_INFO_SUCCESS = 'MACHINE_DETAILS_PANEL::UPDATE_MACHINE_INFO_SUCCESS';
  public static UPDATE_MACHINE_INFO_ERROR = 'MACHINE_DETAILS_PANEL::UPDATE_MACHINE_INFO_ERROR';

  public static GET_MACHINE_NOTIFICATION_LIST_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_NOTIFICATION_LIST_REQUEST';
  public static GET_MACHINE_NOTIFICATION_LIST_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_NOTIFICATION_LIST_SUCCESS';
  public static GET_MACHINE_NOTIFICATION_LIST_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_NOTIFICATION_LIST_ERROR';
  public static CHANGE_NOTIFICATIONS_TABLE_PAGE = 'MACHINE_DETAILS_PANEL::CHANGE_NOTIFICATIONS_TABLE_PAGE';

  public static SET_ACTIVE_NOTIFICATIONS_SORT_FIELD = 'MACHINE_DETAILS_PANEL::SET_ACTIVE_NOTIFICATIONS_SORT_FIELD';
  public static SET_ACTIVE_NOTIFICATIONS_SORT_ORDER = 'MACHINE_DETAILS_PANEL::SET_ACTIVE_NOTIFICATIONS_SORT_ORDER';

  public static GET_MACHINE_ATTACHMENTS_LIST_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_ATTACHMENTS_LIST_REQUEST';
  public static GET_MACHINE_ATTACHMENTS_LIST_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_ATTACHMENTS_LIST_SUCCESS';
  public static GET_MACHINE_ATTACHMENTS_LIST_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_ATTACHMENTS_LIST_ERROR';
  public static MACHINE_ATTACHMENTS_IS_LOADING = 'MACHINE_DETAILS_PANEL::MACHINE_ATTACHMENTS_IS_LOADING';
  public static CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE = 'MACHINE_DETAILS_PANEL::CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE';
  public static SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER =
    'MACHINE_DETAILS_PANEL::SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER';

  public static DELETE_MACHINE_ATTACHMENT_REQUEST = 'MACHINE_DETAILS_PANEL::DELETE_MACHINE_ATTACHMENT_REQUEST';
  public static DELETE_MACHINE_ATTACHMENT_SUCCESS = 'MACHINE_DETAILS_PANEL::DELETE_MACHINE_ATTACHMENT_SUCCESS';
  public static DELETE_MACHINE_ATTACHMENT_ERROR = 'MACHINE_DETAILS_PANEL::DELETE_MACHINE_ATTACHMENT_ERROR';

  public static ADD_MACHINE_ATTACHMENT_REQUEST = 'MACHINE_DETAILS_PANEL::ADD_MACHINE_ATTACHMENT_REQUEST';
  public static ADD_MACHINE_ATTACHMENT_SUCCESS = 'MACHINE_DETAILS_PANEL::ADD_MACHINE_ATTACHMENT_SUCCESS';
  public static ADD_MACHINE_ATTACHMENT_ERROR = 'MACHINE_DETAILS_PANEL::ADD_MACHINE_ATTACHMENT_ERROR';

  public static DOWNLOAD_MACHINE_ATTACHMENT_REQUEST = 'MACHINE_DETAILS_PANEL::DOWNLOAD_MACHINE_ATTACHMENT_REQUEST';
  public static DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS = 'MACHINE_DETAILS_PANEL::DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS';
  public static DOWNLOAD_MACHINE_ATTACHMENT_ERROR = 'MACHINE_DETAILS_PANEL::DOWNLOAD_MACHINE_ATTACHMENT_ERROR';

  public static POLL_MACHINE_ATTACHMENTS_REQUEST = 'MACHINE_DETAILS_PANEL::POLL_MACHINE_ATTACHMENTS_REQUEST';

  public static GET_MACHINE_DETAILS_METADATA_REQUEST = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_METADATA_REQUEST';
  public static GET_MACHINE_DETAILS_METADATA_SUCCESS = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_METADATA_SUCCESS';
  public static GET_MACHINE_DETAILS_METADATA_ERROR = 'MACHINE_DETAILS_PANEL::GET_MACHINE_DETAILS_METADATA_ERROR';

  public static RESET_STATE = 'MACHINE_DETAILS_PANEL::RESET_STATE';

  public static MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED =
    'MACHINE_DETAILS_PANEL::ROBOT_REAL_TIME_PROPERTY_CHANGED';

  public static MACHINE_DETAILS_ROBOT_REAL_TIME_ROUTINE_CHANGED =
    'MACHINE_DETAILS_PANEL::ROBOT_REAL_TIME_ROUTINE_CHANGED';

  public static getMachineDetailsRequest({
    id,
    latestRoutineFilter,
  }: IGetMachineDetailsRequestOptions): IGetMachineDetailsRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_REQUEST,
      payload: { id, latestRoutineFilter },
    };
  }

  public static getMachineDetailsSuccess(data: IGetMachineDetailsSuccessOptions): IGetMachineDetailsSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsPicturesSuccess(
    data: IGetMachineDetailsPictureSuccessOptions
  ): IGetMachineDetailsPictureSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_PICTURE_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsTelemetriesSuccess(
    data: IGetMachineDetailsTelemetriesSuccessOptions
  ): IGetMachineDetailsTelemetriesSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_TELEMETRIES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsLatestRoutineSuccess(
    data: IGetMachineDetailsLatestRoutineSuccessOptions
  ): IGetMachineDetailsLatestRoutineSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_LATEST_ROUTINE_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsLatestCtrSuccess(
    data: IGetMachineDetailsLatestCtrSuccessOptions
  ): IGetMachineDetailsLatestCtrSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_LATEST_CTR_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsError({ error }: IActionCreatorErrorOptions): IGetMachineDetailsErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_ERROR,
      error,
    };
  }

  public static getMachineOperatingTimeRequest({
    id,
    timezone,
    period,
  }: IGetMachineOperatingTimeRequestOptions): IGetMachineOperatingTimeRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_OPERATING_TIME_REQUEST,
      payload: { id, timezone, period },
    };
  }

  public static getMachineOperatingTimeSuccess(
    data: IGetMachineOperatingTimeSuccessOptions
  ): IGetMachineOperatingTimeSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_OPERATING_TIME_SUCCESS,
      payload: data,
    };
  }

  public static getMachineOperatingTimeError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineOperatingTimeErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_OPERATING_TIME_ERROR,
      error,
    };
  }

  public static getMachineRemindersRequest({
    machineId,
    paginationOptions,
    append,
  }: IGetMachineRemindersRequestOptions): IGetMachineRemindersRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_REMINDERS_REQUEST,
      payload: {
        machineId,
        paginationOptions,
        append,
      },
    };
  }

  public static getMachineRemindersSuccess(
    data: IGetMachineRemindersSuccessOptions
  ): IGetMachineRemindersSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_REMINDERS_SUCCESS,
      payload: data,
    };
  }

  public static getMachineRemindersError({ error }: IActionCreatorErrorOptions): IGetMachineRemindersErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_REMINDERS_ERROR,
      error,
    };
  }

  public static getMachineNotesRequest({
    machineId,
    paginationOptions,
    append,
  }: IGetMachineNotesRequestOptions): IGetMachineNotesRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_NOTES_REQUEST,
      payload: {
        machineId,
        paginationOptions,
        append,
      },
    };
  }

  public static getMachineNotesSuccess(data: IGetMachineNotesSuccessOptions): IGetMachineNotesSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_NOTES_SUCCESS,
      payload: data,
    };
  }

  public static getMachineNotesError({ error }: IActionCreatorErrorOptions): IGetMachineNotesErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_NOTES_ERROR,
      error,
    };
  }

  public static updateMachineRequest({ id, name }: IUpdateMachineRequestOptions): IUpdateMachineRequestAction {
    return {
      type: MachineDetailsPanelActions.UPDATE_MACHINE_REQUEST,
      payload: {
        id,
        name,
      },
    };
  }

  public static updateMachineSuccess(data: IUpdateMachineSuccessOptions): IUpdateMachineSuccessAction {
    return {
      type: MachineDetailsPanelActions.UPDATE_MACHINE_SUCCESS,
      payload: data,
    };
  }

  public static updateMachineError({ error }: IActionCreatorErrorOptions): IUpdateMachineErrorAction {
    return {
      type: MachineDetailsPanelActions.UPDATE_MACHINE_ERROR,
      error,
    };
  }

  public static updateMachineInfoRequest(data: IUpdateMachineInfoRequestOptions): IUpdateMachineInfoRequestAction {
    return {
      type: MachineDetailsPanelActions.UPDATE_MACHINE_INFO_REQUEST,
      payload: data,
    };
  }

  public static updateMachineInfoSuccess(data: IUpdateMachineInfoSuccessOptions): IUpdateMachineInfoSuccessAction {
    return {
      type: MachineDetailsPanelActions.UPDATE_MACHINE_INFO_SUCCESS,
      payload: data,
    };
  }

  public static updateMachineInfoError({ error }: IActionCreatorErrorOptions): IUpdateMachineInfoErrorAction {
    return {
      type: MachineDetailsPanelActions.UPDATE_MACHINE_INFO_ERROR,
      error,
    };
  }

  public static getMachineNotificationListRequest({
    paginationOptions,
    sortOptions,
    filter,
    lang,
  }: IGetMachineNotificationListRequestOptions): IGetMachineNotificationListRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_NOTIFICATION_LIST_REQUEST,
      payload: {
        paginationOptions,
        sortOptions,
        filter,
        lang,
      },
    };
  }

  public static getMachineNotificationListSuccess(
    data: IGetMachineNotificationListSuccessOptions
  ): IGetMachineNotificationListSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_NOTIFICATION_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getMachineNotificationListError({
    error,
  }: IActionCreatorErrorOptions): IGetMachineNotificationListErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_NOTIFICATION_LIST_ERROR,
      error,
    };
  }

  public static changeMachineNotificationListTablePage(data: {
    page: number;
  }): IChangeMachineNotificationListTablePage {
    return {
      type: MachineDetailsPanelActions.CHANGE_NOTIFICATIONS_TABLE_PAGE,
      payload: data,
    };
  }

  public static setMachineNotificationListActiveSortField(field: Optional<string>): ISetActiveNotificationsSortField {
    return {
      type: MachineDetailsPanelActions.SET_ACTIVE_NOTIFICATIONS_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setMachineNotificationListActiveSortOrder(
    order: Optional<SortOrders>
  ): ISetActiveNotificationsSortOrder {
    return {
      type: MachineDetailsPanelActions.SET_ACTIVE_NOTIFICATIONS_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static getMachineAttachmentsRequest({
    machineId,
    paginationOptions,
    sortOptions,
  }: IGetMachineAttachmentsRequestOptions): IGetMachineAttachmentsRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_ATTACHMENTS_LIST_REQUEST,
      payload: {
        machineId,
        paginationOptions,
        sortOptions,
      },
    };
  }

  public static getMachineAttachmentsSuccess(
    data: IGetMachineAttachmentsSuccessOptions
  ): IGetMachineAttachmentsSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_ATTACHMENTS_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getMachineAttachmentsError({ error }: IActionCreatorErrorOptions): IGetMachineAttachmentsErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_ATTACHMENTS_LIST_ERROR,
      error,
    };
  }

  public static machineAttachmentsIsLoading(): IMachineAttachmentsIsLoadingAction {
    return {
      type: MachineDetailsPanelActions.MACHINE_ATTACHMENTS_IS_LOADING,
    };
  }

  public static changeMachineAttachmentsListTablePage(data: { page: number }): IChangeMachineAttachmentsListTablePage {
    return {
      type: MachineDetailsPanelActions.CHANGE_MACHINE_ATTACHMENTS_TABLE_PAGE,
      payload: data,
    };
  }

  public static setMachineAttachmentsActiveSortOrder(
    order: Optional<SortOrders>
  ): ISetMachineAttachmentsActiveSortOrder {
    return {
      type: MachineDetailsPanelActions.SET_MACHINE_ATTACHMENT_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static deleteMachineAttachmentRequest({
    machineId,
    attachmentId,
  }: IDeleteMachineAttachmentRequestOptions): IDeleteMachineAttachmentRequestAction {
    return {
      type: MachineDetailsPanelActions.DELETE_MACHINE_ATTACHMENT_REQUEST,
      payload: {
        machineId,
        attachmentId,
      },
    };
  }

  public static deleteMachineAttachmentSuccess(
    data: IDeleteMachineAttachmentSuccessOptions
  ): IDeleteMachineAttachmentSuccessAction {
    return {
      type: MachineDetailsPanelActions.DELETE_MACHINE_ATTACHMENT_SUCCESS,
      payload: data,
    };
  }

  public static deleteMachineAttachmentError({
    error,
  }: IActionCreatorErrorOptions): IDeleteMachineAttachmentErrorAction {
    return {
      type: MachineDetailsPanelActions.DELETE_MACHINE_ATTACHMENT_ERROR,
      error,
    };
  }

  public static addMachineAttachmentRequest({
    machineId,
    file,
  }: IAddMachineAttachmentRequestOptions): IAddMachineAttachmentRequestAction {
    return {
      type: MachineDetailsPanelActions.ADD_MACHINE_ATTACHMENT_REQUEST,
      payload: {
        machineId,
        file,
      },
    };
  }

  public static addMachineAttachmentSuccess(
    data: IAddMachineAttachmentSuccessOptions
  ): IAddMachineAttachmentSuccessAction {
    return {
      type: MachineDetailsPanelActions.ADD_MACHINE_ATTACHMENT_SUCCESS,
      payload: data,
    };
  }

  public static addMachineAttachmentError({ error }: IActionCreatorErrorOptions): IAddMachineAttachmentErrorAction {
    return {
      type: MachineDetailsPanelActions.ADD_MACHINE_ATTACHMENT_ERROR,
      error,
    };
  }

  public static downloadMachineAttachmentRequest({
    machineId,
    attachmentId,
  }: IDownloadMachineAttachmentRequestOptions): IDownloadMachineAttachmentRequestAction {
    return {
      type: MachineDetailsPanelActions.DOWNLOAD_MACHINE_ATTACHMENT_REQUEST,
      payload: {
        machineId,
        attachmentId,
      },
    };
  }

  public static downloadMachineAttachmentSuccess(
    data: IDownloadMachineAttachmentSuccessOptions
  ): IDownloadMachineAttachmentSuccessAction {
    return {
      type: MachineDetailsPanelActions.DOWNLOAD_MACHINE_ATTACHMENT_SUCCESS,
      payload: data,
    };
  }

  public static downloadMachineAttachmentError({
    error,
  }: IActionCreatorErrorOptions): IDownloadMachineAttachmentErrorAction {
    return {
      type: MachineDetailsPanelActions.DOWNLOAD_MACHINE_ATTACHMENT_ERROR,
      error,
    };
  }

  public static pollMachineAttachmentsRequest({
    machineId,
    operation,
    attachment,
  }: IPollMachineAttachmentsRequestOptions): IPollMachineAttachmentsRequestAction {
    return {
      type: MachineDetailsPanelActions.POLL_MACHINE_ATTACHMENTS_REQUEST,
      payload: {
        machineId,
        operation,
        attachment,
      },
    };
  }

  public static getMachineDetailsMetadataRequest({
    id,
  }: IGetMachineDetailsRequestOptions): IGetMachineDetailsRequestAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_METADATA_REQUEST,
      payload: { id },
    };
  }

  public static getMachineDetailsMetadataSuccess(
    data: IGetMachineDetailsSuccessOptions
  ): IGetMachineDetailsSuccessAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_METADATA_SUCCESS,
      payload: data,
    };
  }

  public static getMachineDetailsMetadataError({ error }: IActionCreatorErrorOptions): IGetMachineDetailsErrorAction {
    return {
      type: MachineDetailsPanelActions.GET_MACHINE_DETAILS_METADATA_ERROR,
      error,
    };
  }

  public static resetState(): Action<typeof MachineDetailsPanelActions.RESET_STATE> {
    return {
      type: MachineDetailsPanelActions.RESET_STATE,
    };
  }

  public static robotByStatusRealTimePropertyChanged({
    updatedData,
  }: MachineDetailsRobotRealTimePropertyChangedOptions): MachineDetailsRobotRealTimePropertyChangedAction {
    return {
      type: MachineDetailsPanelActions.MACHINE_DETAILS_ROBOT_REAL_TIME_PROPERTY_CHANGED,
      payload: {
        updatedData,
      },
    };
  }

  public static robotByRoutineRealTimePropertyChanged({
    updatedData,
  }: MachineDetailsRobotRealTimeRoutineChangedOptions): MachineDetailsRobotRealTimeRoutineChangedAction {
    return {
      type: MachineDetailsPanelActions.MACHINE_DETAILS_ROBOT_REAL_TIME_ROUTINE_CHANGED,
      payload: {
        updatedData,
      },
    };
  }
}
