import { singleton } from 'tsyringe';
import { SiteService } from '../site-management/SiteService';
import { MachineClient } from '../machine-inventory/MachineClient';
import { MachinesAvailableToFilter } from '../machine-inventory/interfaces/Machine.types';
import {
  NotificationClient,
  NotificationClientListOptions,
  NotificationClientMachineListFilterOptions,
  NotificationClientUserLocationStatusOptions,
} from './NotificationClient';
import { NotificationList, NotificationListAvailableFilters } from './interfaces/Notification.types';
import { OpenSearch } from 'config/constants';
import {
  NotificationCategory,
  ResponseNotificationUpdateNotifyStatus,
  ResponseNotificationUser,
  Severity,
  SortOrders,
  Type,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';
import { Optional } from 'lib/types/Optional';

export type NotificationServiceListOptions = NotificationClientListOptions;
export type NotificationServiceMachineListFilterOptions = NotificationClientMachineListFilterOptions;
export type NotificationServiceUserLocationStatusOptions = NotificationClientUserLocationStatusOptions;

@singleton()
export class NotificationService {
  constructor(
    private machineClient: MachineClient,
    private notificationClient: NotificationClient,
    private siteService: SiteService
  ) {}

  public list = async ({
    paginationOptions,
    filter,
    sortOptions,
    lang,
  }: NotificationServiceListOptions): Promise<NotificationList> => {
    const { data } = await this.notificationClient.list({ paginationOptions, filter, sortOptions, lang });
    return data;
  };

  public listShort = async ({
    paginationOptions,
    filter,
    sortOptions,
    lang,
  }: NotificationServiceListOptions): Promise<NotificationList> => {
    const { data } = await this.notificationClient.listShort({ paginationOptions, filter, sortOptions, lang });
    return data;
  };

  public machineListFilter = async ({
    filter,
  }: NotificationServiceMachineListFilterOptions): Promise<MachinesAvailableToFilter> => {
    const { data } = await this.machineClient.listAvailableToFilter({
      filter,
      paginationOptions: {
        limit: OpenSearch.MAX_RESULT_WINDOW,
      },
      sortOptions: {
        field: 'name',
        order: SortOrders.Asc,
      },
    });
    return data;
  };

  public availableFilters = async (): Promise<NotificationListAvailableFilters> => {
    const machinesPromise = this.machineListFilter({
      filter: {
        siteIds: [],
      },
    });

    const sitesPromise = this.siteService.list({
      paginationOptions: {
        limit: OpenSearch.MAX_RESULT_WINDOW,
        paginationToken: '',
      },
    });

    return Promise.all([machinesPromise, sitesPromise])
      .then(([machinesResponse, sitesResponse]) => {
        const {
          sites: { data: sites },
        } = sitesResponse;

        const {
          machines: { data: machines },
        } = machinesResponse;

        return {
          machines,
          sites,
          severities: Object.values(Severity),
          types: Object.values(Type),
          categories: Object.values(NotificationCategory),
        };
      })
      .catch(error => {
        throw error;
      });
  };

  public getUserNotification = async (): Promise<Optional<ResponseNotificationUser>> => {
    const { data } = await this.notificationClient.getUserNotification();
    return data;
  };

  public updateNotifyStatus = async ({
    lang,
    notifyAutonomousTaskStopped,
    notifyCleaningTaskFinished,
    notifyCleaningTaskInterrupted,
    notifyEmergencyStop,
    notifyErrorOccurred,
    notifyInformation,
    notifyLocationStatus,
    notifyNoWorkStart,
    notifyPCM,
    notifyOther,
    notifyReminder,
    notifyResources,
    notifyServiceNeeded,
    timezone,
  }: NotificationServiceUserLocationStatusOptions): Promise<Optional<ResponseNotificationUpdateNotifyStatus>> => {
    const { data } = await this.notificationClient.updateNotifyStatus({
      lang,
      notifyAutonomousTaskStopped,
      notifyCleaningTaskFinished,
      notifyCleaningTaskInterrupted,
      notifyEmergencyStop,
      notifyErrorOccurred,
      notifyInformation,
      notifyLocationStatus,
      notifyNoWorkStart,
      notifyOther,
      notifyPCM,
      notifyReminder,
      notifyResources,
      notifyServiceNeeded,
      timezone,
    });
    return data;
  };
}
