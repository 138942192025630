import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectData, selectIsLoading } from '../../state/machineDetailsPanelSelectors';
import { MachineDetailsPanelActions } from '../../state/machineDetailsPanelActions';
import { StyledMachineDetailsPanel } from './MachineDetailsPanel.styles';
import { MachineDetailsPanelGcd } from './MachineDetailsPanelGcd';
import { MachineDetailsPanelRobot } from './MachineDetailsPanelRobot';
import { LoadingPage } from 'lib/components/LoadingPage/LoadingPage';
import { FeatureFlagSelectors } from 'app/cross-cutting-concerns/feature-flags/state/featureFlagSelectors';
import {
  MachineClassification,
  RoutineStatus,
} from 'app/cross-cutting-concerns/communication/interfaces/am-api-graphql';

export interface MachineDetailsPanelProps {
  id: string;
}

export const MachineDetailsPanel = ({ id }: MachineDetailsPanelProps): React.JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const features = useSelector(FeatureFlagSelectors.selectFeatureFlagConfig);
  const machine = useSelector(selectData);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (id) {
      dispatch(
        MachineDetailsPanelActions.getMachineDetailsRequest({
          id,
          latestRoutineFilter: {
            status: RoutineStatus.InProgress,
          },
        })
      );
    }
  }, [dispatch, id]);

  useEffect(
    () => (): void => {
      dispatch(MachineDetailsPanelActions.resetState());
    },
    [dispatch]
  );

  const isRobot = machine?.classification === MachineClassification.Robot;
  const isRobotDetailsAvailable = features.ROBOTICS_DETAILS_PAGE && isRobot;
  const effectiveMachineDetailsPanel = isRobotDetailsAvailable ? (
    <MachineDetailsPanelRobot />
  ) : (
    <MachineDetailsPanelGcd id={id} />
  );

  return (
    <StyledMachineDetailsPanel>
      {isLoading && (
        <div className="loading-wrapper">
          <LoadingPage />
        </div>
      )}
      {!isLoading && (machine ? effectiveMachineDetailsPanel : t('machineDetails.error.machineNotFound'))}
    </StyledMachineDetailsPanel>
  );
};
